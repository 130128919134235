import React from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../Buttons/Button';
import { createUser } from '../../store/actions/userActions';
import './UserForm.css';

const UserForm = ({ userID, auth, users, createUser, onOpenForm, getUsers, accountId, setSearchTerm }) => {
  const user = users.users.find((user) => user.id === userID);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      username: '',
      role: 'USER',
      password: '',
      confirmPassword: '',
      avatar: null,
      provider: 'local',
      account: accountId ? accountId : (auth.me?.role !== 'SUPERADMIN' && auth.me?.account) ? auth.me?.account : null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(30, 'Must be 30 characters or less')
        .required('Name is required'),
      username: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(20, 'Must be 20 characters or less')
        .matches(/^[a-zA-Z0-9_]+$/, 'Invalid characters in username')
        .required('Username is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),

      password: Yup.string()
        .min(6, 'Must be 6 characters at minimum')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .when('password', {
          is: (password) => password?.length > 0,
          then: (schema) => schema.required('Password confirmation is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      role: Yup.string()
        .oneOf(['USER', 'PODMASTER', 'SUPERADMIN'], 'Invalid role')
        .required('Role is required'),
      avatar: Yup.mixed().nullable(),
      account:  Yup.string().nullable(),
    }),
    onSubmit: (values) => {
      const submitValues = { ...values };
      if (!submitValues.avatar) {
        delete submitValues.avatar;
      }
      delete submitValues.confirmPassword;      

      createUser(submitValues);
      
      formik.resetForm();
      onOpenForm(null);
      getUsers(accountId);
      setSearchTerm('');
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    onOpenForm(null);
  };

  return (
    <div className="user-form user__column">
      <div className="users__heading">
        <h2>Create New User</h2>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <h4 className="label Material-KitButton">User Details</h4>

        <input
          name="name"
          className="Material-KitSB2"
          placeholder="Full Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />

        <input
          name="username"
          className="Material-KitSB2"
          placeholder="Username"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
        />

        <input
          name="email"
          className="Material-KitSB2"
          placeholder="Email Address"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />

        {/* <div className="avatar-section">
          {formik.values.currentAvatar && (
            <img
              src={
                formik.values.currentAvatar.startsWith('http')
                  ? formik.values.currentAvatar
                  : `${process.env.REACT_APP_SERVER_BASE_URL}${formik.values.currentAvatar}`
              }
              alt="Current avatar"
              className="current-avatar"
              style={{ width: '100px', height: '100px', objectFit: 'cover', marginBottom: '10px' }}
            />
          )}
          <input
            type="file"
            accept="image/png,image/jpeg,image/jpg"
            id="avatar"
            name="avatar"
            className="Material-KitSB2"
            placeholder="Avatar"
            onChange={(event) => {
              formik.setFieldValue('avatar', event.currentTarget.files[0]);
            }}
            onBlur={formik.handleBlur}
          />
        </div> */}

        {auth.me?.role === 'SUPERADMIN' && (
          <>
            <h5 className="user-form-label Material-KitOverline">User Role</h5>
            <select
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
              className="user-form-select Material-KitSB2"
            >
              <option value="USER">User</option>
              <option value="PODMASTER">Pod Master</option>
              {!user && <option value="SUPERADMIN">Super Admin</option>}
            </select>

            <h5 className="user-form-label Material-KitOverline">User Account</h5>
            <input
              name="account"
              className="Material-KitSB2"
              placeholder="Account ID"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.account}
            />
          </>
        )}

        {user && user.provider === 'local' && (
          <div className="password-change-section">
            <h4 className="label Material-KitButton">Change Password</h4>
            <input
              type="password"
              name="oldPassword"
              className="Material-KitSB2"
              placeholder="Current Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.oldPassword}
            />

            <input
              type="password"
              name="password"
              className="Material-KitSB2"
              placeholder="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />

            <input
              type="password"
              name="confirmPassword"
              className="Material-KitSB2"
              placeholder="Confirm New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
          </div>
        )}
        {!user && (
          <div className="password-change-section">
            <h4 className="label Material-KitButton">Password</h4>

            <input
              type="password"
              name="password"
              className="Material-KitSB2"
              placeholder="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <input
              type="password"
              name="confirmPassword"
              className="Material-KitSB2"
              placeholder="Confirm New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
          </div>
        )}
        <div className="user__buttons">
          <Button
            type="submit"
            className="save-user submit-btn Material-KitButton bg"
            disabled={formik.isSubmitting}
          >
            {user ? 'Save' : 'Create'}
          </Button>
          <Button
            type="button"
            onClick={handleCancel}
            className="cancel-btn submit-btn Material-KitButton bg"
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
        </div>

        {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}

        {Object.keys(formik.errors).map(
          (key) =>
            formik.touched[key] &&
            key !== 'submit' &&
            key !== 'currentAvatar' &&
            formik.errors[key] && (
              <p key={key} className="error">
                {formik.errors[key]}
              </p>
            ),
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
});

export default connect(mapStateToProps, { createUser })(UserForm);
