import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';

import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';
import requireAuth from '../../hoc/requireAuth';

// import '../../components/TagList/styles.css';

import { getProject } from '../../store/actions/projectActions';
import { getTag, deleteTag, editTag, clearTagError } from '../../store/actions/tagActions';
// import { getTags } from '../../store/actions/tagActions';
import { tagFormSchema } from './validation';



// import './styles.css';

const TagEdit = ({ 
  
  getTag,
  getProject,
  thisProject: { 
    project, 
    // isLoading, 
    error 
  }, 
  tag: { tag, isLoading: tagIsLoading, error: tagError },  
  editTag, 
  clearTagError,   
  history, 
  match 
}) => {  
  
  const matchTagId = match.params.tagId;
  const [isSave, setIsSave] = useState(false);
  const [color, setColor] = useState(tag.color);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: tag.name || '',
      id: matchTagId || '',
      color: tag.color || '#000000',
      project: tag.project || '',            
    },
    validationSchema: tagFormSchema,
    onSubmit: (values, { resetForm }) => {      
      editTag(values.id, { 
        name: values.name, 
        color: values.color,
        project: values.project.id        
      });
      clearTagError(tag.id);
      setIsSave(true);      
    },
  });

  useEffect(() => {
    getTag(matchTagId, history);        
  }, []);

  useEffect(() => {
    getTag(matchTagId, history);    
    setIsSave(false);
  }, [isSave]);

  useEffect(() => {       
    setColor(tag.color);
  }, [tag]);

  useEffect(() => {    
    if (tag.project) {
      getProject(tag.project.id, true, history);
    }
  }, [tag.project])

  return (
    <Layout>
      <div className="department-edit-page">
        
        {error && <div className="error-center">{error}</div>}        
        {tagIsLoading ? (
          <Loader />
        ) : (
          <div className='form-container'>
            <h1>{project.title} / <i>{tag.name} Tag</i></h1>
            <span className="time text-light">{`Created: ${moment(tag.createdAt).fromNow()}`}</span>
            { tag.user ? (
              <>
                <span className="fullname text-light">{` by: ${tag.user.name}`}</span>                
              </>
              ) : (null)
            }
            <br/>
            {!moment(tag.createdAt).isSame(tag.updatedAt, 'minute') && (
              <span className="time text-light">{`Edited: ${moment(tag.updatedAt).fromNow()}`}</span>              
            )}
            
            <form onSubmit={formik.handleSubmit}>              
              <>
                <input type="hidden" name="id" />
                <h3 className='label'>Tag Name</h3>
                {/* Tag Name */}
                <input
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  disabled={tag.isLoading}
                />
                {formik.touched.name && formik.errors.name ? (
                  <p className="error">{formik.errors.name}</p>
                ) : null}            
                
                <h3 className='label'>Color</h3>
                {/* Tag Color */}
                <CirclePicker
                  color={color}
                  onChange={(color) => {
                    formik.setFieldValue('color', color.hex);
                    setColor(color.hex);
                  }}
                  className='department-form-color-field'
                  colors={['#B4A7D6', '#B6D7A8', '#FFE599', '#EA9999', '#97BBDF', '#DFA6DF', '#ACDFDF', '#FFE5D4', '#CCC2A9', '#D6D6D6', '#9FB6C1', '#E5CDD2']}
                />
                {formik.touched.color && formik.errors.color ? (
                  <p className="error">{formik.errors.color}</p>
                ) : null}
                
                
              </>
                                   
              
              <div className='form-actions'> 
                {tag.error ? (
                  <p className="error">{tag.error}</p>
                ) : null}            
                <button type="submit" className="btn save-btn" disabled={tag.isLoading}>
                  Save
                </button>
                {tag.project? (
                  <Link to={`/project/tags/edit/${tag.project.id}`} className="btn return-btn">Back to Tags</Link>
                ) : (null)}
              </div>
            </form>
          </div>
        )}
        
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,
  tag: state.tag,
});

export default compose(requireAuth, withRouter, connect(mapStateToProps, { getTag, getProject, deleteTag, editTag, clearTagError }))(TagEdit);
