import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';

import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';
import requireAuth from '../../hoc/requireAuth';

import '../../components/DepartmentList/styles.css';

import { getProject } from '../../store/actions/projectActions';
import { getDepartment, deleteDepartment, editDepartment, clearDepartmentError } from '../../store/actions/departmentActions';
import { departmentFormSchema } from './validation';



import './styles.css';

const DepartmentEdit = ({   
  getDepartment,
  getProject,
  thisProject: { 
    project, 
    // isLoading, 
    error 
  }, 
  department: { department, isLoading: departmentIsLoading, error: departmentError },  
  editDepartment, 
  clearDepartmentError,   
  history, 
  match 
}) => {  
  
  const matchDepartmentId = match.params.departmentId;
  const [isSave, setIsSave] = useState(false);
  const [color, setColor] = useState(department.color);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: department.name || '',
      id: matchDepartmentId || '',
      color: department.color || '#000000',
      viewOnly: department.viewOnly || false,
      external: department.external || false,
      project: department.project || '',      
      users: department.users || [],
      email: '',
    },
    validationSchema: departmentFormSchema,
    onSubmit: (values, { resetForm }) => {
      const userIDs = values.users.map((user) => user.id);
      editDepartment(values.id, { 
        name: values.name, 
        color: values.color, 
        viewOnly: values.viewOnly, 
        external: values.external, 
        project: values.project.id, 
        users: userIDs 
      });
      clearDepartmentError(department.id);
      setIsSave(true);
      // getDepartment(matchDepartmentId, true, history);
      // resetForm();
    },
  });

  useEffect(() => {
    getDepartment(matchDepartmentId, history);        
  }, []);
  
  useEffect(() => {
    getDepartment(matchDepartmentId, history);    
    setIsSave(false);
  }, [isSave]);

  useEffect(() => {       
    setColor(department.color);
  }, [department]);

  useEffect(() => {    
    if (department.project) {
      getProject(department.project.id, true, history);
    }
  }, [department.project])

  return (
    <Layout>
      <div className="department-edit-page">
        
        {error && <div className="error-center">{error}</div>}        
        {departmentIsLoading ? (
          <Loader />
        ) : (
          <div className='form-container'>
            <h1>{project.title} / <i>{department.name} Department</i></h1>
            <span className="time text-light">{`Created: ${moment(department.createdAt).fromNow()}`}</span>
            { department.user ? (
              <>
                <span className="fullname text-light">{` by: ${department.user.name}`}</span>                
              </>
              ) : (null)
            }
            <br/>
            {!moment(department.createdAt).isSame(department.updatedAt, 'minute') && (
              <span className="time text-light">{`Edited: ${moment(department.updatedAt,).fromNow()}`}</span>              
            )}
            
            <form onSubmit={formik.handleSubmit}>              
              <>
                <input type="hidden" name="id" />
                <h3 className='label'>Department Name</h3>
                {/* Department Name */}
                <input
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  disabled={department.isLoading}
                />
                {formik.touched.name && formik.errors.name ? (
                  <p className="error">{formik.errors.name}</p>
                ) : null}            
                
                <h3 className='label'>Color</h3>
                {/* Department Color */}
                <CirclePicker
                  color={color}
                  onChange={(color) => {
                    formik.setFieldValue('color', color.hex);
                    setColor(color.hex);
                  }}
                  className='department-form-color-field'
                  colors={['#B4A7D6', '#B6D7A8', '#FFE599', '#EA9999', '#97BBDF', '#DFA6DF', '#ACDFDF', '#FFE5D4', '#CCC2A9', '#D6D6D6', '#9FB6C1', '#E5CDD2']}
                />
                {formik.touched.color && formik.errors.color ? (
                  <p className="error">{formik.errors.color}</p>
                ) : null}

                <h3 className='label'>View Only</h3>
                {/* <p><i>Feature coming soon.</i></p> */}
                {/* Department View Only */}
                <select 
                  name="viewOnly" 
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur} 
                  value={formik.values.viewOnly} 
                  // TODO: Remove this once the feature is implemented
                  disabled={false}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
                {formik.touched.viewOnly && formik.errors.viewOnly ? (
                  <p className="error">{formik.errors.viewOnly}</p>
                ) : null}

                <h3 className='label'>External</h3>
                {/* <p><i>Feature coming soon.</i></p> */}
                {/* External */}
                <select 
                  name="external" 
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur} 
                  value={formik.values.external} 
                  // TODO: Remove this once the feature is implemented
                  disabled={false}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
                {formik.touched.external && formik.errors.external ? (
                  <p className="error">{formik.errors.external}</p>
                ) : null}
                
                <div className='department-users-list'>
                  <h3 className='label'>Members (<Link to={`/project/department/edit/${department.id}/users`} className="header-link">Edit</Link>)</h3>
                  <Link to={`/project/department/edit/${department.id}/users`} className="user-list-link"><div className='user-list-container'>
                    { formik.values.users.length === 0 ? (
                        <p>No members selected</p>
                      ) : (
                        <>
                          {formik.values.users.map((user, index) => {
                            return (
                              <div key={index} className="user-list-item">
                                <div>
                                  {/* <div className="label">Name: </div> */}
                                  <div className="user-name">{user.name}</div>
                                </div>
                                <div className="user-info-container">
                                  <div>
                                    {/* <div className="label">Username: </div> */}
                                    <div className="user-info user-username">@{user.username}</div>
                                  </div>
                                  <div>
                                    {/* <div className="label">Email: </div> */}
                                    <div className="user-info user-email">{user.email}</div>
                                  </div>                    
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )
                    }
                  </div></Link>
                </div>
                
              </>
                                   
              
              <div className='form-actions'> 
                {department.error ? (
                  <p className="error">{department.error}</p>
                ) : null}            
                <button type="submit" className="btn save-btn" disabled={department.isLoading}>
                  Save
                </button>
                {department.project? (
                  <Link to={`/project/departments/edit/${department.project.id}`} className="btn return-btn">Back to Departments</Link>
                ) : (null)}                
                
                
              </div>
            </form>
          </div>
        )}
        
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,
  department: state.department,
});

export default compose(requireAuth, withRouter, connect(mapStateToProps, { getDepartment, getProject, deleteDepartment, editDepartment, clearDepartmentError }))(DepartmentEdit);
