import axios from 'axios';
import { month } from '../../constants/index';
import { currentDate, daysInMonth, currentMonth, currentYear } from '../../constants/index';
import { selectDaysInMonth, selectMonth, selectYear, formatDate } from '../../constants/functions';
import {
  FETCH_HUDDLE_BOARDS,
  FETCH_HUDDLE_BOARD,
  HUDDLE_BOARD_ERROR,
  CREATE_HUDDLE_BOARD,
  DELETE_HUDDLE_BOARD,
  UPDATE_HUDDLE_BOARD,
  FETCH_HUDDLE_BOARD_LOADING,
} from '../types';

const config = {
  headers: {
    'x-auth-token': localStorage.getItem('token'),
    'Content-Type': 'application/json',
  },
};

export const fetchHuddleBoards = (projectId) => async (dispatch) => {
  dispatch({
    type: FETCH_HUDDLE_BOARD_LOADING,
  });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/huddle-board/${projectId}?single=false`,
      config,
    );
    dispatch({
      type: FETCH_HUDDLE_BOARDS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: HUDDLE_BOARD_ERROR, payload: error.message });
  }
};

export const fetchHuddleBoard =
  (projectId, title = null, single = true) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_HUDDLE_BOARD_LOADING,
    });
    try {
      let response;
      if (title === null) {
        response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/huddle-board/${projectId}?single=true`,
          config,
        );
      } else if (single) {
        response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/huddle-board/${projectId}?title=${title}&single=true`,
          config,
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/huddle-board/${projectId}?title=${title}&single=false`,
          config,
        );
      }

      dispatch({ type: FETCH_HUDDLE_BOARD, payload: response.data });
    } catch (error) {
      dispatch({ type: HUDDLE_BOARD_ERROR, payload: error.message });
    }
  };

export const createHuddleBoard = (data) => async (dispatch) => {
  try {
    /* 
    // Set today's state to 'highlighted' (or based on the button pressed, for example)
    const today = currentDate.getDate(); // Get the current day number (1-31)
    states[today - 1] = 'highlighted'; // Mark today's state as 'highlighted'
 */    
    const huddleBoardData = {
      project: data.project,
      title: data.title,
      description: data.description,
      order: data.order,
      data: data.data,
      tag: data.tag,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/huddle-board`,
      huddleBoardData,
      config,
    );

    dispatch({ type: CREATE_HUDDLE_BOARD, payload: response.data });
  } catch (error) {
    dispatch({ type: HUDDLE_BOARD_ERROR, payload: error.message });
  }
};

export const updateHuddleBoard = (id, userPayload, prevState, type, selectedDate) => async (dispatch) => {
  // TODO: a lot of the logic here should move to the backend server
  try {
    let updatedHuddleBoard;    
    if (type === 'Custom') {
      updatedHuddleBoard = {
        project: prevState.project,
        title: prevState.title,
        description: prevState.description,
        order: prevState.order,
        data: userPayload,
      };
    } else if (type === 'Daily') {

      const todayIndex = selectedDate.getDate() - 1;
        
      const noDataArray = Array(selectDaysInMonth(selectedDate)).fill([]);        

      let updatedData;
      if (prevState.data.length === 0) {
        updatedData = [
          {
            years: {
              [selectYear(selectedDate)]: {
                [selectMonth(selectedDate)]: [...noDataArray],
              },
            }            
          },
          {
            master: {
              items: [],
              subItems: [],
            },
          }
        ];
      } else {
        updatedData = prevState.data.map((entry) => {
          if (entry.years && entry.years[selectYear(selectedDate)] && entry.years[selectYear(selectedDate)][selectMonth(selectedDate)]) {
            entry.years[selectYear(selectedDate)][selectMonth(selectedDate)][todayIndex] = userPayload.years;
          } else if (entry.master) {
            entry.master = userPayload.master;
          }
          return entry;
        });
      }
      if (!updatedData[0].years[selectYear(selectedDate)]) {
        updatedData[0].years[selectYear(selectedDate)] = {
          [selectMonth(selectedDate)]: [...noDataArray],
        };
      }
      if (!updatedData[0].years[selectYear(selectedDate)][selectMonth(selectedDate)]) {
        updatedData[0].years[selectYear(selectedDate)][selectMonth(selectedDate)] = [...noDataArray];
      }
      updatedData[0].years[selectYear(selectedDate)][selectMonth(selectedDate)][todayIndex] = userPayload.years;
      
      updatedData[1].master = userPayload.master;
          
      

      updatedHuddleBoard = {
        project: prevState.project,
        title: prevState.title,
        description: prevState.description,
        order: prevState.order,
        data: updatedData,
      };
    } else if (type === 'Weekly') {

      let updatedData = [];
      let weekDate = formatDate(selectedDate);
      let weekIndex = prevState.data[0]?.weeks?.findIndex((entry) => entry.week === weekDate);      
      let updatedWeeks = prevState.data[0]?.weeks;
      let updatedMaster = userPayload.master;
      
      if (prevState.data.length === 0) {
        updatedData = [
          {
            weeks: userPayload.weeks?.length > 0 ? [
              {
                week: weekDate,
                questions: userPayload.weeks,
              }
            ] : [],           
          },
          {
            master: updatedMaster,
          }
        ];
      } else {
        if (userPayload.weeks?.length > 0) {
          if (weekIndex === -1) {                    
            updatedWeeks.push({
              week: weekDate,
              questions: userPayload.weeks,
            });          
          } else {                    
            updatedWeeks[weekIndex].questions = userPayload.weeks;          
          }
        }
        
        
        updatedData = [
          {
            weeks: updatedWeeks
          },
          {
            master: updatedMaster,
          }
        ]
      }

      updatedHuddleBoard = {
        project: prevState.project,
        title: prevState.title,
        description: prevState.description,
        order: prevState.order,
        data: updatedData,
      };
    } else {
      if (userPayload === 'previous') {
        updatedHuddleBoard = {
          project: prevState.project,
          title: prevState.title,
          description: prevState.description,
          order: prevState.order,
          data: prevState.data,
          tag: prevState.tag,
        };      
      } else {
        const todayIndex = selectedDate.getDate() - 1;

        const noDataArray = Array(selectDaysInMonth(selectedDate)).fill('no-data');

        let updatedData;
        if (prevState.data.length === 0) {
          updatedData = [
            {
              years: {
                [selectYear(selectedDate)]: {
                  [selectMonth(selectedDate)]: [...noDataArray],
                },
              },
            },
          ];
        } else {
          updatedData = prevState.data.map((entry) => {
            if (
              entry.years &&
              entry.years[selectYear(selectedDate)] &&
              entry.years[selectYear(selectedDate)][selectMonth(selectedDate)]
            ) {
              entry.years[selectYear(selectedDate)][selectMonth(selectedDate)][todayIndex] =
                userPayload.toString();
            }
            return entry;
          });
        }
        if (!updatedData[0].years[selectYear(selectedDate)]) {
          updatedData[0].years[selectYear(selectedDate)] = {
            [selectMonth(selectedDate)]: [...noDataArray],
          };
        }
        if (!updatedData[0].years[selectYear(selectedDate)][selectMonth(selectedDate)]) {
          updatedData[0].years[selectYear(selectedDate)][selectMonth(selectedDate)] = [
            ...noDataArray,
          ];
        }
        updatedData[0].years[selectYear(selectedDate)][selectMonth(selectedDate)][todayIndex] =
          userPayload.toString();

        updatedHuddleBoard = {
          project: prevState.project,
          title: prevState.title,
          description: prevState.description,
          order: prevState.order,
          data: updatedData,
          tag: prevState.tag,
        };
      }
    }
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/huddle-board/${id}`,
      updatedHuddleBoard,
      config,
    );

    dispatch({ type: UPDATE_HUDDLE_BOARD, payload: response.data });
  } catch (error) {
    dispatch({
      type: 'HUDDLE_BOARD_ERROR',
      payload: { message: error.message, status: error.response?.status },
    });
  }
};

export const deleteHuddleBoard = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/api/huddle-board/${id}`, config);
    dispatch({ type: DELETE_HUDDLE_BOARD, payload: id });
  } catch (error) {
    dispatch({ type: HUDDLE_BOARD_ERROR, payload: error.message });
  }
};
