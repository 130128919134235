import React, { useEffect, useState, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import { getProfile, editUser, deleteUser } from '../../store/actions/userActions';
import Layout from '../../layout/Layout';
import Loader from '../../components/Loader/Loader';
import requireAuth from '../../hoc/requireAuth';
import * as Yup from 'yup';
import Button from '../../components/Buttons/Button';
import './styles.css';
import '../../components/UserForm/UserForm.css';
import styles from './DragAndDrop.module.css';
// import DragAndDrop from './DragAndDrop';

const Profile = ({
  getProfile,
  user: { profile, isLoading },
  auth,
  editUser,
  history,
  match,
}) => {

  
  
  
  
  // const [isEdit, setIsEdit] = useState(false);
  // const [image, setImage] = useState(null);
  // const [avatar, setAvatar] = useState(null);
  // const retryCount = useRef(0);
  const matchUsername = match.params.username;
  const [error, setError] = useState(null);

  useEffect(() => {
    getProfile(matchUsername, history);
  }, [matchUsername, getProfile, history]);

  // if changed his own username reload me, done in userActions

  // const onChange = (event) => {
  //   formik.setFieldValue('image', event.currentTarget.files[0]);
  //   setImage(URL.createObjectURL(event.target.files[0]));
  //   setAvatar(event.target.files[0]);
  // };

  // const handleClickEdit = () => {
  //   retryCount.current = 0;
  //   setIsEdit((oldIsEdit) => !oldIsEdit);
  //   setImage(null);
  //   setAvatar(null);
  //   formik.setFieldValue('id', profile.id);
  //   formik.setFieldValue('name', profile.name);
  //   formik.setFieldValue('username', profile.username);
  // };

  // const handleDeleteUser = (id, history) => {
  //   deleteUser(id, history);
  // };

  const formik = useFormik({
    initialValues: {
      name: profile?.name || '',
      email: profile?.email || '',
      username: profile?.username || '',
      // role: profile?.role || 'USER',
      oldPassword: '',
      password: '',
      confirmPassword: '',
      avatar: null,
      currentAvatar: profile?.avatar || null,
      provider: profile?.provider || 'local',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(30, 'Must be 30 characters or less')
        .required('Name is required'),
      username: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(20, 'Must be 20 characters or less')
        .matches(/^[a-zA-Z0-9_]+$/, 'Invalid characters in username')
        .required('Username is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      oldPassword: Yup.string().test(
        'password-change',
        'Current password is required to change password',
        function (value) {
          if (profile && this.parent.password && auth.me?.role !== 'SUPERADMIN') {
            return !!value;
          }
          return true;
        },
      ),
      password: Yup.string()
        .min(6, 'Must be 6 characters at minimum')
        .test('password-match', 'New password is required', function (value) {
          if (profile && this.parent.oldPassword) {
            return !!value;
          }
          return true;
        }),
      confirmPassword: Yup.string().test(
        'passwords-match',
        'Passwords must match',
        function (value) {
          return !this.parent.password || value === this.parent.password;
        },
      ),
      // role: Yup.string()
      //   .oneOf(['USER', 'PODMASTER', 'SUPERADMIN'], 'Invalid role')
      //   .required('Role is required'),
      avatar: Yup.mixed().nullable(),
    }),
    onSubmit: async (values) => {
      const submitValues = { ...values };

      if (!submitValues.avatar) {
        delete submitValues.avatar;
      }

      delete submitValues.confirmPassword;
      delete submitValues.currentAvatar;

      if (submitValues.password) {
        if (auth.me?.role === 'SUPERADMIN') {
          delete submitValues.oldPassword;
        } else if (!submitValues.oldPassword) {
          delete submitValues.password;
        }
      } else {
        delete submitValues.password;
        delete submitValues.oldPassword;
      }

      // if (auth.me?.role !== 'SUPERADMIN') {
      //   delete submitValues.role;
      // }

      if (!profile) {
        submitValues.provider = 'local';
      }

      try {
        const success = await editUser(profile.id, submitValues);

        if (success) {
          // formik.resetForm();
          setError(null);
          getProfile(matchUsername, history);
                    
        }
      } catch (error) {
        setError(error.message || 'An error occurred while editing user');
        console.error('Error editing user:', error);
      }
    },
  });

  useEffect(() => {
    if (profile) {
      formik.setValues({
        name: profile?.name || '',
        email: profile?.email || '',
        username: profile?.username || '',
        // role: profile?.role || 'USER',
        oldPassword: '',
        password: '',
        confirmPassword: '',
        avatar: null,
        currentAvatar: profile?.avatar || null,
        provider: profile?.provider || 'local',
      });
    }
  }, [matchUsername, profile]);

  const handleCancel = () => {
    formik.resetForm();
    setError(null);    
  };

  // DRAG and DROP for CLOUDINARY
  const [dragOver, setDragOver] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);
  const abortControllerRef = useRef(new AbortController());

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setDragOver(false);

    let files = [];
    if (e.currentTarget?.files) {
      files = Array.from(e.currentTarget.files);
    } else if (e.dataTransfer?.files) {
      files = Array.from(e.dataTransfer.files);
    }
    // console.log("Current Target: ", e.currentTarget?.files);
    // console.log("Data Transfer: ", e.dataTransfer?.files);
    // const files = [];
    // const files = Array.from(e.dataTransfer?.files) || e.currentTarget.files;
    setDroppedFiles([]);
    
    if (files && files.length > 1) {
      alert('Upload only one image file.');
    } else if (files && files.length === 1) {
      setLoadingStates(new Array(files.length).fill(true));
      
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      
      
      const url = `https://api.cloudinary.com/v1_1/${
        process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
      }/upload`;
      for (let file of files) {
        try {
          const formData = new FormData();
          const fields = {
            file,
            upload_preset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
            tags: ['user-profile', `userid-${profile.id}`],
            multiple: false,
            resource_type: 'image',            
          };

          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });

          const options = {
            method: 'POST',
            body: formData,
            signal: abortControllerRef.current.signal,
          };
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error('Failed to execute file upload via the Fetch API');
          }
          const json = await response.json();
          const secureUrl = json.secure_url;
          // const previewUrl = secureUrl;
          const previewUrl = secureUrl.replace(
            '/upload/',
            '/upload/c_fill,g_face,h_200,w_200/f_auto/'
          );

          setDroppedFiles((prevFiles) => [...prevFiles, { file, previewUrl }]);
          formik.setFieldValue('avatar', previewUrl);
          formik.setFieldValue('currentAvatar', previewUrl);
          setLoadingStates((prevStates) =>
            prevStates.map((state, index) =>
              file === files[index] ? false : state
            )
          );
        } catch (error) {
          if (error.name !== 'AbortError') {
            console.error(error);
          }
          setLoadingStates((prevStates) =>
            prevStates.map((state, index) =>
              file === files[index] ? false : state
            )
          );
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);
  // END DRAG and DROP for CLOUDINARY

  return (
    <Layout>
      <div className="users__container profile__container adaptive-container profile-page">
        <div className="users profile__row">        
          {isLoading ? (
            <Loader />
          ) : (
            <div className="user-form profile__column">
              <div className="users__heading">
                <h2>Profile</h2>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <h4 className="label Material-KitButton">User Details</h4>

                <div className="avatar-section">
                  {formik.values.currentAvatar && (
                    <img
                      src={
                        formik.values.currentAvatar.startsWith('http')
                          ? formik.values.currentAvatar
                          : `${process.env.REACT_APP_SERVER_BASE_URL}${formik.values.currentAvatar}`
                      }
                      alt="Current avatar"
                      className="current-avatar"
                      style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '16px' }}
                    />
                  )}
                  <input
                    type="file"
                    accept="image/png,image/jpeg,image/jpg"
                    id="avatar"
                    name="avatar"
                    className="invisible Material-KitSB2"
                    placeholder="Avatar"
                    onChange={(event) => {
                      // formik.setFieldValue('avatar', event.currentTarget.files[0]);
                      handleDrop(event);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.avatar && formik.errors.avatar && (
                    <p className="error">{formik.errors.avatar}</p>
                  )}
                  
                  <div
                    className={`${styles.dragArea} ${dragOver ? `${styles.dragOver}` : ''}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    
                    {loadingStates.some((loading) => loading) ? (
                      // <span className="drag-and-drop-image loading loading-spinner text-primary"></span>                      
                      <img src="/icons/asterisk.svg" className="drag-and-drop-image loading loading-spinner text-primary" alt="Uploading Image" />
                    ) :
                      <img src="/icons/upgrade.svg" className="drag-and-drop-image" alt="Upload Image" />
                    }
                    <div className="drag-and-drop-text-container">
                      <div className="drag-and-drop-text">Drop an image here</div>
                      <div className="or-text">or</div>
                      <label htmlFor="avatar" className="select-file-link">select file</label>
                    </div>
                  </div>
                </div>

                {/* <DragAndDrop /> */}
                

                
                {/* {droppedFiles.length !== 0 && (
                  <div className="flex flex-wrap">
                    <h2 className="w-full text-xl font-bold">Uploaded images</h2>
                    {droppedFiles.map((droppedFile, idx) => (
                      <div
                        key={idx}
                        className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-4"
                      >
                        <img
                          className="w-full"
                          src={droppedFile.previewUrl}
                          alt={`Preview of ${droppedFile.file.name}`}
                        />
                      </div>
                    ))}
                  </div>
                )} */}


                
                <input
                  name="name"
                  className="Material-KitSB2"
                  placeholder="Full Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && <p className="error">{formik.errors.name}</p>}

                <input
                  name="username"
                  className="Material-KitSB2"
                  placeholder="Username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                {formik.touched.username && formik.errors.username && (
                  <p className="error">{formik.errors.username}</p>
                )}

                <input
                  name="email"
                  className="Material-KitSB2"
                  placeholder="Email Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="error">{formik.errors.email}</p>
                )}
                

                {/* {user && user.provider === 'local' && ( */}
                {profile && (
                  <div className="password-change-section">
                    <h4 className="label Material-KitButton">Change Password</h4>
                    {auth.me?.role !== 'SUPERADMIN' && (
                      <>
                        <input
                          type="password"
                          name="oldPassword"
                          className="Material-KitSB2"
                          placeholder="Current Password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.oldPassword}
                        />
                        {formik.touched.oldPassword && formik.errors.oldPassword && (
                          <p className="error">{formik.errors.oldPassword}</p>
                        )}
                      </>
                    )}
                    <input
                      type="password"
                      name="password"
                      className="Material-KitSB2"
                      placeholder="New Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <p className="error">{formik.errors.password}</p>
                    )}
                    {formik.values.password && (
                      <>
                        <input
                          type="password"
                          name="confirmPassword"
                          className="Material-KitSB2"
                          placeholder="Confirm New Password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmPassword}
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                          <p className="error">{formik.errors.confirmPassword}</p>
                        )}
                      </>
                    )}
                  </div>
                )}
                <div className="user__buttons">
                  <Button
                    type="submit"
                    className="save-new-pod submit-btn Material-KitButton bg"
                    disabled={formik.isSubmitting}
                  >
                    Save
                  </Button>
                  {/* <Button
                    type="button"
                    onClick={handleCancel}
                    className="cancel-btn submit-btn Material-KitButton bg"
                    disabled={formik.isSubmitting}
                  >
                    Cancel
                  </Button> */}
                </div>

                {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
                {error && <p className="error">{error}</p>}
                {/* {users.error && <p className="error">{users.error}</p>} */}
              </form>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getProfile, editUser, deleteUser }),
)(Profile);
