import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';

import { addDepartment } from '../../store/actions/departmentActions';
import { colorPickerColors } from '../../constants/index';
import { departmentFormSchema } from './validation';
import Button from '../Buttons/Button';

import './styles.css';

const DepartmentForm = ({ addDepartment, accountId, projectId, department: { departments } }) => {
  const [color, setColor] = useState('#000000');
  const formik = useFormik({
    initialValues: {
      name: '',
      project: projectId,
      users: [],
      color: color,
      viewOnly: false,
      external: false,
      account: accountId,
    },
    validationSchema: departmentFormSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log("Department form values:", values)
      
      let submitValues = {
        name: values.name,
        project: values.project,
        users: values.users,
        color: values.color,
        viewOnly: values.viewOnly,
        external: values.external,
        account: values.account,
      };
      if (!accountId) {
        delete submitValues.account;
      }
      if (!projectId || projectId === '') {
        delete submitValues.project;
      } 

      addDepartment(submitValues);
      setColor('#000000');
      resetForm();
    },
  });

  const isSubmiting = departments.some((d) => d.id === 0);

  return (
    <div className="form-container">
      <h2 className="Material-KitH4">Create New Department</h2>
      <form onSubmit={formik.handleSubmit}>
        <input type="hidden" name="id" />
        <h3 className="label Material-KitCaption">Department Name</h3>
        <input
          name="name"
          placeholder="Department name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          disabled={isSubmiting}
          className="Material-KitSB2"
        />
        {formik.touched.name && formik.errors.name ? (
          <p className="error Material-KitCaption">{formik.errors.name}</p>
        ) : null}

        <h3 className="label Material-KitCaption">Color</h3>
        <div className="color-picker margin-bottom-sm">
          {colorPickerColors.map((i) => (
            <button
              type="button"
              key={i}
              className="color-button "
              style={{
                backgroundColor: i,
                border: color === i ? '2px solid var(--primary-primary-80)' : 'none',
              }}
              onClick={() => {
                setColor(i);

                formik.setFieldValue('color', i);
              }}
            />
          ))}
        </div>

        
        <div className="flex-left flex-row flex-gap-36">
          <div className="full-width">
            <h3 className="label Material-KitCaption">View Only</h3>
            <select
              name="viewOnly"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.viewOnly}          
              disabled={false}
              className="Material-KitSB2 form-select"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
            {formik.touched.viewOnly && formik.errors.viewOnly ? (
              <p className="error">{formik.errors.viewOnly}</p>
            ) : null}
          </div>
          <div className="full-width">
            <h3 className="label Material-KitCaption">External</h3>
            <select
              name="external"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.external}          
              disabled={false}
              className="Material-KitSB2 form-select"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
            {formik.touched.external && formik.errors.external ? (
              <p className="error">{formik.errors.external}</p>
            ) : null}
          </div>
        </div>
        

        

        <div className="form-actions">
          <Button
            type="submit"
            className="save-new-pod submit-btn unique-btn Material-KitButton bg"
            disabled={isSubmiting}
          >
            Add Department
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  department: state.department,
});

export default connect(mapStateToProps, { addDepartment })(DepartmentForm);
