import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Department from '../Department/Department';
import Loader from '../Loader/Loader';
import './styles.css';

const DepartmentList = ({
  project,
  setDepartment,
  onOpenForm,  
}) => {  

  return (
    <div className="department-list">
      {project.departments?.length > 0 ? project.departments?.map((department, index) => {
        return (
          <Department
            key={index}
            department={department}
            project={project}
            setDepartment={setDepartment}
            onOpenForm={onOpenForm}
          />
        );
      }) : <p className="Material-KitB1">No departments created</p>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  department: state.department
});

export default connect(mapStateToProps, { })(DepartmentList);
