import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Layout from '../../layout/Layout';
// import MessageList from '../../components/MessageList/MessageList';
import ActiveProjectList from '../../components/DashboardComponents/ActiveProjectList/ActiveProjectList';
import ArchivedProjectList from '../../components/DashboardComponents/ArchivedProjectList/ArchivedProjectList';
import requireAuth from '../../hoc/requireAuth';

import './styles.css';

const Archive = ({ match, auth }) => {
  const accountId = (match && match.params.accountId) || auth.me?.account;
  return (
    <Layout>
      <div className="adaptive-container">
        <div className="archive-page">
          {/* 
        <h1>Dashboard</h1> */}
          {/* <div className="project-list">            
            <ActiveProjectList />
            <ProjectForm />            
          </div> */}
          <div className="project-row">
            <ArchivedProjectList accountId={accountId}/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(requireAuth, connect(mapStateToProps))(Archive);
