// import React, { useState, useEffect } from 'react';
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';



const TagFilter = ({ tags, tagFilter, toggleTagFilter, project }) => {
  
    

    // VERTICAL SCROLL
    const tagScrollRef = useRef(null);
    const [ isMouseDown, setIsMouseDown ] = useState(false);
    const [ startX, setStartX ] = useState(0);
    const [ scrollLeft, setScrollLeft ] = useState(0);
    
    const handleMouseDown = (e) => {
        setIsMouseDown(true);
        // setStartX(e.x);
        setStartX(e.pageX - - tagScrollRef.current.offsetLeft);
        setScrollLeft(tagScrollRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsMouseDown(false);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleMouseMove = (e) => {
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - tagScrollRef.current.offsetLeft;
        const walk = (x - startX) * 1;
        tagScrollRef.current.scrollLeft = scrollLeft - walk;
    };
    // END VERTICAL SCROLL
  

    if (tags.length > 0) {
        return (    
            <div 
                ref={tagScrollRef} 
                id="department-scrollable-filter" 
                className="project-title-bar-right-filter-container mobile-scrollable-filters"
                // onWheel={(e) => console.log('e', e)}
                // onClick={() => deptScrollRef.current.scrollLeft += 20}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
            >
                
                <div className="project-title-bar-right-filter-title">Tags:</div>
                <div
                    key='none'
                    className={`project-title-bar-right-filter-button-department ${
                    tagFilter['none'] ? 'filter-active' : ''
                    }`}
                    style={{ backgroundColor: project.taskColoredBy === 1 ? 'white' : 'var(--brand-color)', color: project.taskColoredBy === 1 || tagFilter['none']? '#131523' : 'white' }}
                    onClick={() => toggleTagFilter('none')}
                >
                    NONE
                    {tagFilter['none'] ? (
                    ''
                    ) : (
                    <>
                        &nbsp; <FontAwesomeIcon icon={faXmark} />
                    </>
                    )}
                </div>
                {tags.filter((tag) => !tag.viewOnly).map((tag) => (
                    <div
                        key={tag.id}
                        className={`project-title-bar-right-filter-button-department ${
                        tagFilter[tag.id] ? 'filter-active' : ''
                        }`}
                        style={{ backgroundColor: project.taskColoredBy === 1 ? tag.color : 'var(--brand-color)', color: project.taskColoredBy === 1 || tagFilter[tag.id]? '#131523' : 'white' }}
                        onClick={() => toggleTagFilter(tag.id)}
                    >
                        {tag.name}
                        {tagFilter[tag.id] ? (
                        ''
                        ) : (
                        <>
                            &nbsp; <FontAwesomeIcon icon={faXmark} />
                        </>
                        )}
                    </div>
                ))}
            </div>
        );
    } else {
        return null;
    }
  
};

const mapStateToProps = (state) => ({
  auth: state.auth,    
});


export default connect(mapStateToProps)(TagFilter);