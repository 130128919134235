import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import requireAuth from '../../../hoc/requireAuth';
import Project from '../Project/Project';
import Button from '../../Buttons/Button';

import { getProjects } from '../../../store/actions/projectActions';
import { getAccount } from '../../../store/actions/accountActions';
import './styles.css';

const ActiveProjectList = ({  
  auth,
  getProjects,
  getAccount,
  project: { projects, isLoading, error },
  account: { account, isLoading: accountLoading },
  onUnsetForm,
  currentOpenForm,
  isMobile = false,
  onOpenForm,
  selectedProject,
  accountId,
}) => {

  

  useEffect(() => {
    getProjects(accountId, false);
    if (accountId) {
      getAccount(accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  return (
    <div className={`project-list ${isMobile ? 'only-child' : ''}`}>
      {projects.length > 0 ? (
        <>
          <div className="project-list__title">
            <h2 className="flex flex-column">Active Pods<span className="Material-KitOverline">{(auth.me?.role !== 'SUPERADMIN' || accountId) && account?.name }</span></h2>

            {auth.me?.role === 'SUPERADMIN' || account?.admins?.some(admin => admin.id === auth.me?.id) || auth.me?.role === 'PODMASTER' ? (
              <div className="project-list__title-buttons">
                <Button
                  onClick={() => {
                    currentOpenForm === 'add' ? onUnsetForm() : onOpenForm('add');
                  }}
                  className="project-list__append"
                  size="small bg"
                >
                  <div className="project-list__image">
                    <img src="/icons/add.svg" alt="Add Project" />
                  </div>
                </Button>
                <Link className="header-link" to="/archive">
                  <Button size="large bg" className="project-list__archive">
                    <div className="project-list__image">
                      <img src="/icons/archive.svg" alt="Archive" />
                    </div>
                    <span>Archive</span>
                  </Button>
                </Link>
              </div>
            ) : null}
          </div>
          {error && <div className="error-center Material-KitCaption">{error}</div>}
          <div className="list">
            {/* {isLoading ? (
              <Loader />
            ) : ( */}
            <>
              {projects.map((project, index) => {
                return (
                  <Project
                    key={index}
                    project={project}
                    archived={false}
                    currentOpenForm={currentOpenForm}
                    onUnsetForm={onUnsetForm}
                    onOpenForm={onOpenForm}
                    selected={selectedProject === project.id}
                    account={account}
                  />
                );
              })}
            </>
            {/* )} */}
          </div>
        </>
      ) : (
        <>
          <div className="project-list__title">
            <h2 className="flex flex-column">Active Pods<span className="Material-KitOverline">{(auth.me?.role !== 'SUPERADMIN' || accountId) && account?.name }</span></h2>

            {auth.me?.role === 'SUPERADMIN' || account?.admins?.some(admin => admin.id === auth.me?.id) || auth.me?.role === 'PODMASTER' ? (
              <div className="project-list__title-buttons">
                <Button
                  onClick={() => {
                    // onOpenForm(null);
                    currentOpenForm === 'add' ? onUnsetForm() : onOpenForm('add');
                  }}
                  className="project-list__append"
                  size="small"
                >
                  <div className="project-list__image">
                    <img src="/icons/add.svg" alt="Add Project" />
                  </div>
                </Button>
                <Link className="header-link" to="/archive">
                  <Button size="large" className="project-list__archive">
                    <div className="project-list__image">
                      <img src="/icons/archive.svg" alt="Archive" />
                    </div>
                    <span>Archive</span>
                  </Button>
                </Link>
              </div>
            ) : null}
          </div>
          <div className="no-projects Material-KitH5">
            No active pods. Create a new project to get started or ask your Pod master to add you to
            a project.
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
  account: state.account,
});

export default  compose(requireAuth, connect(mapStateToProps, { getProjects, getAccount }))(ActiveProjectList);
