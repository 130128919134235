import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import Button from '../../components/Buttons/Button';
import { getUsers } from '../../store/actions/usersActions';
import { getAccount } from '../../store/actions/accountActions';
import Layout from '../../layout/Layout';
import Loader from '../../components/Loader/Loader';
import requireAuth from '../../hoc/requireAuth';
import { connect } from 'react-redux';
import UserForm from '../../components/UserForm/UserForm';
import User from '../../components/User/User';
import UserFormEdit from '../../components/UserForm/UserFormEdit';
import './styles.css';

const Users = ({ 
  getUsers, 
  getAccount,
  users: { users, isLoading }, 
  account: { account, isLoading: accountLoading },
  auth,
  match
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isFormEditVisible, setIsFormEditVisible] = useState(false);
  const [openedUser, setOpenedUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const accountId = match.params.accountId || auth.me?.account;
  

  useEffect(() => {
    getUsers(accountId);
    if (accountId) {
      getAccount(accountId);
    }
  }, [getUsers, accountId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const handleOpenForm = (userID) => {
    if (userID) {
      // If clicking same user that's already open, close the form
      if (userID === openedUser) {
        setIsFormEditVisible(false);
        setIsFormVisible(false);
        setOpenedUser(null);
      } else {
        // If clicking different user, show edit form for that user
        setIsFormEditVisible(true);
        setIsFormVisible(false);
        setOpenedUser(userID);
      }
    } else {
      // Handle create new user form toggle
      setIsFormEditVisible(false);
      setIsFormVisible(!isFormVisible);
      setOpenedUser(null);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = users.filter(
      (user) =>
        user.name?.toLowerCase().includes(term) ||
        user.email?.toLowerCase().includes(term) ||
        user.username?.toLowerCase().includes(term),
    );

    setFilteredUsers(filtered);
  };

  /* const handleAppendClick = () => {
    if (isFormVisible && !openedUser) {
      setIsFormVisible(false);
    } else {
      setOpenedUser(null);
      setIsFormVisible(true);
    }
  }; */
  return (
    <Layout>
      <div className="users__container adaptive-container ">
        <div className="users">
          <div className={`users__row ${isFormVisible || isFormEditVisible ? 'opened' : ''}`}>
            {(!isMobile || !isFormVisible || !isFormEditVisible) && (
              <div className={`users__column users__menu ${isMobile ? 'only-child' : ''}`}>
                <div className="users__heading">
                  {/* <h4 className="users__title Material-KitH5">Users</h4> */}
                  <h2 className="users__title">Users<span className="Material-KitOverline">{(auth.me?.role !== 'SUPERADMIN' || accountId) && account?.name }</span></h2>
                  
                  <div className="users__actions actions-users">
                    <div className="actions-users__search actions__search">
                      <input
                        type="text"
                        className="actions-users__search-input Material-KitSB2"
                        placeholder="Search users..."
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                      <div className="actions-users__search-icon">
                        <img src="/icons/search.svg" alt="Search" />
                      </div>
                    </div>
                    <Button
                      className="button-append"
                      onClick={() => {
                        handleOpenForm(null);
                      }}
                      size="small"
                    >
                      <div className="button-append__image">
                        <img src="/icons/add.svg" alt="Press + to start" />
                      </div>
                    </Button>
                  </div>
                </div>

                <div className="list list-users">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {filteredUsers.map((user) => (
                        <User
                          key={user.id}
                          user={user}
                          isFormVisible={isFormVisible}
                          onOpenForm={handleOpenForm}
                          getUsers={getUsers}
                          selected={openedUser === user.id}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}
            {(auth.me?.role === 'SUPERADMIN' || account?.admins.some(admin => admin.id === auth.me.id)) && (
              <>
                {isFormVisible || isFormEditVisible ? (
                  openedUser ? (
                    <UserFormEdit 
                      userID={openedUser}                       
                      onOpenForm={handleOpenForm}
                      getUsers={getUsers}
                      accountId={accountId}
                      setSearchTerm={setSearchTerm}
                    />
                  ) : (
                    <UserForm 
                      userID={openedUser} 
                      onOpenForm={handleOpenForm}
                      getUsers={getUsers}
                      accountId={accountId}
                      setSearchTerm={setSearchTerm}
                    />
                  )
                ) : (
                  !isMobile && (
                    <div className="users__column placeholder-action">
                      <h4 className="Material-KitH4">Choose an action to start</h4>
                      <h5 className="Material-KitH5">
                        You can edit an existing user, or create a new one
                      </h5>
                      <div className="placeholder-project-list__image">
                        <img src="/images/dashboard/Decoration.svg" alt="Press + to start" />
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
  auth: state.auth,
  account: state.account,
});

export default compose(requireAuth, connect(mapStateToProps, { getUsers, getAccount }))(Users);
