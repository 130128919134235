import {
  GET_TAGS_LOADING,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  GET_TAG_LOADING,
  GET_TAG_SUCCESS,
  GET_TAG_FAIL,  
  ADD_TAG_LOADING,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  DELETE_TAG_LOADING,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  EDIT_TAG_LOADING,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAIL,
  CLEAR_TAG_ERROR,
} from '../types';

const initialState = {
  tags: [],
  tag: {},
  isLoading: false,
  error: null
};

// You could have an array [{ id: 1, isLoading: false, error: null, text: "Hey" }, { id: 2, isLoading: true, error: null, text: null }]

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TAGS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TAG_LOADING:
      return {
        ...state,
        isLoading: true,
      };    
    case ADD_TAG_LOADING:
      return {
        ...state,
        tags: [
          {
            id: 0,
            name: 'Loading...',
            color: '#000000',            
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),            
            project: { ...payload.tag.project },
          },
          ...state.tags,
        ],
      };
    case DELETE_TAG_LOADING:
    case EDIT_TAG_LOADING:
      return {
        ...state,
        tags: state.tags.map((d) => {
          if (d.id === payload.id) return { ...d, isLoading: true };
          return d;
        }),
      };
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tags: payload.tags,
      };
    case GET_TAG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tag: payload.tag,
      };    
    case ADD_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.map((d) => {
          if (d.id === 0) return payload.tag;
          return d;
        }),
      };
    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.filter((d) => d.id !== payload.tag.id),
      };
    case EDIT_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.map((d) => {
          if (d.id === payload.tag.id) return payload.tag;
          return d;
        }),
      };
    case DELETE_TAG_FAIL:
    case EDIT_TAG_FAIL:
      return {
        ...state,
        error: null,
        tags: state.tags.map((d) => {
          if (d.id === payload.id) return { ...d, isLoading: false, error: payload.error };
          return d;
        }),
      };
    case GET_TAGS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_TAG_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };    
    case ADD_TAG_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
        tags: state.tags.filter((d) => d.id !== 0),
      };
    case CLEAR_TAG_ERROR:
      return {
        ...state,
        tags: state.tags.map((d) => {
          if (d.id === payload.id) return { ...d, isLoading: false, error: null };
          return d;
        }),
      };
    default:
      return state;
  }
}
