import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import Loader from '../../Loader/Loader';
import Button from '../../Buttons/Button';
import TagList from '../../TagList/TagList';
import TagForm from '../../TagForm/TagForm';

import requireAuth from '../../../hoc/requireAuth';
import { getProjectUsers } from '../../../store/actions/projectActions';
import './ProjectTagsEdit.css';

const ProjectTagsEdit = ({
  getProjectUsers,
  thisProject: { project, isLoading, error },
  onUnsetForm,
  onReturnBack,
  setTag,
  projectID,
  onOpenForm,
}) => {
  useEffect(() => {
    getProjectUsers(projectID, true);
  }, [getProjectUsers, projectID]);

  return (
    <div className="tags-edit-page dashboard-form project-list">
      {error && <div className="error-center">{error}</div>}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project-form__heading flex align-center justify-space-between">
            <h2 className="Material-KitH4">Tags</h2>
            <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
              <div className="btn__image">
                <img src="/icons/arrow_back.svg" alt="previous" />
              </div>
            </Button>
          </div>
          <div className="tags-list-container list flex flex-column">
            {/* Tags */}
            <TagList
              setTag={setTag}
              onOpenForm={onOpenForm}
              projectId={projectID}
              project={project}
            />
            <TagForm projectId={projectID} />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  thisProject: state.project,
});

// export default compose(requireAuth, withRouter, connect(mapStateToProps))(ProjectTagsEdit);
export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getProjectUsers }),
)(ProjectTagsEdit);
