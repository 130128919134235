import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import requireAuth from '../../hoc/requireAuth';
import Task from '../../components/Task/Task';
import ManyTasks from '../../components/ManyTasks/ManyTasks';
import { editTask } from '../../store/actions/taskActions';
import './styles.css';
import { useDrop } from 'react-aria';

const DailyTaskCell = ({
  dayNumber,
  department,
  tasks,  
  tags,
  project,
  hideApproveDecline,
  auth,
  editTask,
  deconstructTask,
  deconstructValues,
  desconstructAudit,
  cellType,
  date,
  expandTasks,
  setExpandTasks,
  tagFilter,
}) => {
  let [dropped, setDropped] = useState(null);
  let ref = useRef(null);
  let [noTasks, setNoTasks] = useState(true);
  let [displayedTasks, setDisplayedTasks] = useState([]);
  let [manyTasks, setManyTasks] = useState(false);
  let { dropProps, isDropTarget } = useDrop({
    ref,
    async onDrop(e) {
      let items = await Promise.all(
        e.items
          .filter(
            (item) =>
              item.kind === 'text' && (item.types.has('text/plain') || item.types.has('task')),
          )
          .map(async (item) => {
            if (item.types.has('task')) {
              // console.log('onDrop e.item task', await item.getText('task'));
              return await item.getText('task');
            } else {
              return { message: await item.getText('text/plain') };
            }
          }),
      );
      // console.log('onDrop items', items);
      setDropped(items);
    },
  });

  // let message = ['Drop here'];
  // useEffect(() => {
  //   if (dropped) {
  //     // message = dropped.map((d) => {
  //     // console.log('dropped', dropped);
  //     // console.log('cellType', cellType);

  //     try {
  //       let taskID = dropped[0].id;
  //       const task = deconstructTask(dropped[0]);
  //       let taskNew = dropped[0];

  //       taskNew.dueDate = moment().add(dayNumber, 'days').format('YYYY-MM-DD');

  //       let setDelay = task.delay;

  //       if (typeof task.dueDate !== 'object' && task.approved === true) {
  //         // console.log('task.dueDate', task.dueDate);
  //         // console.log('moment().add(dayNumber, \'days\')', moment().add(dayNumber, 'days'));
  //         // console.log('moment(task.dueDate)', moment(task.dueDate));
  //         if (
  //           moment().add(dayNumber, 'days').format('YYYY-MM-DD') >
  //             moment(task.dueDate).format('YYYY-MM-DD') &&
  //           task.delay < 3
  //         ) {
  //           setDelay = setDelay + 1;
  //         }
  //       }

  //       if (cellType === 'escalate') {
  //         setDelay = 3;
  //       }

  //       if (cellType === 'pacing') {
  //         setDelay = 0;
  //         taskNew.dueDate = null;
  //       }

  //       taskNew.department = department.id;
  //       taskNew.project = project.id;
  //       taskNew.assignedTo = taskNew.assignedTo.hasOwnProperty('id') ? taskNew.assignedTo.id : '';

  //       // Reset assignedTo if department is changed
  //       if (taskNew.department !== task.department) {
  //         taskNew.assignedTo = '';
  //       }

  //       let audit = desconstructAudit(taskNew.audit);
  //       if (task.approved === true || taskNew.audit.length === 1) {
  //         audit.push({
  //           user: auth.me.id,
  //           action: 'updated',
  //           date: moment().toISOString(),
  //           old: task,
  //           new: deconstructValues(taskNew),
  //         });
  //       } else {
  //         let lastChange = audit.pop();
  //         lastChange.new = taskNew;
  //         audit.push(lastChange);
  //       }

  //       // console.log('task', task);
  //       // console.log('taskNew', taskNew);
  //       // console.log('audit', audit);

  //       editTask(taskID, {
  //         title: taskNew.title,
  //         description: taskNew.description,
  //         complete: taskNew.complete,
  //         assignedTo: taskNew.assignedTo === '' ? null : taskNew.assignedTo,
  //         department: taskNew.department,
  //         project: taskNew.project,
  //         approved: false,
  //         delay: setDelay,
  //         dueDate: moment(taskNew.dueDate).toISOString(),
  //         audit: audit,
  //       });
  //     } catch (err) {
  //       console.log('error', err);
  //     }

  //     setDropped(null);      
  //     // return <div key={moment()}>{message}</div>;
  //     // });
  //   }
  // }, [dropped]);

  useEffect(() => {

    let analyzedTasks = [];
    let noTasksCheck = true;
    
    // let manyTasks = false;
    if (cellType === 'escalate') {
      analyzedTasks = tasks.filter((task) => task.department.id === department.id && task.delay === 3);
      
    } else if (cellType === 'pacing') {
      analyzedTasks = tasks.filter((task) => task.department.id === department.id && (task.dueDate === null || typeof task.dueDate === 'object'));
      
    } else if (cellType === 'due') {
      analyzedTasks = tasks.filter(
        (task) =>
          task.department.id === department.id &&
          task.dueDate !== null &&
          typeof task.dueDate !== 'object' &&
          task.delay < 3 &&
          moment(task.dueDate).format('YYYY-MM-DD') <=
          moment().add(-1, 'days').format('YYYY-MM-DD'),
      );
      
    } else if (cellType === 'completed') {
      analyzedTasks = tasks.filter(
        (task) =>
          task.department.id === department.id &&
          task.dueDate !== null &&
          typeof task.dueDate !== 'object' &&              
          moment(task.dueDate).format('YYYY-MM-DD') === date,
      )
      
    } else {
      analyzedTasks = tasks.filter(
        (task) =>
          task.department.id === department.id &&
          task.dueDate !== null &&
          typeof task.dueDate !== 'object' &&
          task.delay < 3 &&
          moment(task.dueDate).format('YYYY-MM-DD') ===
          moment().add(dayNumber, 'days').format('YYYY-MM-DD'),
      )
      
    }
    if (tagFilter) {
      analyzedTasks = analyzedTasks.filter((task) => (task.tag.id !== '' && !tagFilter[task.tag.id]));
      if (tagFilter['none']) {
        analyzedTasks = analyzedTasks.filter((task) => task.tag.id);
      }
    }

    noTasksCheck = analyzedTasks.length === 0;

    if (dropped) {
      // message = dropped.map((d) => {
      // console.log('dropped', dropped);
      // console.log('cellType', cellType);

      try {
        // console.log('Dropped Task:', dropped[0]);
        let taskID = dropped[0].id;
        const task = deconstructTask(dropped[0]);
        let taskNew = dropped[0];
        
        

        taskNew.dueDate = moment().add(dayNumber, 'days').format('YYYY-MM-DD');

        let setDelay = task.delay;

        if (typeof task.dueDate !== 'object' && task.approved === true) {
          // console.log('task.dueDate', task.dueDate);
          // console.log('moment().add(dayNumber, \'days\')', moment().add(dayNumber, 'days'));
          // console.log('moment(task.dueDate)', moment(task.dueDate));
          if (
            moment().add(dayNumber, 'days').format('YYYY-MM-DD') >
              moment(task.dueDate).format('YYYY-MM-DD') &&
            task.delay < 3
          ) {
            setDelay = setDelay + 1;
          }
        }

        if (cellType === 'escalate') {
          setDelay = 3;
        }

        if (cellType === 'pacing') {
          setDelay = 0;
          taskNew.dueDate = null;
        }

        taskNew.department = department.id;
        taskNew.project = project.id;
        taskNew.assignedTo = taskNew.assignedTo.hasOwnProperty('id') ? taskNew.assignedTo.id : '';
        taskNew.tag = taskNew.tag.hasOwnProperty('id') ? taskNew.tag.id : '';

        // Reset assignedTo if department is changed
        if (taskNew.department !== task.department) {
          taskNew.assignedTo = '';
        }

        let audit = desconstructAudit(taskNew.audit);
        if (task.approved === true || taskNew.audit.length === 1) {
          audit.push({
            user: auth.me.id,
            action: 'updated',
            date: moment().toISOString(),
            old: task,
            new: deconstructValues(taskNew),
          });
        } else {
          let lastChange = audit.pop();
          lastChange.new = taskNew;
          audit.push(lastChange);
        }

        editTask(taskID, {
          title: taskNew.title,
          description: taskNew.description,
          complete: taskNew.complete,
          assignedTo: taskNew.assignedTo === '' ? null : taskNew.assignedTo,
          department: taskNew.department,
          tag: taskNew.tag === '' ? null : taskNew.tag,
          project: taskNew.project,
          approved: false,
          delay: setDelay,
          dueDate: moment(taskNew.dueDate).toISOString(),
          audit: audit,
        });

        analyzedTasks.push({
          ...taskNew,
          approved: false,
          department: department, 
          tag: taskNew.tag === "" ? {} : (tags.find(tag => tag.id === taskNew.tag) || {}),
          project: project, 
          assignedTo: taskNew.assignedTo === "" ? {} : (department.users.find(user => user.id === taskNew.assignedTo) || {}),
          dropped: true
        })

        noTasksCheck = false;
        setDropped(null);

      } catch (err) {
        console.log('error', err);
      }

      
      
    }

    setNoTasks(noTasksCheck);
    setDisplayedTasks(analyzedTasks);
    // analyzedTasks.length && console.log('analyzedTasks', analyzedTasks);
    if (analyzedTasks.length >= 3) {
      setManyTasks(true);          
    } else {
      setManyTasks(false);
      if (expandTasks?.[department.id+'-'+cellType] || expandTasks?.[department.id+'-'+cellType+'-'+dayNumber] || expandTasks?.[department.id+'-'+cellType+'-'+date]) {
        // console.log('closing all expanded tasks. expandTasks:', expandTasks);
        setExpandTasks({});
      }
    }
    

  }, [tasks, cellType, dropped, tagFilter, date])

  if (cellType === 'escalate') {
    return (
      <div
        {...dropProps}
        role="button"
        tabIndex={0}
        ref={ref}
        className={`project-view-page-body-row-task-cell droppable ${
          isDropTarget ? 'target' : ''
        } ${ 
          !noTasks ? '' : 'no-tasks'
        }`}
      >
        <div className="project-view-page-body-row-header-responsive-1">
          <div>
            ESCALATE
          </div>
        </div>

        {manyTasks && <div className="project-view-page-body-row-task-cell-tasks-stack">
          <ManyTasks 
            tasks={displayedTasks} 
            department={department} 
            project={project}
            expandTasks={expandTasks} 
            setExpandTasks={setExpandTasks}
            colType={'escalate'} 
          />
        </div>}

        <div className={`project-view-page-body-row-task-cell-tasks ${manyTasks ? 'many-tasks-container' : ''} ${expandTasks?.[department.id+'-escalate'] ? 'expanded-many-tasks-container' : ''}`}>
          {manyTasks && <div className="expanded-many-tasks-header-container">
            <div onClick={() => setExpandTasks({})} className="expanded-many-tasks-header-close-button">
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <div className="expanded-many-tasks-header-department">
              {department.name}
            </div>              
            <div className="expanded-many-tasks-header-column">
              Escalate
            </div>              
          </div>}
          <div className={`${manyTasks ? 'expanded-many-tasks-list' : ''}`}>
            {displayedTasks
              .map((task) => {              
                return (
                  <div key={task.dropped ? task.id+'-dropped' : task.id}>
                    <Task
                      task={task}
                      department={department}                      
                      tags={tags}
                      project={project}
                      hideApproveDecline={hideApproveDecline}
                      deconstructTask={deconstructTask}
                      deconstructValues={deconstructValues}
                      desconstructAudit={desconstructAudit}
                    />
                  </div>
                );                
            })}
          </div>
        </div>
                  
      </div>
    );
  } else if (cellType === 'pacing') {
    return (
      <div
        {...dropProps}
        role="button"
        tabIndex={0}
        ref={ref}
        className={`project-view-page-body-row-task-cell droppable ${
          isDropTarget ? 'target' : ''
        } ${
          !noTasks ? '' : 'no-tasks'
        }`}
      >
        <div className="project-view-page-body-row-header-responsive-1">
          <div>
            PACING
          </div>
        </div>
        {manyTasks && <div className="project-view-page-body-row-task-cell-tasks-stack">
          <ManyTasks 
            tasks={displayedTasks} 
            department={department} 
            project={project}
            expandTasks={expandTasks} 
            setExpandTasks={setExpandTasks} 
            colType={'pacing'} 
          /></div>}
        <div className={`project-view-page-body-row-task-cell-tasks ${manyTasks ? 'many-tasks-container' : ''} ${expandTasks?.[department.id+'-pacing'] ? 'expanded-many-tasks-container' : ''}`}>
          {manyTasks && <div className="expanded-many-tasks-header-container">
            <div onClick={() => setExpandTasks({})} className="expanded-many-tasks-header-close-button">
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <div className="expanded-many-tasks-header-department">
              {department.name}
            </div>              
            <div className="expanded-many-tasks-header-column">
              Pacing
            </div>              
          </div>}
          <div className={`${manyTasks ? 'expanded-many-tasks-list' : ''}`}>
            {displayedTasks
              .map((task) => {              
                  return (
                    <div key={task.dropped ? task.id+'-dropped' : task.id}>
                      <Task
                        task={task}
                        department={department}                        
                        tags={tags}
                        project={project}
                        hideApproveDecline={hideApproveDecline}
                        deconstructTask={deconstructTask}
                        deconstructValues={deconstructValues}
                        desconstructAudit={desconstructAudit}
                      />
                    </div>
                  );
                
              })}
            </div>
          </div>
          
      </div>
    );
  } else if (cellType === 'due') {
    return (
      <div
        className={`project-view-page-body-row-task-cell ${
          !noTasks ? '' : 'no-tasks'
        }`}
      >
        <div className="project-view-page-body-row-header-responsive-1">
          <div>
            DUE
          </div>
        </div>
        {manyTasks && <div className="project-view-page-body-row-task-cell-tasks-stack">
          <ManyTasks 
            tasks={displayedTasks} 
            department={department} 
            project={project}
            expandTasks={expandTasks} 
            setExpandTasks={setExpandTasks} 
            colType={'due'} 
          />
        </div>}
        <div className={`project-view-page-body-row-task-cell-tasks ${manyTasks ? 'many-tasks-container' : ''} ${expandTasks?.[department.id+'-due'] ? 'expanded-many-tasks-container' : ''}`}>
          {manyTasks && <div className="expanded-many-tasks-header-container">
            <div onClick={() => setExpandTasks({})} className="expanded-many-tasks-header-close-button">
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <div className="expanded-many-tasks-header-department">
              {department.name}
            </div>              
            <div className="expanded-many-tasks-header-column">
              Due
            </div>              
          </div>}
          <div className={`${manyTasks ? 'expanded-many-tasks-list' : ''}`}>
            {displayedTasks
              .map((task) => {                   
                return (
                  <div key={task.dropped ? task.id+'-dropped' : task.id}>
                    <Task
                      task={task}
                      department={department}                      
                      tags={tags}
                      project={project}
                      hideApproveDecline={hideApproveDecline}
                      deconstructTask={deconstructTask}
                      deconstructValues={deconstructValues}
                      desconstructAudit={desconstructAudit}
                    />
                  </div>
                );
              })}
          </div>
        </div>        
        
      </div>
    );
  } else if (cellType === 'completed') {
    return (
      <div
        // {...dropProps}
        role="button"
        // tabIndex={0}
        // ref={ref}
        className={`project-view-page-body-row-task-cell ${
          !noTasks ? '' : 'no-tasks'
        }`}
      >
        <div className="project-view-page-body-row-header-responsive-2">
          <div>
            { date ? 
              <>{moment(date).format('dddd').toUpperCase()} ({moment(date).format('MMM DD').toUpperCase()})</> 
              : 
              <>{moment().add(dayNumber, 'days').format('dddd').toUpperCase()} ({moment().add(dayNumber, 'days').format('MMM DD').toUpperCase()})</>
            }            
          </div>
        </div>
        {manyTasks && <div className="project-view-page-body-row-task-cell-tasks-stack">  
          <ManyTasks 
            tasks={displayedTasks} 
            department={department} 
            project={project}
            expandTasks={expandTasks} 
            setExpandTasks={setExpandTasks} 
            colType={'completed-'+date} 
          />
        </div>}
        <div className={`project-view-page-body-row-task-cell-tasks ${manyTasks ? 'many-tasks-container' : ''} ${expandTasks?.[department.id+'-completed-'+date] ? 'expanded-many-tasks-container' : ''}`}>
          {manyTasks && <div className="expanded-many-tasks-header-container">
            <div onClick={() => setExpandTasks({})} className="expanded-many-tasks-header-close-button">
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <div className="expanded-many-tasks-header-department">
              {department.name}
            </div>              
            <div className="expanded-many-tasks-header-column">
              {moment(date).format('dddd').toUpperCase()} ({moment(date).format('MMM DD').toUpperCase()})
            </div>              
          </div>}
          <div className={`${manyTasks ? 'expanded-many-tasks-list' : ''}`}>
          {displayedTasks
            .map((task) => {
              return (
                <div key={task.dropped ? task.id+'-dropped' : task.id}>
                  <Task
                    task={task}
                    department={department}                    
                    tags={tags}
                    project={project}
                    hideApproveDecline={hideApproveDecline}
                    deconstructTask={deconstructTask}
                    deconstructValues={deconstructValues}
                    desconstructAudit={desconstructAudit}                        
                  />
                </div>
              );
            })}
          </div>
        </div>
        
      </div>
    );
  } else {
    return (
      <div
        {...dropProps}
        role="button"
        tabIndex={0}
        ref={ref}
        className={`project-view-page-body-row-task-cell droppable ${
          isDropTarget ? 'target' : ''
        } ${
          !noTasks ? '' : 'no-tasks'
        }`}
      >
        <div className="project-view-page-body-row-header-responsive-2">
          <div>
            {moment().add(dayNumber, 'days').format('dddd').toUpperCase()} ({moment().add(dayNumber, 'days').format('MMM DD').toUpperCase()})
          </div>
        </div>
        {manyTasks && <div className="project-view-page-body-row-task-cell-tasks-stack">                               
          <ManyTasks 
            tasks={displayedTasks} 
            department={department}
            project={project}
            expandTasks={expandTasks} 
            setExpandTasks={setExpandTasks} 
            colType={'daily-'+dayNumber} 
          />
        </div>}
        <div className={`project-view-page-body-row-task-cell-tasks ${manyTasks ? 'many-tasks-container' : ''} ${expandTasks?.[department.id+'-daily-'+dayNumber] ? 'expanded-many-tasks-container' : ''}`}>
          {manyTasks && <div className="expanded-many-tasks-header-container">
            <div onClick={() => setExpandTasks({})} className="expanded-many-tasks-header-close-button">
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <div className="expanded-many-tasks-header-department">
              {department.name}
            </div>              
            <div className="expanded-many-tasks-header-column">
              {moment().add(dayNumber, 'days').format('dddd').toUpperCase()} ({moment().add(dayNumber, 'days').format('MMM DD').toUpperCase()})
            </div>              
          </div>}
          <div className={`${manyTasks ? 'expanded-many-tasks-list' : ''}`}>
          {displayedTasks
            .map((task) => {
              return (
                <div key={task.dropped ? task.id+'-dropped' : task.id}>
                  <Task
                    task={task}
                    department={department}                    
                    tags={tags}
                    project={project}
                    hideApproveDecline={hideApproveDecline}
                    deconstructTask={deconstructTask}
                    deconstructValues={deconstructValues}
                    desconstructAudit={desconstructAudit}
                  />
                </div>
              );
            })}
          </div>
        </div>
        
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  // task: state.task,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { editTask }),
)(DailyTaskCell);
