import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_TAGS_LOADING,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  GET_TAG_LOADING,
  GET_TAG_SUCCESS,
  GET_TAG_FAIL,
  ADD_TAG_LOADING,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  DELETE_TAG_LOADING,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  EDIT_TAG_LOADING,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAIL,
  CLEAR_TAG_ERROR,
} from '../types';

export const getTags = (projectId) => async (dispatch, getState) => {
  dispatch({
    type: GET_TAGS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/tags/project/${projectId}`,
      options,
    );

    dispatch({
      type: GET_TAGS_SUCCESS,
      payload: { tags: response.data.tags },
    });
  } catch (err) {
    dispatch({
      type: GET_TAGS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getTag = (tagId, history) => async (dispatch, getState) => {
  dispatch({
    type: GET_TAG_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/tags/${tagId}`,
      options,
    );

    dispatch({
      type: GET_TAG_SUCCESS,
      payload: { tag: response.data.tag },
    });
  } catch (err) {
    if (err?.response.status === 404) {
      history.push('/notfound');
    }
    dispatch({
      type: GET_TAG_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
export const getTagUsers = (tagId) => async (dispatch, getState) => {
  dispatch({
    type: GET_TAG_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/tags/${tagId}`,
      options,
    );

    dispatch({
      type: GET_TAG_SUCCESS,
      payload: { tag: response.data.tag },
    });
  } catch (err) {
    dispatch({
      type: GET_TAG_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
export const addTag = (formData) => async (dispatch, getState) => {
  dispatch({
    type: ADD_TAG_LOADING,
    payload: { tag: { ...getState().auth.tag } },
    // payload: { me: { ...getState().auth.me } },
  });
  // console.log("Tag formData:", formData);
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/tags`,
      formData,
      options,
    );

    // console.log("Tag response:", response);

    dispatch({
      type: ADD_TAG_SUCCESS,
      payload: { tag: response.data.tag },
    });
  } catch (err) {
    // console.log("Tag error:", err);
    dispatch({
      type: ADD_TAG_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteTag = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_TAG_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/tags/${id}`,
      options,
    );

    dispatch({
      type: DELETE_TAG_SUCCESS,
      payload: { tag: response.data.tag },
    });
  } catch (err) {
    dispatch({
      type: DELETE_TAG_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editTag = (id, formData) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_TAG_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/tags/${id}`,
      formData,
      options,
    );

    dispatch({
      type: EDIT_TAG_SUCCESS,
      payload: { tag: response.data.tag },
    });
  } catch (err) {
    dispatch({
      type: EDIT_TAG_FAIL,
      payload: { error: err?.response?.data.message || err.message, id },
    });
  }
};

export const clearTagError = (id) => ({
  type: CLEAR_TAG_ERROR,
  payload: { id },
});
