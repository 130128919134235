import React from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import Button from '../../Buttons/Button';
import { addProject } from '../../../store/actions/projectActions';
import { projectFormSchema } from './validation';

import './styles.css';

const ProjectForm = ({ addProject, project: { projects }, onUnsetForm, onReturnBack, accountId, auth }) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      projectPlan: '',
      risks: '',
      opportunities: '',
      resources: '',
      fontStyle: 'Reenie-Beanie',
      fontWeight: 'bold',
      fontSizeLarge: '37px',
      fontSizeSmall: '25px',
      account: accountId || auth.me?.account,
    },
    validationSchema: projectFormSchema,
    onSubmit: (values, { resetForm }) => {
      addProject({
        title: values.title,
        projectPlan: values.projectPlan,
        risks: values.risks,
        opportunities: values.opportunities,
        resources: values.resources,
        fontStyle: values.fontStyle,
        fontWeight: values.fontWeight,
        fontSizeLarge: values.fontSizeLarge,
        fontSizeSmall: values.fontSizeSmall,
        account: values.account,
      });
      resetForm();
      onUnsetForm();
    },
  });

  const isSubmiting = projects.some((p) => p.id === 0);

  return (
    <div className="dashboard-form project-list">
      <div className="project-form__heading flex align-center justify-space-between">
        <h2 className="Material-KitH4">Create a New Pod</h2>
        <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
          <div className="btn__image">
            <img src="/icons/arrow_back.svg" alt="previous" />
          </div>
        </Button>
      </div>
      <div className="list">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-section">
            <h4 className="label Material-KitButton">Pod Title</h4>
            <input
              name="title"
              className="Material-KitSB2"
              placeholder="Example Pod"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              disabled={isSubmiting}
            />
            {formik.touched.title && formik.errors.title ? (
              <p className="error Material-KitCaption">{formik.errors.title}</p>
            ) : null}

            {auth.me?.role === 'SUPERADMIN' ? (
              <>
                <h4 className="label Material-KitButton">Pod Account</h4>
                <input
                  name="account"
                  className="Material-KitSB2"
                  placeholder="Example Pod"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.account}
                  disabled={isSubmiting}
                />
                {formik.touched.account && formik.errors.account ? (
                  <p className="error Material-KitCaption">{formik.errors.account}</p>
                ) : null}
              </>
            ) : null}

          </div>

          {/* <div className="form-section">
            <h4 className="label Material-KitButton">Task Settings</h4>

            <h5 className="project-form-label Material-KitOverline">Font Style</h5>
            <select
              name="fontStyle"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fontStyle}
              style={{
                fontFamily: formik.values.fontStyle,
                fontWeight: formik.values.fontWeight,
              }}
              disabled={isSubmiting}
              className="form-select Material-KitSB2"
            >
              <option
                value="Brush Script MT"
                style={{ fontFamily: 'Brush Script MT', fontWeight: formik.values.fontWeight }}
              >
                Brush Script MT
              </option>
              <option
                value="Courier New"
                style={{ fontFamily: 'Courier New', fontWeight: formik.values.fontWeight }}
              >
                Courier New
              </option>
              <option
                value="Garamond"
                style={{ fontFamily: 'Garamond', fontWeight: formik.values.fontWeight }}
              >
                Garamond
              </option>
              <option
                value="'Klyakson', sans-serif"
                style={{
                  fontFamily: "'Klyakson', sans-serif",
                  fontWeight: formik.values.fontWeight,
                }}
              >
                Klyakson
              </option>
              <option
                value="Lato"
                style={{ fontFamily: 'Lato', fontWeight: formik.values.fontWeight }}
              >
                Lato
              </option>
              <option
                value="Reenie-Beanie"
                style={{ fontFamily: 'Reenie-Beanie', fontWeight: formik.values.fontWeight }}
              >
                Reenie Beanie
              </option>
              <option
                value="'Roboto', sans-serif"
                style={{ fontFamily: "'Roboto', sans-serif", fontWeight: formik.values.fontWeight }}
              >
                Roboto
              </option>
            </select>

            <h5 className="project-form-label Material-KitOverline">Font Weight</h5>
            <select
              name="fontWeight"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fontWeight}
              style={{
                fontFamily: formik.values.fontStyle,
                fontWeight: formik.values.fontWeight,
              }}
              disabled={isSubmiting}
              className="form-select Material-KitSB2"
            >
              <option
                value="bold"
                style={{ fontFamily: formik.values.fontStyle, fontWeight: 'bold' }}
              >
                Bold
              </option>
              <option
                value="normal"
                style={{ fontFamily: formik.values.fontStyle, fontWeight: 'normal' }}
              >
                Regular
              </option>
            </select>

            <h5 className="project-form-label Material-KitOverline">Font Size - Large Screen</h5>

            <select
              name="fontSizeLarge"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fontSizeLarge}
              style={{
                fontFamily: formik.values.fontStyle,
                fontWeight: formik.values.fontWeight,
                fontSize: formik.values.fontSizeLarge,
              }}
              disabled={isSubmiting}
              className="form-select"
            >
              <option
                value="25px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '25px',
                }}
              >
                XSmall
              </option>
              <option
                value="31px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '31px',
                }}
              >
                Small
              </option>
              <option
                value="37px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '37px',
                }}
              >
                Medium
              </option>
              <option
                value="43px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '43px',
                }}
              >
                Large
              </option>
              <option
                value="49px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '49px',
                }}
              >
                XLarge
              </option>
            </select>

            <h5 className="project-form-label Material-KitOverline">Font Size - Mobile Screen</h5>

            <select
              name="fontSizeSmall"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fontSizeSmall}
              style={{
                fontFamily: formik.values.fontStyle,
                fontWeight: formik.values.fontWeight,
                fontSize: formik.values.fontSizeSmall,
              }}
              disabled={isSubmiting}
              className="form-select"
            >
              <option
                value="17px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '17px',
                }}
              >
                XSmall
              </option>
              <option
                value="21px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '21px',
                }}
              >
                Small
              </option>
              <option
                value="25px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '25px',
                }}
              >
                Medium
              </option>
              <option
                value="29px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '29px',
                }}
              >
                Large
              </option>
              <option
                value="33px"
                style={{
                  fontFamily: formik.values.fontStyle,
                  fontWeight: formik.values.fontWeight,
                  fontSize: '33px',
                }}
              >
                XLarge
              </option>
            </select>
          </div> */}

          <div className="project__buttons">
            <Button
              type="submit"
              className="save-new-pod submit-btn Material-KitButton bg"
              disabled={isSubmiting}
            >
              Create
            </Button>
            <Button
              type="button"
              onClick={() => {
                formik.resetForm();
                onUnsetForm();
              }}
              className="cancel-btn submit-btn Material-KitButton bg"
              disabled={isSubmiting}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});

export default connect(mapStateToProps, { addProject })(ProjectForm);
