import React, { useState, useMemo, useEffect } from 'react';
import GoldenMoments from './GoldenMoments/GoldenMoments';
import { statuses } from '../../../constants/index';
import * as Yup from 'yup';
import Modal from './Modals/Modal';
import { updateHuddleBoard } from '../../../store/actions/huddleBoardAction';
import { connect, useSelector } from 'react-redux';
import PatientCentricity from './PatientCentricity/PatientCentricity';
import NonTipActions from './NonTipActions/NonTipActions';
import moment from 'moment';
const CustomLayout = ({ title, updateHuddleBoard, huddleBoard }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const departments = useSelector((state) => state.project.project.departments);
  const [rows, setRows] = useState(huddleBoard?.data?.length > 0 ? huddleBoard.data : []);
  
  useEffect(() => {
    setRows(huddleBoard?.data?.length > 0 ? huddleBoard.data : []);
  }, [huddleBoard]);

  const fields = useMemo(() => {
    switch (title) {
      case 'Golden Moments':
        return [
          {
            name: 'name',
            label: 'Name',
            type: 'text',
            defaultValue: rows[rowToEdit]?.name || '',
            validation: Yup.string().required('Name is required'),
          },
          {
            name: 'department',
            label: 'Department',
            type: 'select',
            defaultValue:
              rows[rowToEdit]?.department || (departments?.length ? departments[0].id : ''),
            validation: Yup.string().required('Department is required'),
          },
          {
            name: 'description',
            label: 'Description',
            type: 'textarea',
            defaultValue: rows[rowToEdit]?.description || '',
            validation: Yup.string().required('Description is required'),
          },
          {
            name: 'date',
            label: 'When',
            type: 'date',
            defaultValue: rows[rowToEdit]?.date || moment().format('YYYY-MM-DD'),
            validation: Yup.date().required('Date is required'),
          },
        ];
      case 'Non-Tip Actions':
        return [
          {
            name: 'actions',
            label: 'Actions',
            type: 'text',
            defaultValue: rows[rowToEdit]?.actions || '',
            validation: Yup.string().required('Actions are required'),
          },
          {
            name: 'date',
            label: 'Due',
            type: 'date',
            defaultValue: rows[rowToEdit]?.date || moment().format('YYYY-MM-DD'),
            validation: Yup.date().required('Date is required'),
          },
          {
            name: 'department',
            label: 'Department',
            type: 'select',
            defaultValue:
              rows[rowToEdit]?.department || (departments?.length ? departments[0].id : ''),
            validation: Yup.string().required('Department is required'),
          },
          {
            name: 'name',
            label: 'Name',
            type: 'text',
            defaultValue: rows[rowToEdit]?.name || '',
            validation: Yup.string().required('Name is required'),
          },
          {
            name: 'status',
            label: 'Status',
            type: 'button',
            isMulti: true,
            options: statuses,
            defaultValue: rows[rowToEdit]?.status || [],
            validation: Yup.array().min(1, 'Please select at least one status'),
          },
          /* {
            name: 'isComplete',
            label: 'Complete',
            type: 'select',
            defaultValue: rows[rowToEdit]?.isComplete || 'In progress',
            validation: Yup.string().required('Completion status is required'),
          }, */
        ];
      default:
        return [];
    }
  }, [title, departments, rowToEdit, rows]);
  const handleUpdate = (updatedRows) => {
    updateHuddleBoard(huddleBoard?.id, updatedRows, huddleBoard, 'Custom', moment());
  };

  const handleDeleteRow = (targetIndex) => {
    const updatedRows = rows.filter((_, idx) => idx !== targetIndex);
    setRows(updatedRows);
    handleUpdate(updatedRows);
  };

  const handleEditRow = (idx) => {
    setRowToEdit(idx);
    setModalOpen(true);
  };
  /* const handleMarkCompleteRow = (idx) => {
    const updatedRows = rows.map((row, index) =>
      index === idx ? { ...row, isComplete: true } : row,
    );
    setRows(updatedRows);
    handleUpdate(updatedRows);
  }; */
  const handleSubmit = (newRow) => {
    const updatedRows =
      rowToEdit === null
        ? [...rows, newRow]
        : rows.map((currRow, idx) => (idx === rowToEdit ? newRow : currRow));

    setRows(updatedRows);
    setRowToEdit(null);
    setModalOpen(false);
    handleUpdate(updatedRows);
  };
  const handleArchiveRow = (idx) => {
    const updatedRows = rows.map((row, index) => {
      if (index === idx && !row.status.includes('archived')) {
        return { ...row, status: [...row?.status, 'archived'] };
      } else {
        return row;
      }
    });
    setRows(updatedRows);
    handleUpdate(updatedRows);
  };
  const handleMarkCompleteRow = (idx) => {    
    const updatedRows = rows      
      .map((row, index) => {
        if (index === idx && !row.status.includes('completed')) {
          let cleanInProgress = row.status.filter((el) => el !== 'inProgress');
          return { ...row, status: [...cleanInProgress, 'completed'] };
        } else {
          return row;
        }
      });          
    setRows(updatedRows);
    handleUpdate(updatedRows);
  };
  const componentMapping = () => {
    switch (title) {
      case 'Golden Moments':
        return (
          <>
            <GoldenMoments
              rows={rows}
              deleteRow={handleDeleteRow}
              editRow={handleEditRow}
              title={title}
            />
            <div className="btn-container flex-center">
              <button className="btn-add" onClick={() => setModalOpen(true)}>
                + Add Golden Moment
              </button>
            </div>
            {modalOpen && (
              <Modal
                fields={fields}
                closeModal={() => {
                  setModalOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
                defaultValue={rowToEdit !== null ? rows[rowToEdit] : {}}
              />
            )}
          </>
        );
      case 'Client & Patient Centiricity':
        return (
          <PatientCentricity title={title} huddleBoard={huddleBoard} onUpdate={handleUpdate} />
        ); /* 
      case 'NON TIP Actions':
        return <NonTipActions title={title} huddleBoard={huddleBoard} onUpdate={handleUpdate} />;
 */
      case 'Non-Tip Actions':
        return (
          <>
            <NonTipActions
              rows={rows}
              deleteRow={handleDeleteRow}
              editRow={handleEditRow}
              onMarkCompleteRow={handleMarkCompleteRow}
              onArchiveRow={handleArchiveRow}
              title={title}
            />
            <div className="btn-container flex-center">
              <button className="btn-add" onClick={() => setModalOpen(true)}>
                + Add Non-Tip Action
              </button>
            </div>
            {modalOpen && (
              <Modal
                fields={fields}
                closeModal={() => {
                  setModalOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
                defaultValue={rowToEdit !== null ? rows[rowToEdit] : {}}
              />
            )}
          </>
        );
      default:
        return <p>No specified HuddleBoard found</p>;
    }
  };
  return <>{componentMapping()}</>;
};
export default connect(null, { updateHuddleBoard })(CustomLayout);
