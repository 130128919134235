// import React, { useState, useEffect } from 'react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faBoxArchive, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons';
import Button from '../../Buttons/Button';
import {
  deleteProject,
  editProject,
  clearProjectError,
} from '../../../store/actions/projectActions';
// import { projectFormSchema } from './validation';

import './styles.css';

const Project = ({
  project,
  archived,
  auth,
  deleteProject,
  editProject,
  clearProjectError,
  onOpenForm,
  selected,
  currentOpenForm,
  onUnsetForm,
  account,
}) => {
  // useEffect(() => {
  //   console.log('project', project);
  //   console.log('selected', selected, 'project id check', project.id);
  // }, [selected]);

  const handleArchive = (e, id, formData) => {
    e.preventDefault();
    editProject(id, formData);
  };

  const checkPermissions = () => {
    if (
      auth.me.id === project.user?.id ||
      auth.me.role === 'SUPERADMIN' ||
      project.podMasters.some((podMaster) => podMaster.id === auth.me.id) ||
      account?.admins?.some(admin => admin.id === auth.me?.id)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={`project${project.isLoading ? ' loader' : ''}${selected ? ' selected' : ''}`}>
      <div className="project-info">
        {auth.me?.role === 'SUPERADMIN' && <div className="project-info__meta ">          
          <div className="Material-KitCaption">{project.account?.name}</div>          
        </div>}
        <h3 className="title Material-KitH5">{project.title}</h3>
        
        <div className="project-info__meta ">
          {/* <span className="fullname">Created by {project.user.name}</span>
          &nbsp;<span className="time">{moment(project.createdAt).fromNow()}</span> */}
          <span className="Material-KitOverline edited">Created:</span>
          &nbsp;
          <span className="time Material-KitOverline">{moment(project.createdAt).fromNow()}</span>
        </div>
      </div>

      {auth.isAuthenticated && (
        <div className="project-actions">
          {!archived ? (
            <>
              <Link to={`/project/view/${project.id}`}>
                <Button
                  className={`project-actions__button ${!checkPermissions() ? 'alone' : ''}`}
                  size="small"
                >
                  <div className="btn__image">
                    <img src="/icons/visibility.svg" alt="View" />
                  </div>
                </Button>
              </Link>
              {checkPermissions() && (
                <>
                  {/* <Link to={`/project/edit/${project.id}`}> */}
                  <Button
                    className="project-actions__button"
                    size="small"
                    onClick={() => {
                      currentOpenForm === 'edit' ? onUnsetForm() : onOpenForm('edit', project.id);
                    }}
                  >
                    <div className="btn__image">
                      <img src="/icons/edit.svg" alt="Edit" />
                    </div>
                  </Button>
                  {/* 
                  </Link> */}
                  <Button
                    className="project-actions__button btn-gray"
                    onClick={(e) =>
                      handleArchive(e, project.id, {
                        title: project.title,
                        archived: !project.archived,
                        projectPlan: project.projectPlan,
                        risks: project.risks,
                        opportunities: project.opportunities,
                        resources: project.resources,
                      })
                    }
                    size="small"
                    type="button"
                  >
                    <div className="btn__image">
                      <img src="/icons/archive.svg" alt="Archive" />
                    </div>
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              {checkPermissions() && (
                <Button
                  className="project-actions__button btn-gray"
                  onClick={(e) =>
                    handleArchive(e, project.id, {
                      title: project.title,
                      archived: !project.archived,
                      projectPlan: project.projectPlan,
                      risks: project.risks,
                      opportunities: project.opportunities,
                      resources: project.resources,
                    })
                  }
                  size="small"
                  type="button"
                >
                  <div className="btn__image">
                    <img src="/icons/history.svg" alt="Unarchive" />
                  </div>
                </Button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteProject, editProject, clearProjectError })(Project);
