import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Link, useParams } from 'react-router-dom';
import Button from '../../components/Buttons/Button';
// import { getUsers } from '../../store/actions/usersActions';
import { getAccounts, getAccount } from '../../store/actions/accountActions';
import Layout from '../../layout/Layout';
import Loader from '../../components/Loader/Loader';
import requireAuth from '../../hoc/requireAuth';
import { connect } from 'react-redux';
// import UserForm from '../../components/UserForm/UserForm';
// import User from '../../components/User/User';
// import UserFormEdit from '../../components/UserForm/UserFormEdit';
import AccountForm from '../../components/AccountComponents/AccountForm';
import Account from '../../components/AccountComponents/Account';
import AccountFormEdit from '../../components/AccountComponents/AccountFormEdit';
import DepartmentEdit from '../../components/DashboardComponents/DepartmentEdit/DepartmentEdit';
import AccountDepartmentsEdit from '../../components/AccountComponents/AccountDepartmentsEdit/AccountDepartmentsEdit';
import AccountAdminsEdit from '../../components/AccountComponents/AccountAdminsEdit/AccountAdminsEdit';
import ProjectDepartmentsUsersEdit from '../../components/DashboardComponents/ProjectDepartmentsUsersEdit/ProjectDepartmentsUsersEdit';

// Use '../Users/styles.css' for styling
// import './styles.css';

const Accounts = ({ 
  getAccounts,  
  getAccount,
  account: { accounts, isLoading, error }, 
  auth 
}) => {
  const [openHistory, setOpenHistory] = useState([]);
  const [currentOpenForm, setCurrentOpenForm] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isFormEditVisible, setIsFormEditVisible] = useState(false);  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [account, setAccount] = useState(null);
  const [department, setDepartment] = useState(null);  
  const [admin, setAdmin] = useState(null);  
  let { accountId } = useParams();

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  useEffect(() => {

    setCurrentOpenForm(null);      
    if (accountId) {
      setAccount(accountId);      
      setCurrentOpenForm('edit');
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setFilteredAccounts(accounts);
  }, [accounts]);

  // const handleOpenForm = (accountID) => {
  //   if (accountID) {
  //     // If clicking same user that's already open, close the form
  //     if (accountID === account) {
  //       setIsFormEditVisible(false);
  //       setIsFormVisible(false);
  //       setOpenedAccount(null);        
  //     } else {
  //       // If clicking different user, show edit form for that user
  //       setIsFormEditVisible(true);
  //       setIsFormVisible(false);
  //       setOpenedAccount(accountID);        
  //     }
  //   } else {
  //     // Handle create new user form toggle
  //     setIsFormEditVisible(false);
  //     setIsFormVisible(!isFormVisible);
  //     setOpenedAccount(null);      
  //   }
  // };

  const handleOpenForm = (type = null, account = null) => {
    if (currentOpenForm && !openHistory.includes(currentOpenForm)) {
      setOpenHistory((prev) => [...prev, currentOpenForm]);
    }
    setCurrentOpenForm(type);
    
    account && setAccount(account);    
  };

  const handleUnsetForm = (account = null) => {
    setOpenHistory([]);
    setAccount(null);
    // setOpenedAccount(null);
    setCurrentOpenForm(null);
  };

  const handleReturnBack = () => {
    if (openHistory.length > 0) {
      const lastForm = openHistory[openHistory.length - 1];
      setOpenHistory((prev) => prev.slice(0, -1));
      setCurrentOpenForm(lastForm);
    } else {
      setAccount(null);
      // setOpenedAccount(null);
      setCurrentOpenForm(null);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = accounts.filter(
      (account) =>
        account.name?.toLowerCase().includes(term) ||
        account.description?.toLowerCase().includes(term) ||
        account.emailPrimary?.toLowerCase().includes(term) ||
        account.emailSecondary?.toLowerCase().includes(term) ||
        account.phone?.toLowerCase().includes(term),
    );

    setFilteredAccounts(filtered);
  };

  const renderForm = () => {
    switch (currentOpenForm) {
      case 'edit':
        return (
          <AccountFormEdit
            accountID={account}                       
            onOpenForm={handleOpenForm}            
            onUnsetForm={handleUnsetForm}
            currentOpenForm={currentOpenForm}            
            onReturnBack={handleReturnBack}
            setDepartment={setDepartment}
            setAdmin={setAdmin}
          />
        );
      case 'add':
        return (
          <AccountForm 
            onUnsetForm={handleUnsetForm} 
            onReturnBack={handleReturnBack}
            accountID={account} 
            onOpenForm={handleOpenForm}
            getAccounts={getAccounts} 
          />
        );
      case 'department/new':
        return (
          <AccountDepartmentsEdit
            accountID={account}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            setDepartment={setDepartment}
            onOpenForm={handleOpenForm}
          />
        );
      case 'department/edit':
        return (
          <DepartmentEdit
            accountID={account}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            departmentID={department}
            onOpenForm={handleOpenForm}
            currentOpenForm={currentOpenForm}            
          />
        );      
      case 'member/new':
        return (
          <ProjectDepartmentsUsersEdit
            departmentID={department}
            accountID={account}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}            
          />
        );
      case 'admin/new':
        return (
          <AccountAdminsEdit
            accountID={account}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            setAdmin={setAdmin}
            onOpenForm={handleOpenForm}
          />
        );
      default:
        return !isMobile ? (
          <div className="project-list__placeholder placeholder-project-list">
            <h4 className="Material-KitH4">Choose an action to start</h4>
            {(auth.me?.role === 'SUPERADMIN') ?
             <h5 className="Material-KitH5">You can view or edit an existing account, or create a new one</h5> :
             <h5 className="Material-KitH5">You can view or edit an existing account</h5>
            }
            <div className="placeholder-project-list__image">
              <img src="/images/dashboard/Decoration.svg" alt="Press + to start" />
            </div>
          </div>
        ) : null;
    }
  };
  if (!auth.isAuthenticated) return null;

  return (
    <Layout>
      <div className="users__container adaptive-container accounts-page">
        <div className="users">
          <div className={`users__row ${currentOpenForm !== null ? 'opened' : ''}`}>
            
              <div className={`users__column users__menu ${isMobile ? 'only-child' : ''}`}>
                <div className="users__heading">
                  {/* <h4 className="users__title Material-KitH5">Users</h4> */}
                  <h2 className="users__title">Accounts</h2>
                  <div className="users__actions actions-users">
                    <div className="actions-users__search actions__search">
                      <input
                        type="text"
                        className="actions-users__search-input Material-KitSB2"
                        placeholder="Search accounts..."
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                      <div className="actions-users__search-icon">
                        <img src="/icons/search.svg" alt="Search" />
                      </div>
                    </div>
                    <Button
                      className="button-append"
                      onClick={() => {
                        // handleOpenForm(null);
                        currentOpenForm === 'add' ? handleUnsetForm() : handleOpenForm('add');
                      }}
                      size="small"
                    >
                      <div className="button-append__image">
                        <img src="/icons/add.svg" alt="Press + to start" />
                      </div>
                    </Button>
                  </div>
                </div>

                <div className="list list-users">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {filteredAccounts.map((act) => (
                        <Account
                          key={act.id}
                          account={act}
                          isFormVisible={isFormVisible}
                          onOpenForm={handleOpenForm}
                          getAccounts={getAccounts}
                          selected={account === act.id}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            
            {renderForm()}
            {/* {auth.me?.role === 'SUPERADMIN' && (
              <>
                {isFormVisible || isFormEditVisible ? (
                  account ? (
                    <AccountFormEdit 
                      accountID={account}                       
                      onOpenForm={handleOpenForm}
                      getAccounts={getAccounts}
                    />
                  ) : (
                    <AccountForm 
                      accountID={account} 
                      onOpenForm={handleOpenForm}
                      getAccounts={getAccounts}
                    />
                  )
                ) : (
                  !isMobile && (
                    <div className="users__column placeholder-action">
                      <h4 className="Material-KitH4">Choose an action to start</h4>
                      <h5 className="Material-KitH5">
                        You can edit an existing account, or create a new one
                      </h5>
                      <div className="placeholder-project-list__image">
                        <img src="/images/dashboard/Decoration.svg" alt="Press + to start" />
                      </div>
                    </div>
                  )
                )}
              </>
            )} */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  auth: state.auth,
});

export default compose(requireAuth, connect(mapStateToProps, { getAccounts }))(Accounts);
