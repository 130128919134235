import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import './Modal.css';

import { formatedStatus } from '../../../constants/functions';
import { statuses } from '../../../constants/index';
const Modal = ({ fields, closeModal, onSubmit, defaultValue = {} }) => {
  const departments = useSelector((state) => state.project.project.departments);
  const initialValues = fields.reduce((acc, field) => {
    acc[field.name] = field.defaultValue;
    return acc;
  }, {});

  const validationSchema = Yup.object(
    fields.reduce((acc, field) => {
      if (field.validation) {
        acc[field.name] = field.validation;
      }
      return acc;
    }, {}),
  );

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.length ? validationSchema : undefined,
    onSubmit: (values) => {
      onSubmit(values);
      closeModal();
    },
  });
  return (
    <div
      className="modal-container"
      onClick={(e) => {
        if (e.target.className === 'modal-container') closeModal();
      }}
    >
      <div className="modal">
        <form onSubmit={formik.handleSubmit}>
          {fields.map((field) => (
            <div className="form-group" key={field.name}>
              <label htmlFor={field.name} className={field.hidden ? 'field-label-hidden' : 'field-label'}>{field.label}</label>
              {field.type === 'select' ? (
                <select
                  name={field.name}
                  onChange={formik.handleChange}
                  value={formik.values[field.name]}
                >
                  {departments.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              ) : field.type === 'button' ? (
                <div className={`status-options ${field.hidden ? 'hidden' : ''}`}>
                  {field.options.map((option, idx) => (
                    <button
                      key={idx}
                      type="button"
                      className={`status-option ${
                        formik.values[field.name]?.includes(option) ? 'selected' : 'not-selected'
                      }`}
                      onClick={() => {
                        // Toggle selection
                        const currentValues = formik.values[field.name] || [];
                        const updatedValues = currentValues.includes(option)
                          ? currentValues.filter((value) => value !== option) // Remove if already selected
                          // : [...currentValues, option]; // Add if not selected
                          : [option]; // Replace if not selected

                        formik.setFieldValue(field.name, updatedValues);
                      }}
                    >
                      {formatedStatus(option)}
                    </button>
                  ))}
                </div>
              ) : field.type === 'textarea' ? (
                <textarea
                  name={field.name}
                  rows="4"
                  onChange={formik.handleChange}
                  value={formik.values[field.name]}
                />
              ) : (
                <input
                  type={field.type || 'text'}
                  name={field.name}
                  onChange={formik.handleChange}
                  value={formik.values[field.name]}
                  {...(field.type === 'date')}
                />
              )}
              {formik.touched[field.name] && formik.errors[field.name] && (
                <div className="error">{formik.errors[field.name]}</div>
              )}
            </div>
          ))}
          <button type="submit" className="modal-btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
