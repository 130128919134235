import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import requireAuth from '../../hoc/requireAuth';
import { connect } from 'react-redux';
import Button from '../Buttons/Button';
import { archiveAccount } from '../../store/actions/accountActions';
import '../User/User.css';
const Account = ({ account, isFormVisible, onOpenForm, auth, archiveAccount, getAccounts, selected, currentOpenForm, onUnsetForm }) => {
  const avatarUrl = account?.avatar?.startsWith('http')
    ? account.avatar
    : `${process.env.REACT_APP_SERVER_BASE_URL}${account?.avatar}`;

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to archive this account?')) {
      archiveAccount(account.id);
      getAccounts();
    }
  };

  return (
    < div className={`list-users__account account-users ${selected ? ' selected' : ''}`}>
      <div className="account-users__info">
        <div className="account-users__image">
          {account.avatar ? <img src={
            account?.avatar?.startsWith('http')
              ? account.avatar
              : `${process.env.REACT_APP_SERVER_BASE_URL}${account?.avatar}` || '/images/default-avatar.png'} alt="avatar" />
            : <div className="account-initial">{account.name.charAt(0)}</div>
        }
        </div>
        <div className="account-users__info-text">
          <h5 className="Material-KitH6">
            {account.name}            
          </h5>
          <span className="Material-KitCaption">{account.description}</span>
        </div>
      </div>
      <div className="account-users__actions">
        <Button
          className={`button-view`}
          onClick={
            // () => onOpenForm(account.id)
            () => currentOpenForm === 'edit' ? onUnsetForm() : onOpenForm('edit', account.id)
          }
          size="small"
        >
          <div className="button-view__image button__image">
            <img src="/icons/edit.svg" alt="Edit" />
          </div>
        </Button>
        <Link to={`/account/${account.id}`}>
          
            <Button
              className={`button-view`}
              size="small"
            >
              <div className="button-view__image button__image">
                <img src="/icons/home.svg" alt="Projects" />
              </div>
            </Button>                           
        </Link>
        <Link to={`/users/account/${account.id}`}>
          
            <Button
              className={`button-view`}
              size="small"
            >
              <div className="button-view__image button__image">
                <img src="/icons/groups_2.svg" alt="Users" />
              </div>
            </Button>                           
        </Link>
        {auth.me?.role === 'SUPERADMIN' && (
          <Button className="button-delete" size="small" onClick={handleDelete}>
            <div className="button-delete__image button__image">
              <img src="/icons/archive.svg" alt="Archive" />
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(requireAuth, connect(mapStateToProps, { archiveAccount }))(Account);
