import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_PROJECTS_LOADING,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_PROJECT_LOADING,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAIL,
  ADD_PROJECT_LOADING,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  DELETE_PROJECT_LOADING,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  // ARCHIVE_PROJECT_LOADING,
  ARCHIVE_PROJECT_SUCCESS,
  // ARCHIVE_PROJECT_FAIL,
  UNARCHIVE_PROJECT_SUCCESS,
  EDIT_PROJECT_LOADING,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAIL,
  CLEAR_PROJECT_ERROR,
  GET_PROJECT_PODMASTERS_LOADING,
  GET_PROJECT_PODMASTERS_SUCCESS,
  GET_PROJECT_PODMASTERS_FAIL,
} from '../types';

export const getProjects = (accountId, archived) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECTS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const currentUser = getState().auth.me;
    var response = {};
    let url = `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects`;
    if (accountId) {
      url = `${url}/account/${accountId}`;
    }
    if (currentUser.role !== 'SUPERADMIN') {
      url = `${url}/user/${currentUser.id}`;
    }
    url = `${url}/archive/${archived}`;    
    response = await axios.get(
      url,
      options,
    );
    if (!archived) {
      dispatch({
        type: GET_PROJECTS_SUCCESS,
        payload: { projects: response.data.projects, error: null },
      });
    } else {
      dispatch({
        type: GET_PROJECTS_SUCCESS,
        payload: { projects: response.data.projects, error: null },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PROJECTS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getProject = (projectId, isEdit, history) => async (dispatch, getState) => {  
  dispatch({
    type: GET_PROJECT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/${projectId}`,
      options,
    );
    
    dispatch({
      type: GET_PROJECT_SUCCESS,
      payload: { project: response.data.project, error: null },
    });
    
    
  } catch (err) {
    if (err?.response?.status === 404) {
      history.push('/notfound');
    }
    dispatch({
      type: GET_PROJECT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getProjectUsers = (projectId, isEdit) => async (dispatch, getState) => {
    dispatch({
    type: GET_PROJECT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);    

    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/${projectId}`,
      options,
    );
    
    dispatch({
      type: GET_PROJECT_SUCCESS,
      payload: { project: response.data.project, error: null },
    });
    
  } catch (err) {
    dispatch({
      type: GET_PROJECT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
export const addProject = (formData) => async (dispatch, getState) => {
  dispatch({
    type: ADD_PROJECT_LOADING,
    payload: { me: { ...getState().auth.me } },
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log("FormData:", formData)
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects`,
      formData,
      options,
    );

    dispatch({
      type: ADD_PROJECT_SUCCESS,
      payload: { project: response.data.project, error: null },
    });
  } catch (err) {
    dispatch({
      type: ADD_PROJECT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteProject = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_PROJECT_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/${id}`,
      options,
    );

    dispatch({
      type: DELETE_PROJECT_SUCCESS,
      payload: { project: response.data.project, error: null },
    });
  } catch (err) {
    dispatch({
      type: DELETE_PROJECT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editProject = (id, formData) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_PROJECT_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log('FormData:', formData);
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/${id}`,
      formData,
      options,
    );

    if (formData.archived === true) {
      dispatch({
        type: ARCHIVE_PROJECT_SUCCESS,
        payload: { project: response.data.project, error: null },
      });
    } else if (formData.archived === false) {
      dispatch({
        type: UNARCHIVE_PROJECT_SUCCESS,
        payload: { project: response.data.project, error: null },
      });
    } else {
      dispatch({
        type: EDIT_PROJECT_SUCCESS,
        payload: { project: response.data.project, error: null },
      });
    }
  } catch (err) {
    dispatch({
      type: EDIT_PROJECT_FAIL,
      payload: { error: err?.response?.data.message || err.message, id },
    });
  }
};

export const getPodmasterForProject = (projectID, podMasterId) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_PODMASTERS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const responseProject = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/addpodmaster/${projectID}`,
      { id: podMasterId },
      options,
    );
    // const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/project/${responseProject.data.department.project.id}`, options);
    dispatch({
      type: GET_PROJECT_PODMASTERS_SUCCESS,
      // payload: { departmentProfile: response.data.user },
      payload: { project: responseProject.data.project },
    });
    // dispatch({
    //   type: GET_DEPARTMENTS_SUCCESS,
    //   // payload: { departmentProfile: response.data.user },
    //   payload: { departments: response.data.departments },
    // });
  } catch (err) {
    dispatch({
      type: GET_PROJECT_PODMASTERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deletePodmasterForProject = (projectId, podMasterId) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_PODMASTERS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const responseProject = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/removepodmaster/${projectId}`,
      { id: podMasterId },
      options,
    );

    dispatch({
      type: GET_PROJECT_PODMASTERS_SUCCESS,
      payload: { project: responseProject.data.project },
    });
  } catch (err) {
    dispatch({
      type: GET_PROJECT_PODMASTERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const addDepartmentForProject = (projectID, departmentID) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_PODMASTERS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log("Searching email:", formData.email);
    // const responseDepartment = await axios.get(
    //   `${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/${departmentID}`,
    //   options,
    // );
    const responseProject = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/adddepartment/${projectID}`,
      { id: departmentID },
      options,
    );
    // const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/project/${responseProject.data.department.project.id}`, options);
    dispatch({
      type: GET_PROJECT_PODMASTERS_SUCCESS,
      // payload: { departmentProfile: response.data.user },
      payload: { project: responseProject.data.project },
    });
    // dispatch({
    //   type: GET_DEPARTMENTS_SUCCESS,
    //   // payload: { departmentProfile: response.data.user },
    //   payload: { departments: response.data.departments },
    // });
  } catch (err) {
    dispatch({
      type: GET_PROJECT_PODMASTERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteDepartmentForProject = (projectId, departmentId) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_PODMASTERS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const responseProject = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/removedepartment/${projectId}`,
      { id: departmentId },
      options,
    );

    dispatch({
      type: GET_PROJECT_PODMASTERS_SUCCESS,
      payload: { project: responseProject.data.project },
    });
  } catch (err) {
    dispatch({
      type: GET_PROJECT_PODMASTERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const moveDepartment = (projectId, departmentId, direction) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_PODMASTERS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const responseProject = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/movedepartment/${projectId}`,
      { 
        id: departmentId,
        direction: direction
      },
      options,
    );

    dispatch({
      type: GET_PROJECT_PODMASTERS_SUCCESS,
      payload: { project: responseProject.data.project },
    });
  } catch (err) {
    dispatch({
      type: GET_PROJECT_PODMASTERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const clearProjectError = (id) => ({
  type: CLEAR_PROJECT_ERROR,
  payload: { id },
});
