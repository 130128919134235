import * as Yup from 'yup';

export const tagFormSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Tag Name" must be 2 characters at minimum')
    .max(30, 'Tag Name" must be 30 characters or less')
    .required('Required'),
  color: Yup.string()
    .required('Required'),  
});
