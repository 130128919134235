// import React, { useState, useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import { Link, withRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import {Button, Calendar, CalendarCell, CalendarGrid, Heading} from 'react-aria-components';
import {parseDate} from '@internationalized/date';

import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faPen, faTrash, faBarsStaggered, faTag, faPeopleGroup, faUserPlus, faCalendar } from '@fortawesome/free-solid-svg-icons';


import requireAuth from '../../hoc/requireAuth';

// import Loader from '../../components/Loader/Loader';

import {  deleteTask, editTask, clearTaskError } from '../../store/actions/taskActions';
// import { getProjectTasks } from '../../store/actions/taskActions';
import { taskFormSchema } from './validation';

import './styles.css';

// import {useDrag, useDrop, onDragEnd, DragPreview} from 'react-aria';
// import {useDrag, useLongPress, mergeProps } from 'react-aria';
import {useDrag} from 'react-aria';

const Task = ({ 
  task, 
  taskIsLoading, 
  taskError, 
  project, 
  department,   
  tags, 
  auth, 
  deleteTask, 
  editTask, 
  // clearTaskError, 
  hideApproveDecline,
  deconstructTask,
  deconstructValues,
  desconstructAudit
}) => {
  
  const [isEdit, setIsEdit] = useState(false);

  const [isView, setIsView] = useState(false);

  const [isConfirm, setIsConfirm] = useState(false);

  const [moved, setMoved] = useState(false);  

  const [isEditingAssignedTo, setIsEditingAssignedTo] = useState(false);

  const [isEditingDepartment, setIsEditingDepartment] = useState(false);

  const [isEditingTag, setIsEditingTag] = useState(false);

  const [isEditingDueDate, setIsEditingDueDate] = useState(false);

  
  const formik = useFormik({
    initialValues: {
      title: task.title,
      description: task.description,
      complete: task.complete,
      assignedTo: task.assignedTo.id ? task.assignedTo.id : '',
      department: task.department.id,
      tag: task.tag.id ? task.tag.id : '',
      project: task.project.id,
      approved: task.approved,
      delay: task.delay,
      dueDate: typeof task.dueDate === 'object' ? '' : moment(task.dueDate).format('YYYY-MM-DD'),
      audit: task.audit,
    },
    validationSchema: taskFormSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      
      submitTask(values, "updated");
           
      // resetForm();
      setSubmitting(false);            
      setIsEdit(false);      
    },
  });

  const submitTask = (values, action) => {
    let setDelay = task.delay;
    if ((typeof task.dueDate !== 'object') && (task.approved === true)) {
      if (moment(values.dueDate).format('YYYY-MM-DD') > moment(task.dueDate).format('YYYY-MM-DD') && task.delay < 3) {
        setDelay = setDelay+1;
      }
    }
    
    // Reset assignedTo if department is changed
    if (values.department !== task.department.id) {
      values.assignedTo = '';
    }

    // If dueDate is empty, reset delay to 0
    if ((values.dueDate === '') || (values.dueDate === null) || (typeof values.dueDate === 'object')) {
      setDelay = 0;
    }

    let audit = desconstructAudit(task.audit);
    if ((task.approved === true) || (task.audit.length === 1)) {        
      
      audit.push({
        user: auth.me.id,
        action: action,
        date: moment().toISOString(),
        old: deconstructTask(task),
        new: deconstructValues(values),
      });
    } else {
      let lastChange = audit.pop();
      lastChange.new = deconstructValues(values);
      audit.push(lastChange);
    }

    let complete = values.complete;
    if ((action === "completed") && ((values.dueDate === '') || (values.dueDate === null) || (typeof values.dueDate === 'object'))) {
      values.dueDate = moment().toISOString();
    }
    if ((action === "restored") || (action === "completed")) {
      complete = !values.complete;
    }
    

    editTask(task.id, { 
      title: values.title, 
      description: values.description, 
      complete: complete, 
      assignedTo: values.assignedTo === '' ? null : values.assignedTo, 
      department: values.department, 
      tag: values.tag === '' ? null : values.tag,
      project: task.project.id, // NOTE: this is not the formik value, it is the task's project id
      approved: false, // NOTE: this is not the formik value
      delay: setDelay, 
      dueDate: moment(values.dueDate).toISOString(),
      audit: audit,
    }, values.complete);
    
    document.documentElement.style.removeProperty('overflow');
  }

  const completeTask = (action) => {
    submitTask(formik.values, action);
  }

  const approveTask = (id) => {
    // console.log('approveTask', id);
    let audit = desconstructAudit(task.audit);      
    audit.push({
      user: auth.me.id,
      action: 'approved',
      date: moment().toISOString(),
      old: {},
      new: {},
    });

    editTask(id, { 
      complete: task.complete,
      approved: true,
      delay: task.delay,
      dueDate: typeof task.dueDate === 'object' ? '' : task.dueDate,      
      assignedTo: task.assignedTo.id,
      department: task.department.id,
      tag: task.tag.id,
      project: task.project.id, 
      title: task.title,
      description: task.description,
      audit: audit,
    });
    // getProjectTasks(task.project.id);
  }
  
  const removeTask = (id, action) => {
    
    if (action === "delete") {
      deleteTask(id);
    } else if (action === "decline") {
      
      if (task.audit.length > 1) {
        let audit = desconstructAudit(task.audit);
        let lastAudit = audit.pop();
        lastAudit.old.audit = audit;
        editTask(id, lastAudit.old);
      } else {
        deleteTask(id);
      }
    }
    setIsConfirm(false);
    document.documentElement.style.removeProperty('overflow');
  }

  const openEditTaskForm = () => {
    setIsEdit(true);    
    document.documentElement.style.setProperty('overflow', 'hidden');
    
  }

  const closeEditTaskForm = () => {
    setIsEdit(false);    
    document.documentElement.style.removeProperty('overflow');    
  }

  const openViewTaskForm = () => {
    setIsView(true);
    document.documentElement.style.setProperty('overflow', 'hidden');
  }

  const closeViewTaskForm = () => {
    setIsView(false);
    document.documentElement.style.removeProperty('overflow');
  }

  const openConfirmDeleteTaskForm = () => {
    setIsConfirm(true);    
  }

  const closeConfirmDeleteTaskForm = () => {
    setIsConfirm(false);
  }

  const canEditTask = (task) => {
    return auth.me.role === 'SUPERADMIN' || task.department.users.some(user => user.toString() === auth.me.id.toString()) || project.podMasters.some(pm => pm.id === auth.me.id);
  }

  const canAdminTask = () => {
    return auth.me.role === 'SUPERADMIN' || project.podMasters.some(pm => pm.id === auth.me.id);
  }

  const getAssignedUser = (assignedTo) => {
    if (assignedTo === '') {      
      return 'NONE';      
    } else {      
      return department.users.find((user) => user.id === assignedTo)?.name;
    }        
  }

  const isUserAssigned = (assignedTo) => {
    if (assignedTo === '') {
      return false;
    } else {
      return true;
    }
  }

  const setAssignedTo = (assignedTo) => {
    formik.setFieldValue('assignedTo', assignedTo);
    setIsEditingAssignedTo(false);
  }

  const setDepartment = (departmentId) => {
    formik.setFieldValue('department', departmentId);
    setIsEditingDepartment(false);
  }

  const getTag = (tagId) => {
    if (tagId === '') {      
      return 'NONE';      
    } else {      
      return tags.find((tag) => tag.id === tagId)?.name;
    }        
  }

  const isTag = (tagId) => {
    if (tagId === '') {
      return false;
    } else {
      return true;
    }
  }

  const setTag = (tagId) => {
    formik.setFieldValue('tag', tagId);
    setIsEditingTag(false);
  }

  const setDueDate = (dueDate) => {    
    formik.setFieldValue('dueDate', moment(dueDate.toDate()).format('YYYY-MM-DD'));
    setIsEditingDueDate(false);
  }

  const dateSelection = () => {
    if (formik.values.dueDate !== '') {
      return parseDate(formik.values.dueDate);
    } else {
      return null;
    }
  }

  // const [isLongPressActive, setIsLongPressActive] = useState(false);

  // 1) Long press to activate dragging
  // const { longPressProps } = useLongPress({
  //   onLongPressStart: () => {
  //     console.log('onLongPressStart');
  //     setIsLongPressActive(true);
  //   },
  //   onLongPressEnd: () => {
  //     // Optionally reset after release, if that’s the desired behavior
  //     console.log('onLongPressEnd');
  //     setIsLongPressActive(false);
  //   },
  //   // If you want a specific delay, you can customize it:
  //   threshold: 2000, // 500ms long press threshold
  // });

  const { dragProps, isDragging } = useDrag({  
    getItems() {
      // console.log('useDrag task', task);
      return [{
        'text/plain': task.id,
        'task': task
      }];
    },
    onDragStart(e) {
      // console.log('onDragStart', e);      
    },
    isDisabled: false,
    onDragEnd(e) {      
      if (e.dropOperation === 'move') {
        setMoved(true);        
      }
    }
  });

  

  // if (moved) {
  //   // find a better way to handle this for the scenario where the task is moved to the same location
  //   setMoved(false);
  //   // return null; 
  // }

  useEffect(() => {
    if (moved) {
      setMoved(false);
    }
  }, [task])

  return (
    <div className={taskIsLoading ? 'task loader' : 'task'}>
      
      {isEdit && (
        <>
          <div className="task-form-background-overlay" onClick={() => {
            if (isConfirm) {
              closeConfirmDeleteTaskForm();
            }
            closeEditTaskForm();              
          }}></div>
          <div className='edit-task-form-container'>
            <div className="task-form">
              {!isConfirm ? (
                <>
                  <div className="task-form-header">
                    <h2 className='task-form-title'>Edit Task</h2>
                    {canAdminTask() ? 
                      <div className='task-form-buttons-admin'>                      
                        <div className="task-form-buttons-admin-archive" onClick={() => completeTask("completed")}><FontAwesomeIcon icon={faCheck} className='task-form-buttons-archive-icon'/></div>
                        <div className="task-form-buttons-admin-delete" onClick={() => openConfirmDeleteTaskForm()}><FontAwesomeIcon icon={faTrash} className='task-form-buttons-delete-icon'/></div>                          
                        <div className="task-form-buttons-x-cancel" onClick={() => closeEditTaskForm()}><FontAwesomeIcon icon={faXmark} /></div>
                      </div> :
                      <div className='task-form-buttons-admin'>                                                                          
                        <div className="task-form-buttons-x-cancel" onClick={() => closeEditTaskForm()}><FontAwesomeIcon icon={faXmark} /></div>
                      </div>
                    }                      
                  </div>                    
                  
                  <div className='task-form-audit'>Created by {task.user.name} on {moment(task.createdAt).format('MMM DD, YYYY')}</div>
                  <form onSubmit={formik.handleSubmit}>        
                    
                    {/* Task Title */}
                    <input
                      name="title"          
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      placeholder="What is the task?"
                      disabled={false}
                    />            
                    {(formik.touched.title && formik.errors.title) ? (
                      <p className="error">{formik.errors.title}</p>
                    ) : null}
                    
                    {/* Task Description */}
                    <textarea
                      name="description"
                      rows="7"
                      cols="30"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      disabled={false}
                      placeholder="Optional description"
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <p className="error">{formik.errors.description}</p>
                    ) : null}

                    {/* Task Department */}
                    <div className='task-form-department-container'>
                      <FontAwesomeIcon icon={faPeopleGroup} />                        
                      <div className='task-form-department-container-inner'>                          
                        <div className='task-form-department task-form-department-selected' style={{backgroundColor: project.departments?.find(dept => dept.id === formik.values.department)?.color}}>{project.departments?.find(dept => dept.id === formik.values.department)?.name}</div>                           
                        
                        <div className='task-form-department-list' style={{visibility: isEditingDepartment ? 'unset' : 'hidden'}}>
                          {project.departments?.map((department) => (
                            <div 
                              key={department.id} 
                              className={`task-form-department ${formik.values.department === department.id ? 'task-form-department-selected' : ''}`} 
                              onClick={() => setDepartment(department.id)}
                            >
                              {department.name}
                            </div>
                          ))}
                        </div>
                        
                        <FontAwesomeIcon icon={faPen} className={isEditingDepartment ? 'editing' : ''} onClick={() => {
                          setIsEditingDepartment(!isEditingDepartment);
                          setIsEditingAssignedTo(false);
                          setIsEditingDueDate(false);
                          setIsEditingTag(false);
                        }} />
                      </div>
                    </div>

                    {/* Task Assigned To */}
                    <div className='task-form-assigned-to-container'>
                      <FontAwesomeIcon icon={faUserPlus} />
                      <div className='task-form-assigned-to-container-inner'>
                        {isUserAssigned(formik.values.assignedTo) ? 
                          <div className='task-form-assigned-to task-form-assigned-to-selected'>{getAssignedUser(formik.values.assignedTo)} <FontAwesomeIcon icon={faXmark} onClick={() => formik.setFieldValue('assignedTo', '')} /></div> 
                          : 
                          <div className='task-form-assigned-to'>{getAssignedUser(formik.values.assignedTo)}</div>
                        }
                        
                        <div className='task-form-assigned-to-list' style={{visibility: isEditingAssignedTo ? 'unset' : 'hidden'}}>
                          {department.users.length ? 
                            department.users.map((user) => (
                              <div 
                                key={user.id} 
                                className={`task-form-assigned-to ${formik.values.assignedTo === user.id ? 'task-form-assigned-to-selected' : ''}`} 
                                onClick={() => setAssignedTo(user.id)}
                              >
                                {user.name}
                              </div>
                            )) : 
                            <div className='task-form-assigned-to-none'>No members assigned to this department</div>
                          }
                        </div>
                        
                        <FontAwesomeIcon icon={faPen} className={isEditingAssignedTo ? 'editing' : ''} onClick={() => {
                          setIsEditingAssignedTo(!isEditingAssignedTo);
                          setIsEditingDepartment(false);
                          setIsEditingDueDate(false);
                          setIsEditingTag(false);
                        }} />
                      </div>
                    </div>

                    {/* Task Tag */}
                    <div className='task-form-tag-container'>
                      <FontAwesomeIcon icon={faTag} />
                      <div className='task-form-tag-container-inner'>
                        {isTag(formik.values.tag) ? 
                          <div className='task-form-tag task-form-tag-selected' style={{backgroundColor: tags.find(tag => tag.id === formik.values.tag)?.color}}>{getTag(formik.values.tag)} <FontAwesomeIcon icon={faXmark} onClick={() => formik.setFieldValue('tag', '')} /></div> 
                          : 
                          <div className='task-form-tag'>{getTag(formik.values.tag)}</div>
                        }
                        
                        <div className='task-form-tag-list' style={{visibility: isEditingTag ? 'unset' : 'hidden'}}>
                          {tags.length ? 
                            tags.map((tag) => (
                              <div 
                                key={tag.id} 
                                className={`task-form-tag ${formik.values.tag === tag.id ? 'task-form-tag-selected' : ''}`} 
                                onClick={() => setTag(tag.id)}
                              >
                                {tag.name}
                              </div>
                            )) : 
                            <div className='task-form-tag-none'>No tags exist</div>
                          }
                        </div>
                        
                        <FontAwesomeIcon icon={faPen} className={isEditingTag ? 'editing' : ''} onClick={() => {
                          setIsEditingTag(!isEditingTag);
                          setIsEditingAssignedTo(false);
                          setIsEditingDepartment(false);
                          setIsEditingDueDate(false);
                        }} />
                      </div>
                    </div>                       

                    {/* Task Due Date */}
                    <div className='task-form-due-date-container'>              
                      <FontAwesomeIcon icon={faCalendar} />
                      <div className='task-form-due-date-container-inner'>
                        
                        {formik.values.dueDate ? 
                          <div className='task-form-due-date task-form-due-date-selected'>{formik.values.dueDate} <FontAwesomeIcon icon={faXmark} onClick={() => formik.setFieldValue('dueDate', '')} /></div>
                          :
                          <div className='task-form-due-date'>{'NONE'}</div>                                    
                        }
                        <div className='task-form-due-date-list' style={{visibility: isEditingDueDate ? 'unset' : 'hidden'}}>
                          <Calendar value={dateSelection()} onChange={setDueDate} aria-label='Date Picker'>
                            <header>
                              <Button slot="previous">◀</Button>
                              <Heading />
                              <Button slot="next">▶</Button>
                            </header>
                            <CalendarGrid>
                              {(date) => <CalendarCell date={date} />}                            
                            </CalendarGrid>
                          </Calendar>
                        </div>
                        
                        <FontAwesomeIcon icon={faPen} className={isEditingDueDate ? 'editing' : ''} onClick={() => {
                          setIsEditingDueDate(!isEditingDueDate);
                          setIsEditingAssignedTo(false);
                          setIsEditingDepartment(false);
                          setIsEditingTag(false);
                        }} />
                        
                      </div>
                    </div>
                                          
                    <div className='task-form-buttons'>
                      <button type="submit" className="task-form-buttons-update" value="Update" disabled={false}>UPDATE</button>
                      <button type="button" className="task-form-buttons-cancel" onClick={() => closeEditTaskForm()}>CANCEL</button>
                    </div>
                    
                    
                  </form>
                </>
              ) : 
              (
                <>
                  <p className="task-form-buttons-admin-confirm-text">Are you sure you want to delete <b>{task.title}</b> task?</p>
                  <div className="task-form-buttons-admin-confirm">                      
                    <div className="task-form-buttons-admin-confirm-delete" onClick={() => removeTask(task.id, "delete")}> CONFIRM</div>
                    <div className="task-form-buttons-admin-confirm-cancel" onClick={() => closeConfirmDeleteTaskForm()}> CANCEL</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {isView && (
        <>
          <div className="task-form-background-overlay" onClick={() => closeViewTaskForm()}></div>
          <div className='edit-task-form-container'>
            <div className="task-form">
              {!isConfirm ? (
                <>
                  <div className="task-form-header">
                    <h2 className='task-form-title'>View Task</h2>
                    <div className="task-form-buttons-x-cancel" onClick={() => closeViewTaskForm()}><FontAwesomeIcon icon={faXmark} /></div>
                  </div>
                  <div className='task-form-audit'>Created by {task.user.name} on {moment(task.createdAt).format('MMM DD, YYYY')}</div>
                  
                  <div className='task-form-view-only-container'>

                    <div className='task-form-view-only-title'>{task.title}</div>
                    
                    <div className='task-form-view-only-description'>{task.description}</div>
                    
                    <div className='task-form-department-container'>
                      <FontAwesomeIcon icon={faPeopleGroup} />
                      <div className='task-form-department' style={{backgroundColor: department.color}}>{department.name}</div>
                    </div>
                    
                    <div className='task-form-assigned-to-container'>
                      <FontAwesomeIcon icon={faUserPlus} />
                      <div className='task-form-assigned-to-container-inner'>
                        {task.assignedTo.name ? 
                          <div className='task-form-assigned-to task-form-assigned-to-selected' >{task.assignedTo.name}</div> 
                          : 
                          <div className='task-form-assigned-to'>NONE</div>
                        }                          
                      </div>
                    </div>

                    <div className='task-form-tag-container'>
                      <FontAwesomeIcon icon={faTag} />
                      <div className='task-form-tag-container-inner'>
                        {task.tag.name ? 
                          <div className='task-form-tag task-form-tag-selected'  style={{backgroundColor: task.tag.color}}>{task.tag.name}</div> 
                          : 
                          <div className='task-form-tag'>NONE</div>
                        }                          
                      </div>
                    </div>                       

                    

                    <div className='task-form-due-date-container'>              
                      <FontAwesomeIcon icon={faCalendar} />
                      <div className='task-form-due-date-container-inner'>                          
                        <div className='task-form-due-date task-form-due-date-selected'>{moment(task.dueDate).format('MMM DD, YYYY')}</div>
                      </div>
                    </div>

                    
                    <div className='task-form-buttons'>
                      {(task.complete && canEditTask(task)) && <button type="button" className="task-form-buttons-update" value="Update" disabled={false} onClick={() => openConfirmDeleteTaskForm()}>RESTORE</button>}
                      <button type="button" className="task-form-buttons-cancel" onClick={() => closeViewTaskForm()}>CLOSE</button>
                    </div>
                    
                    
                  </div>
                </>
              ) : 
              (
                <>
                  <p className="task-form-buttons-admin-confirm-text">Are you sure you want to restore <b>{task.title}</b> task?</p>
                  <div className="task-form-buttons-admin-confirm">                      
                    <div className="task-form-buttons-admin-confirm-delete" onClick={() => completeTask("restored")}> CONFIRM</div>
                    <div className="task-form-buttons-admin-confirm-cancel" onClick={() => closeConfirmDeleteTaskForm()}> CANCEL</div>
                  </div>
                </>
                
              )}
            </div>
          </div>
        </>
      )}
      

      {!moved && (<div                
        // {...mergeProps(longPressProps, dragProps)}
        // {...(!task.complete ? mergeProps(longPressProps, dragProps) : {})}
        {...(!task.complete && dragProps)}
        role="button"
        {...(!task.complete && {tabIndex: 0})}
        className={`task-box ${!task.complete ? 'draggable' : ''} ${isDragging ? 'dragging' : ''}`} 
        style={{backgroundColor: project.taskColoredBy === 0 ? department.color : project.taskColoredBy === 1 ? task.tag.color || 'white' : 'white' }}
        // onClick={() => console.log('pointer down - onclick')}
        // onPointerDown={(e) => console.log('pointer down - onpointerdown', e.pointerType)}
        // onPointerMove={() => console.log('pointer move')}
        // onPointerUp={() => console.log('pointer up')}
        // onContextMenu={(e) => e.preventDefault()}
        // onTouchStart={(e) => console.log('touch start', e.touches)}
        // onTouchMove={(e) => console.log('touch move', e.touches)}
        // onTouchEnd={(e) => console.log('touch end', e.touches)}
        
        // onPointerDown={(e) => e.preventDefault()}
      >
        
        <div className="task-info">              

          {/* Task Info Top */}
          <div className='task-info-top'>


            <div className='task-info-top-left'>                  
              
              {task.description !== '' ? 
                <>
                  { canEditTask(task) && !task.complete ? (
                    <div className='task-description'>
                      <FontAwesomeIcon icon={faBarsStaggered} className='task-description-icon' onClick={() => openEditTaskForm()}/>                  
                    </div>
                  ) : (
                    // <div className='task-description-noedit'>
                    <div className='task-description'>
                      <FontAwesomeIcon icon={faBarsStaggered} className='task-description-icon' onClick={() => openViewTaskForm()}/>                  
                    </div>
                  )}                  
                </>
              : null}
              

              { canEditTask(task) && !task.complete ? ( 
                <div className="task-edit"><FontAwesomeIcon icon={faPen} className='task-edit-icon' onClick={() => openEditTaskForm()}/></div>
              ) : null }

            </div>


            <div className='task-info-top-right'>
              {task.delay === 1 ? 
                <div className='task-delay'>
                  {/* <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/> */}
                  <div className='task-delay-bar'></div>
                </div>
              : null}
              {task.delay === 2 ? 
                <div className='task-delay'>
                  {/* <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/>
                  <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/> */}
                  <div className='task-delay-bar'></div>
                  <div className='task-delay-bar'></div>
                </div>
              : null}
              {task.delay === 3 ? 
                <div className='task-delay'>
                  {/* <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/>
                  <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/>
                  <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/> */}
                  <div className='task-delay-bar'></div>
                  <div className='task-delay-bar'></div>
                  <div className='task-delay-bar'></div>
                </div>
              : null}
            </div>
            

          </div>
          
          {/* Task Title */}
          { canEditTask(task) && !task.complete ? (
            <div className='task-header-row' onClick={() => openEditTaskForm()}>
              <div className='task-title-large' style={{ fontSize: project.fontSizeLarge, lineHeight: project.fontSizeLarge, fontFamily: project.fontStyle, fontWeight: project.fontWeight }}>{task.title}</div>
              <div className='task-title-small' style={{ fontSize: project.fontSizeSmall, lineHeight: project.fontSizeSmall, fontFamily: project.fontStyle, fontWeight: project.fontWeight }}>{task.title}</div>                  
            </div>
          ) : (
            // <div className='task-header-row-noedit' onClick={() => openViewTaskForm()}>
            <div className='task-header-row' onClick={() => openViewTaskForm()}>
              <div className='task-title-large' style={{ fontSize: project.fontSizeLarge, lineHeight: project.fontSizeLarge, fontFamily: project.fontStyle, fontWeight: project.fontWeight }}>{task.title}</div>
              <div className='task-title-small' style={{ fontSize: project.fontSizeSmall, lineHeight: project.fontSizeSmall, fontFamily: project.fontStyle, fontWeight: project.fontWeight }}>{task.title}</div>
            </div>
          )}

          <div className='task-info-bottom'>
            {/* Due Date */}
            {((task.dueDate !== null && typeof task.dueDate !== 'object' && moment(task.dueDate) < moment().add(-1, 'days')) || (task.delay === 3)) && !task.complete ? 
              <div className='task-due-date'>{moment(task.dueDate).format('MMM DD, YYYY')}</div> 
            : null}
            
            {/* Assigned To */}
            {task.assignedTo.name &&
              <>
                {/* <div className='task-assignedTo'><FontAwesomeIcon icon={faUser} className='task-assignedTo-icon'/>{task.assignedTo.name}</div>*/}
                <div className='task-assignedTo'><img src={task.assignedTo.avatar.startsWith('http') ? task.assignedTo.avatar : process.env.REACT_APP_SERVER_BASE_URL+task.assignedTo.avatar} alt={task.assignedTo.name} className='task-assignedTo-avatar'/></div>
              </>
            }
          </div>
          

        </div>

        <div className="task-actions">

          
          {canAdminTask() && !task.approved && !hideApproveDecline && !task.complete ? 
            <div className="task-actions-approval">
              <div className='task-actions-approval-buttons'>
                <button className='task-approve-button' onClick={() => approveTask(task.id)}><FontAwesomeIcon icon={faCheck} className='task-approve-button-icon' /></button>
                <button className='task-decline-button' onClick={() => removeTask(task.id, "decline")}><FontAwesomeIcon icon={faXmark} className='task-decline-button-icon' /></button>
              </div>
              <div className='task-actions-approval-last-audit'><span className='task-actions-approval-last-audit-action'>{task.audit[task.audit.length - 1].action}</span> on <span className='task-actions-approval-last-audit-date'>{moment(task.audit[task.audit.length - 1].date).format('MMM DD')}</span> by <span className='task-actions-approval-last-audit-user'>{task.audit[task.audit.length - 1].user.name}</span></div>
              {/* <div className='task-actions-approval-last-audit'>{task.audit[task.audit.length - 1].action} on {moment(task.audit[task.audit.length - 1].date).format('MMM DD, YYYY')}</div> */}
            </div>
          : null}
          
          {(task.dueDate !== null && typeof task.dueDate !== 'object' && moment(task.dueDate) < moment().add(-1, 'days')) && canAdminTask() && !task.complete ? 
            <div className="task-actions-complete">
              <button className='task-done-button' onClick={() => completeTask("completed")}><FontAwesomeIcon icon={faCheck} className='task-done-button-icon' /></button>
            </div>
          : null}
          
        </div>
        
      </div>)}        
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  // task: state.task,
});

export default compose(requireAuth, withRouter, connect(mapStateToProps, { deleteTask, editTask, clearTaskError }))(Task);
