// import React, { useEffect } from 'react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import {Button, Calendar, CalendarCell, CalendarGrid, DateInput, DatePicker, DateSegment, Dialog, Group, Heading, Label, Popover} from 'react-aria-components';
import {parseDate} from '@internationalized/date';

import { addTask } from '../../store/actions/taskActions';
import { taskFormSchema } from './validation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faTag, faPeopleGroup, faUserPlus, faCalendar, faPen } from '@fortawesome/free-solid-svg-icons';

import './styles.css';

const TaskForm = ({ 
  auth, 
  addTask, 
  projectID, 
  department, 
  tags,
  task: { 
    tasks, 
    // isLoading, 
    // error 
  }, 
  // isTaskFormVisible, 
  toggleTaskFormVisibility}) => {  

  let isSubmiting = tasks.some((t) => t.id === 0);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      complete: false,
      assignedTo: '',
      department: department.id,
      tag: '',
      project: projectID,
      approved: false,
      delay: 0,
      dueDate: moment().format('YYYY-MM-DD'),
      audit: [],
    },
    validationSchema: taskFormSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      
      addTask({ 
        title: values.title, 
        description: values.description, 
        complete: values.complete, 
        assignedTo: values.assignedTo === '' ? null : values.assignedTo, 
        department: values.department, 
        tag: values.tag === '' ? null : values.tag,
        project: values.project, 
        approved: values.approved, 
        delay: values.delay, 
        dueDate: moment(values.dueDate).toISOString(),
        audit: [
          {
            user: auth.me.id,
            action: 'created',
            date: moment().toISOString(), //.split('T')[0],
            old: {},
            new: {},
          }
        ]
      });      
      resetForm();
      setSubmitting(false);      
      
      toggleTaskFormVisibility(department.id);
    },
  });

  const [isEditingAssignedTo, setIsEditingAssignedTo] = useState(false); 
  const [isEditingDueDate, setIsEditingDueDate] = useState(false);
  const [isEditingTag, setIsEditingTag] = useState(false);

  const getAssignedUser = (assignedTo) => {
    if (assignedTo === '') {      
      return 'NONE';      
    } else {      
      return department.users.find((user) => user.id === assignedTo)?.name;
    }        
  }

  const isUserAssigned = (assignedTo) => {
    if (assignedTo === '') {
      return false;
    } else {
      return true;
    }
  }

  const setAssignedTo = (assignedTo) => {
    formik.setFieldValue('assignedTo', assignedTo);
    setIsEditingAssignedTo(false);
  }

  const getTag = (tagId) => {
    if (tagId === '') {      
      return 'NONE';      
    } else {      
      return tags.find((tag) => tag.id === tagId)?.name;
    }        
  }

  const isTag = (tagId) => {
    if (tagId === '') {
      return false;
    } else {
      return true;
    }
  }

  const setTag = (tagId) => {
    formik.setFieldValue('tag', tagId);
    setIsEditingTag(false);
  }

  const setDueDate = (dueDate) => {    
    formik.setFieldValue('dueDate', moment(dueDate.toDate()).format('YYYY-MM-DD'));
    setIsEditingDueDate(false);
  }

  const dateSelection = () => {
    if (formik.values.dueDate !== '') {
      return parseDate(formik.values.dueDate);
    } else {
      return null;
    }
  }

    

  return (
    <>
      <div className="task-form-background-overlay" onClick={() => toggleTaskFormVisibility(department.id)}></div>
      <div className='new-task-form-container'>
        <div className="task-form">

          <div className="task-form-header">
            <h2 className='task-form-title'>Create New Task</h2>
            <div className="task-form-buttons-x-cancel" onClick={() => toggleTaskFormVisibility(department.id)}><FontAwesomeIcon icon={faXmark} /></div>
          </div>
          
          <form onSubmit={formik.handleSubmit}>        
            
            {/* Task Title */}
            <input
              name="title"          
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              disabled={isSubmiting}
              placeholder="What is the task?"
            />            
            {(formik.touched.title && formik.errors.title) ? (
              <div className="error">{formik.errors.title}</div>
            ) : null}

            {/* Task Description */}
            <textarea
              name="description"
              rows="7"
              cols="30"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              disabled={isSubmiting}
              placeholder="Optional description"
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="error">{formik.errors.description}</div>
            ) : null}

            {/* Task Department */}
            <div className='task-form-department-container'>
              <FontAwesomeIcon icon={faPeopleGroup} />
              <div className='task-form-department' style={{backgroundColor: department.color}}>{department.name}</div>
            </div>

            {/* Task Assigned To */}
            <div className='task-form-assigned-to-container'>
              <FontAwesomeIcon icon={faUserPlus} />
              <div className='task-form-assigned-to-container-inner'>
                {isUserAssigned(formik.values.assignedTo) ? 
                  <div className='task-form-assigned-to task-form-assigned-to-selected'>{getAssignedUser(formik.values.assignedTo)} <FontAwesomeIcon icon={faXmark} onClick={() => formik.setFieldValue('assignedTo', '')} /></div> 
                  : 
                  <div className='task-form-assigned-to'>{getAssignedUser(formik.values.assignedTo)}</div>
                }
                
                <div className='task-form-assigned-to-list' style={{visibility: isEditingAssignedTo ? 'unset' : 'hidden'}}>
                  {department.users.length ? 
                    department.users.map((user) => (
                      <div 
                        key={user.id} 
                        className={`task-form-assigned-to ${formik.values.assignedTo === user.id ? 'task-form-assigned-to-selected' : ''}`} 
                        onClick={() => setAssignedTo(user.id)}
                      >
                        {user.name}
                      </div>
                    )) : 
                    <div className='task-form-assigned-to-none'>No members assigned to this department</div>
                  }
                </div>
                
                <FontAwesomeIcon icon={faPen} className={isEditingAssignedTo ? 'editing' : ''} onClick={() => {
                  setIsEditingAssignedTo(!isEditingAssignedTo); 
                  setIsEditingDueDate(false);
                  setIsEditingTag(false);
                }} />
              </div>
            </div>

            {/* Task Tag */}
            <div className='task-form-tag-container'>
              <FontAwesomeIcon icon={faTag} />
              <div className='task-form-tag-container-inner'>
                {isTag(formik.values.tag) ? 
                  <div className='task-form-tag task-form-tag-selected' style={{backgroundColor: tags.find(tag => tag.id === formik.values.tag)?.color}}>{getTag(formik.values.tag)} <FontAwesomeIcon icon={faXmark} onClick={() => formik.setFieldValue('tag', '')} /></div> 
                  : 
                  <div className='task-form-tag'>{getTag(formik.values.tag)}</div>
                }
                
                <div className='task-form-tag-list' style={{visibility: isEditingTag ? 'unset' : 'hidden'}}>
                  {tags.length ? 
                    tags.map((tag) => (
                      <div 
                        key={tag.id} 
                        className={`task-form-tag ${formik.values.tag === tag.id ? 'task-form-tag-selected' : ''}`} 
                        onClick={() => setTag(tag.id)}
                      >
                        {tag.name}
                      </div>
                    )) : 
                    <div className='task-form-tag-none'>No tags exist</div>
                  }
                </div>
                
                <FontAwesomeIcon icon={faPen} className={isEditingTag ? 'editing' : ''} onClick={() => {
                  setIsEditingTag(!isEditingTag);
                  setIsEditingAssignedTo(false);                  
                  setIsEditingDueDate(false);
                }} />
              </div>
            </div>  

            {/* Task Due Date */}
            <div className='task-form-due-date-container'>              
              <FontAwesomeIcon icon={faCalendar} />
              <div className='task-form-due-date-container-inner'>
                
                {formik.values.dueDate ? 
                  <div className='task-form-due-date task-form-due-date-selected'>{formik.values.dueDate} <FontAwesomeIcon icon={faXmark} onClick={() => formik.setFieldValue('dueDate', '')} /></div>
                  :
                  <div className='task-form-due-date'>{'NONE'}</div>                                    
                }
                
                <div className='task-form-due-date-list' style={{visibility: isEditingDueDate ? 'unset' : 'hidden'}}>
                  <Calendar value={dateSelection()} onChange={setDueDate} aria-label='Date Picker'>
                    <header>
                      <Button slot="previous">◀</Button>
                      <Heading />
                      <Button slot="next">▶</Button>
                    </header>
                    <CalendarGrid>
                      {(date) => <CalendarCell date={date} />}                            
                    </CalendarGrid>
                  </Calendar>
                </div>
                
                <FontAwesomeIcon icon={faPen} className={isEditingDueDate ? 'editing' : ''} onClick={() => {
                  setIsEditingDueDate(!isEditingDueDate);
                  setIsEditingAssignedTo(false);
                  setIsEditingTag(false);
                }} />
                
              </div>
            </div>
            
            <div className='task-form-buttons'>
              <button type="submit" className="task-form-buttons-create" value="Create" disabled={isSubmiting} >CREATE</button>              
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  task: state.task,
  auth: state.auth,
});

export default connect(mapStateToProps, { addTask })(TaskForm);
