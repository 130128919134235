import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import requireAuth from '../../hoc/requireAuth';
import Task from '../../components/Task/Task';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const ManyTasks = ({
  tasks,
  department,
  project,
  expandTasks,
  setExpandTasks,
  colType,
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(expandTasks?.[department.id+'-'+colType] || false);
    // console.log("ExpandTasks", expandTasks);
  }, [expandTasks]);
  
  const toggleExpandTasks = () => {
    if (expanded) {
      setExpandTasks({});      
    } else {
      setExpandTasks({[department.id+'-'+colType]: true});      
    }    
  }  
  
  return (
    <div className={`task many-tasks ${expanded ? 'expand' : ''}`}>
      <div className={`stack-task-box stack-bottom`} style={{backgroundColor: project.taskColoredBy === 0 ? department.color : 'lightgray'}}>
        
      </div>
      <div className={`stack-task-box stack-middle`} style={{backgroundColor: project.taskColoredBy === 0 ? department.color : 'lightgray'}}>
        
      </div>
      <div className={`stack-task-box stack-top`} style={{backgroundColor: project.taskColoredBy === 0 ? department.color : 'lightgray'}}>
        <div className='stack-task-box-text' onClick={toggleExpandTasks}>            
          {expanded ? <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} /> : <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />}            
          {tasks.length} tasks
        </div>
      </div>        
    </div>
  );
 
};

const mapStateToProps = (state) => ({
  auth: state.auth,  
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, null),
)(ManyTasks);
