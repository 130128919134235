import React, { useMemo, useState, useEffect } from 'react';
import MetricWeekly from '../Metrica/MetricWeekly';
import SponsorReport from './SponsorReport';
import TeamPulseCheck from './TeamPulseCheck';
import WeeklyPriorities from './WeeklyPriorities';
import { teamPulseRatings } from '../../../constants/index';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '../DailyWeeklyModals/Modal';
import { normalizeWeeklyDataWithMaster } from '../../../constants/functions';
import { selectDate, selectYear, selectMonth, selectDaysInMonth } from '../../../constants/functions';
import { updateHuddleBoard } from '../../../store/actions/huddleBoardAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import './styles.css';

// Function to format date to DD-MM-YY
const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
};



const WeeklyLayout = ({ updateHuddleBoard, title, huddleBoard, labels }) => {
  
  const [selectedDate, setSelectedDate] = useState(
    new Date(moment().startOf('isoWeek').toISOString()),
  );

  const [isGlobalEdit, setIsGlobalEdit] = useState(true);

  const { firstDayFormatted, lastDayFormatted, computedLabels } = useMemo(() => {
    // const currentDate = new Date();
    const currentDate = selectedDate;
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const firstDayFormatted = formatDate(firstDayOfMonth);
    const lastDayFormatted = formatDate(lastDayOfMonth);
    const computedLabels = labels.length === 2 ? [...labels, 'Unmarked'] : [...labels];
    return { firstDayFormatted, lastDayFormatted, computedLabels };
  }, [labels, selectedDate]);

  const dataRows = useMemo(() => {
    if (title === 'Team Pulse Check') {
      // return normalizeWeeklyDataWithMaster(huddleBoard?.data, selectedDate)[0].years[selectYear(selectedDate)][selectMonth(selectedDate)][moment(selectedDate).date()-1];
      let currentWeek = normalizeWeeklyDataWithMaster(huddleBoard?.data)[0].weeks.filter((week) => week.week === moment(selectedDate).format('YYYY-MM-DD'));
      if (currentWeek.length > 0) {
        return currentWeek[0].questions;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [huddleBoard, selectedDate]);

  const master = useMemo(() => {
    if (title === 'Team Pulse Check') {
      return normalizeWeeklyDataWithMaster(huddleBoard?.data)[1].master;
    } else {
      return [];
    }
  }, [huddleBoard, selectedDate]);
  
  const [modalOpen, setModalOpen] = useState(false);
  
  const [rowToEdit, setRowToEdit] = useState(null);
  
  const [rows, setRows] = useState(dataRows.length > 0 ? dataRows : master.items);

  useEffect(() => {
    if (dataRows.length > 0) {
      setIsGlobalEdit(false);
      setRows(dataRows);
    } else {
      setIsGlobalEdit(true);
      setRows(master.items);
    }
    
  }, [selectedDate, huddleBoard]);

  

  const fields = useMemo(() => {
    switch (title) {
      case 'Team Pulse Check':
        return [
          {
            name: 'question',
            label: 'Question',
            type: 'textarea',
            defaultValue: rows[rowToEdit]?.question || '',
            validation: Yup.string().required('Question is required'),
          }// ,
          // {
          //   name: 'rating',
          //   label: 'Rating',
          //   type: 'button',
          //   isMulti: true,
          //   options: teamPulseRatings,
          //   defaultValue: rows[rowToEdit]?.rating || 0,
          //   validation: Yup.number(),
          //   hidden: true,
          // }
        ];            
      default:
        return [];
    }
  }, [title, rowToEdit, rows]);
  
  const handleUpdate = (update) => {
    updateHuddleBoard(huddleBoard?.id, update, huddleBoard, 'Weekly', selectedDate);
  };

  const handleDeleteRow = (targetIndex) => {
    let updatedRows = [];
    let updatedMasterItems = master.items;
    if (dataRows.length > 0) {
      updatedRows = rows.filter((_, idx) => idx !== targetIndex);
    } else {
      updatedMasterItems = master.items.filter((_, idx) => idx !== targetIndex);
    }
    setRows(updatedRows);
    handleUpdate({weeks: updatedRows, master: {items: updatedMasterItems, subItems: master.subItems}});
  };

  const handleEditRow = (idx) => {
    setRowToEdit(idx);
    setModalOpen(true);
  };
  
  const handleSubmit = (newRow) => {

    // console.log("newRow", newRow);
    // console.log("rows", rows);
    // console.log("rowToEdit", rowToEdit);
    // console.log("master", master);
    // console.log("dataRows", dataRows);

    let updatedDataRows = dataRows;
    let updatedMaster = master;
    
    if (rowToEdit === null) {
      if (dataRows.length > 0) {
        updatedDataRows = [...rows, newRow];
        setRows(updatedDataRows);
      } else {
        
        if (title === 'Team Pulse Check') {
          updatedMaster = {
            items: [...master.items, {question: newRow.question }],
            subItems: master.subItems,
          };
        }
        
        setRows(updatedMaster.items);
      }
    } else {
      if (dataRows.length > 0) {
        if (title === 'Team Pulse Check') {
          updatedDataRows = rows.map((currRow, idx) => (idx === rowToEdit ? newRow : currRow));
        }
        
        setRows(updatedDataRows);
        setIsGlobalEdit(false);
      } else {
        
        if (title === 'Team Pulse Check') {
          updatedMaster = {          
            items: master.items.map((currRow, idx) => (idx === rowToEdit ? {question: newRow.question} : currRow)),
            subItems: master.subItems,
          };
        }
        
        setRows(updatedMaster.items);
        setIsGlobalEdit(true);
      }
    }
    
    // console.log("updatedDataRows", updatedDataRows);
    // console.log("updatedMaster", updatedMaster);

    const updatedWeekly = {
      weeks: updatedDataRows,
      master: updatedMaster,
    }  

    setRowToEdit(null);
    setModalOpen(false);
    handleUpdate(updatedWeekly);
  };
  
  const handleRateRow = (idx, rating = 0) => {    
    let updatedRows = rows;    

    if (title === 'Team Pulse Check') {
      
      updatedRows = rows      
        .map((row, index) => {
          if (index === idx) {            
            return { ...row, rating: rating };
          } else {
            return row;
          }
        }); 
      
    }
    
    

    setRows(updatedRows);
    setIsGlobalEdit(false);
    handleUpdate({weeks: updatedRows, master: master});
  };

  const componentMapping = (title, propsToPass, selectedDate, setSelectedDate, labels) => {
    switch (title) {
      case 'Sponsor Report':
        return (
          <>
            <div className="huddle-board-content__sponsor-report-container">
              <SponsorReport
                huddleBoard={propsToPass}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                labels={labels}
              />
            </div>
            <MetricWeekly
              updateHuddleBoard={updateHuddleBoard}
              huddleBoard={huddleBoard}
              title={title}
              labels={computedLabels}
              selectedDate={selectedDate}
            />
          </>
        );
      case 'Team Pulse Check':
        return (
          <>
            <div className="huddle-board-content__team-pulse-check-container">
              <TeamPulseCheck
                rows={rows}
                isGlobalEdit={isGlobalEdit}
                deleteRow={handleDeleteRow}
                editRow={handleEditRow}
                handleRateRow={handleRateRow}
              />
              <div className="team-pulse-check-btn-container btn-container flex-left">
                <button className="btn-add" onClick={() => setModalOpen(true)}>
                  + Add Question
                </button>
              </div>
              {modalOpen && (
                <Modal
                  fields={fields}
                  closeModal={() => {
                    setModalOpen(false);
                    setRowToEdit(null);
                  }}
                  onSubmit={handleSubmit}
                  defaultValue={rowToEdit !== null ? rows[rowToEdit] : {}}
                />
              )}
            </div>
            <MetricWeekly
              updateHuddleBoard={updateHuddleBoard}
              huddleBoard={huddleBoard}
              title={title}
              labels={computedLabels}
              selectedDate={selectedDate}
            />
          </>
        );
      case 'Weekly Priorities':
        return (
          <div className="huddle-board-content__weekly-priorities">
            <WeeklyPriorities
              huddleBoard={propsToPass}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              labels={labels}
            />
          </div>
        );
      default:
        return <p>No specified Huddle Board found</p>;
    }
  };

  return (
    <>
      <h2 className="huddle-board-content__title title text-center">{title}</h2>      

      <span className="huddle-board-content__date-row date-row">        
        <div
          className="month-select-button"
          onClick={() =>
            setSelectedDate(new Date(moment(selectedDate).add(-1, 'week').toISOString()))
          }
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="month-select-current">
          📅 Week of {moment(selectedDate).format('MMMM DD, YYYY')}
        </div>
        <div
          className="month-select-button"
          onClick={() =>
            setSelectedDate(new Date(moment(selectedDate).add(1, 'week').toISOString()))
          }
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </span>
      <div className={`huddle-board-content-row ${title === 'Team Pulse Check' ? 'team-pulse-check-container' : ''}`}>        
        {componentMapping(title, huddleBoard, selectedDate, setSelectedDate, labels)}
      </div>
    </>
  );
};

export default connect(null, { updateHuddleBoard })(WeeklyLayout);
