import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Button from '../../Buttons/Button';

import Loader from '../../../components/Loader/Loader';
// import MessageList from '../../../components/MessageList/MessageList';
// import ActiveProjectList from '../../../components/ActiveProjectList/ActiveProjectList';
// import ArchivedProjectList from '../../../components/ArchivedProjectList/ArchivedProjectList';
import DepartmentList from '../../../components/DepartmentList/DepartmentList';
import DepartmentForm from '../../../components/DepartmentForm/DepartmentForm';

import requireAuth from '../../../hoc/requireAuth';
import { getProject } from '../../../store/actions/projectActions';
import { getAccount } from '../../../store/actions/accountActions';
import { getAccountDepartments } from '../../../store/actions/departmentActions';
import './styles.css';

const AccountDepartmentsEdit = ({
  getProject,
  thisProject: { project, isLoading, error },
  thisAccount: { account, isLoading: accountIsLoading, error: accountError },
  thisAccountDepartments: { departments, isLoading: departmentsIsLoading, error: departmentsError },
  history,
  match,
  projectID,
  accountID,
  onUnsetForm,
  onReturnBack,
  onOpenForm,
  setDepartment,
  getAccount,
  getAccountDepartments,
}) => {
  const matchProjectId = projectID || match.params.projectId;
  const matchAccountId = accountID || match.params.accountId;

  useEffect(() => {
    if (matchAccountId) {
      getAccount(matchAccountId, true, history);
      getAccountDepartments(matchAccountId, true, history);
    } else {
      getProject(matchProjectId, true, history);
    }
  }, [getProject, getAccount, getAccountDepartments, history, matchProjectId, matchAccountId]);

  return (
    <div className="dashboard-form project-list">
      <div className="project-form__heading flex align-center justify-space-between">
        <h2 className="Material-KitH4">Departments</h2>
        <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
          <div className="btn__image">
            <img src="/icons/arrow_back.svg" alt="previous" />
          </div>
        </Button>
      </div>

      {error && <div className="error-center Material-KitCaption">{error}</div>}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="departments-list-container list flex flex-column">
          <DepartmentList
            setDepartment={setDepartment}
            onOpenForm={onOpenForm}
            projectId={matchProjectId}
            project={ matchProjectId ? project : { departments: departments }}
            accountId={matchAccountId}
            account={account}
          />
          <DepartmentForm 
            projectId={matchProjectId} 
            accountId={matchAccountId} 
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  thisProject: state.project,
  thisAccount: state.account,
  thisAccountDepartments: state.department,
});

// export default compose(requireAuth, withRouter, connect(mapStateToProps))(AccountDepartmentsEdit);
export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getProject, getAccount, getAccountDepartments }),
)(AccountDepartmentsEdit);
