import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';

import TagList from '../../components/TagList/TagList';
import TagForm from '../../components/TagForm/TagForm';

import requireAuth from '../../hoc/requireAuth';
import { getProject } from '../../store/actions/projectActions';
// import './styles.css';

const ProjectTagsEdit = ({
  getProject,
  thisProject: { project, isLoading, error },
  history,
  match,
}) => {
  const matchProjectId = match.params.projectId;

  useEffect(() => {
    getProject(matchProjectId, true, history);
  }, [getProject, history, matchProjectId]);

  return (
    <Layout>
      <div className="departments-edit-page">
        {error && <div className="error-center">{error}</div>}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h1>
              {project.title} / <i>Tags</i>
            </h1>
            <div className="department-list-container">
              {/* Tags */}
              <TagList projectId={matchProjectId} project={project} />
              <TagForm projectId={matchProjectId} />
            </div>
            <div className="form-actions">
              <Link to={`/project/edit/${project.id}`} className="btn return-btn">
                Back to Pod
              </Link>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  thisProject: state.project,
});

// export default compose(requireAuth, withRouter, connect(mapStateToProps))(ProjectTagsEdit);
export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getProject }),
)(ProjectTagsEdit);
