import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { formatedStatus } from '../../../../constants/functions';
import { useSelector } from 'react-redux';
import { faPen, faTrash, faCheck, faBoxArchive } from '@fortawesome/free-solid-svg-icons';
const NonTipActions = ({ rows, title, deleteRow, editRow, onMarkCompleteRow, onArchiveRow }) => {
  const departments = useSelector((state) => state.project.project.departments);
  const orderedActions = rows
    .map((row, originalIndex) => ({ ...row, originalIndex }))
    .slice()
    .sort((a, b) => {
      return moment(b.date).diff(moment(a.date));
    })
    .filter((row) => !row.status?.includes('archived'));
  return (
    <div className="huddle-board-content__non-tip-actions non-tip-actions">
      <h2 className="huddle-board-content__title title text-center">{title}</h2>

      <div className="table-wrapper">
        <table className="form-non-tip-actions__table table">
          <thead>
            <tr>
              <th className="nta_index">#</th>
              <th className="nta_description">Actions</th>
              <th className="nta_due">Due</th>

              <th className="nta_department">Department</th>
              <th className="nta_name">Name</th>

              <th className="nta_status">Status</th>
              <th className="nta_actions">Actions</th>
            </tr>
          </thead>
          <tbody className="expanded">
            {orderedActions.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td className="nta_index">{idx + 1}</td>
                  <td className="nta_description">{row?.actions}</td>
                  <td className="nta_due">{row?.date}</td>

                  <td className="nta_department">
                    <span className="label">
                      {departments.find((el) => el.id === row?.department)?.name}
                    </span>
                  </td>
                  <td className="nta_name">{row?.name}</td>
                  {/* 
                    <td className="nta_complete">{row?.isComplete ? 'Complete' : 'In Progress'}</td> */}

                  <td className="nta_status">
                    {row.status && row.status.length > 0 ? (
                      row.status.map((el, index) => (
                        <span key={index} className={`status ${el}`}>
                          {String(formatedStatus(el))}
                        </span>
                      ))
                    ) : (
                      <span className="status no-status">No Status</span>
                    )}
                  </td>
                  <td className="nta_actions">
                    <span className="actions">
                      
                      {row.status && row.status.length > 0 ? (
                        row.status.filter((el) => el === 'completed').length === 0 && (                          
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="check-btn"
                                onClick={() => onMarkCompleteRow(row.originalIndex)}
                            />
                            )                         
                      ) : (
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="check-btn"
                            onClick={() => onMarkCompleteRow(row.originalIndex)}
                        />
                      )}
                      
                      <FontAwesomeIcon
                        icon={faPen}
                        className="edit-btn"
                        onClick={() => editRow(row.originalIndex)}
                      />
                      <FontAwesomeIcon
                        icon={faBoxArchive}
                        className="archive-btn"
                        onClick={() => onArchiveRow(row.originalIndex)}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        className=" delete-btn"
                        onClick={() => {
                          deleteRow(row.originalIndex);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NonTipActions;
