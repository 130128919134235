import * as Yup from 'yup';

export const taskFormSchema = Yup.object({
  title: Yup.string()
    .min(3, 'Must be at least 3 characters long')
    .max(140, 'Can\'t exceed 140 characters')
    .required('This field is required'),
  description: Yup.string()
    .min(3, 'Description must be at least 3 characters long, or leave blank')
    .max(1000, 'Description must be 1000 characters or less'),    
  complete: Yup.boolean(),
  assignedTo: Yup.string(),
  department: Yup.string(),
  project: Yup.string(),
  approved: Yup.boolean(),
  delay: Yup.number(),
  dueDate: Yup.date(),
  audit: Yup.array(),
});
