import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Tag from '../Tag/Tag';
import Loader from '../Loader/Loader';

import { getTags } from '../../store/actions/tagActions';
import './TagList.css';

const TagList = ({ getTags, projectId, project, tag: { tags, isLoading, error }, setTag, onOpenForm }) => {
  useEffect(() => {
    getTags(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tag-list">
      {error && <div className="error-center Material-KitCaption">{error}</div>}
      {/* <div className="list list-sm"> */}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {tags.length > 0 ? tags.map((tag, index) => {
              return <Tag key={index} tag={tag} project={project} setTag={setTag} onOpenForm={onOpenForm} />;
            }) : <p className="Material-KitB1">No tags created</p>}
          </>
        )}
      {/* </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tag: state.tag,
});

export default connect(mapStateToProps, { getTags })(TagList);
