import React from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../Buttons/Button';
import { addAccount } from '../../store/actions/accountActions';
import '../UserForm/UserForm.css';

const AccountForm = ({ accountID, auth, account: { accounts, isLoading, error }, addAccount, onOpenForm, getAccounts, onReturnBack }) => {
  const account = accounts.find((account) => account.id === accountID);
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      phone: '',
      emailPrimary: '',
      emailSecondary: '',
      archived: false,
      admins: [],
      avatar: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(50, 'Must be 50 characters or less')
        .required('Name is required'),
      description: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(1000, 'Must be 1000 characters or less'),
      phone: Yup.string(),
      emailPrimary: Yup.string().email('Invalid email address').required('Email is required'),
      emailSecondary: Yup.string().email('Invalid email address'),      
    }),
    onSubmit: (values) => {
      const submitValues = { ...values };      

      addAccount(submitValues);
      
      formik.resetForm();
      onOpenForm(null);
      getAccounts();
      
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    onOpenForm(null);
  };

  return (
    <div className="user-form user__column">
      <div className="users__heading">
        <h2>Create New Account</h2>
        <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
          <div className="btn__image">
            <img src="/icons/arrow_back.svg" alt="previous" />
          </div>
        </Button>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <h4 className="label Material-KitButton">Account Details</h4>

        <input
          name="name"
          className="Material-KitSB2"
          placeholder="Full Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />

        <textarea
          name="description"
          rows="4"
          className="Material-KitSB2"
          placeholder="Description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
        />

        <input
          name="phone"
          className="Material-KitSB2"
          placeholder="Phone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
        />

        <input
          name="emailPrimary"
          className="Material-KitSB2"
          placeholder="Email Primary"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.emailPrimary}
        />

        <input
          name="emailSecondary"
          className="Material-KitSB2"
          placeholder="Email Secondary"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.emailSecondary}
        />
        
        <div className="user__buttons">
          <Button
            type="submit"
            className="save-user submit-btn Material-KitButton bg"
            disabled={formik.isSubmitting}
          >
            {account ? 'Save' : 'Create'}
          </Button>
          <Button
            type="button"
            onClick={handleCancel}
            className="cancel-btn submit-btn Material-KitButton bg"
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
        </div>

        {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}

        {Object.keys(formik.errors).map(
          (key) =>
            formik.touched[key] &&
            key !== 'submit' &&
            key !== 'currentAvatar' &&
            formik.errors[key] && (
              <p key={key} className="error">
                {formik.errors[key]}
              </p>
            ),
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  account: state.account,
});

export default connect(mapStateToProps, { addAccount })(AccountForm);
