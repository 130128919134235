import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { compose } from 'redux';
import requireAuth from '../../hoc/requireAuth';
import * as Yup from 'yup';
import Button from '../Buttons/Button';
import { editUser } from '../../store/actions/userActions';
import './UserForm.css';
import styles from '../../pages/Profile/DragAndDrop.module.css';

const UserFormEdit = ({ userID, auth, users, editUser, onOpenForm, getUsers, accountId, setSearchTerm }) => {
  const [error, setError] = useState(null);
  const user = users.users.find((user) => user.id === userID);
  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      username: user?.username || '',
      role: user?.role || 'USER',
      oldPassword: '',
      password: '',
      confirmPassword: '',
      avatar: null,
      currentAvatar: user?.avatar || null,
      provider: user?.provider || 'local',
      account: user?.account?.id || null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(30, 'Must be 30 characters or less')
        .required('Name is required'),
      username: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(20, 'Must be 20 characters or less')
        .matches(/^[a-zA-Z0-9_]+$/, 'Invalid characters in username')
        .required('Username is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      oldPassword: Yup.string().test(
        'password-change',
        'Current password is required to change password',
        function (value) {
          if (user && this.parent.password && !(auth.me?.role === 'SUPERADMIN' || user.account?.admins.some(admin => admin.toString() === auth.me?.id.toString()))) {
            return !!value;
          }
          return true;
        },
      ),
      password: Yup.string()
        .min(6, 'Must be 6 characters at minimum')
        .test('password-match', 'New password is required', function (value) {
          if (user && this.parent.oldPassword) {
            return !!value;
          }
          return true;
        }),
      confirmPassword: Yup.string().test(
        'passwords-match',
        'Passwords must match',
        function (value) {
          return !this.parent.password || value === this.parent.password;
        },
      ),
      role: Yup.string()
        .oneOf(['USER', 'PODMASTER', 'SUPERADMIN'], 'Invalid role')
        .required('Role is required'),
      avatar: Yup.mixed().nullable(),
      account: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      const submitValues = { ...values };

      if (!submitValues.avatar) {
        delete submitValues.avatar;
      }

      delete submitValues.confirmPassword;
      delete submitValues.currentAvatar;

      if (submitValues.password) {
        if (auth.me?.role === 'SUPERADMIN' || user.account?.admins.some(admin => admin.toString() === auth.me?.id.toString())) {
          delete submitValues.oldPassword;
        } else if (!submitValues.oldPassword) {
          delete submitValues.password;
        }
      } else {
        delete submitValues.password;
        delete submitValues.oldPassword;
      }

      if (auth.me?.role !== 'SUPERADMIN') {
        delete submitValues.role;
      }

      if (!user) {
        submitValues.provider = 'local';
      }

      if (submitValues.account === "" || submitValues.account === null) {
        delete submitValues.account;
      }

      try {
        const success = await editUser(userID, submitValues);

        
        formik.resetForm();
        setError(null);
        onOpenForm(userID);
        getUsers(accountId);
        setSearchTerm('');
      } catch (error) {
        setError(error.message || 'An error occurred while editing user');
        console.error('Error editing user:', error);
      }
    },
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        name: user.name || '',
        email: user.email || '',
        username: user.username || '',
        role: user.role || 'USER',
        oldPassword: '',
        password: '',
        confirmPassword: '',
        avatar: null,
        currentAvatar: user.avatar || "",
        provider: user.provider || 'local',
        account: user.account?.id || "",
      });
    }
  }, [userID, user]);

  const handleCancel = () => {
    formik.resetForm();
    setError(null);
    onOpenForm(userID);
    getUsers(accountId);
  };


   // DRAG and DROP for CLOUDINARY
   const [dragOver, setDragOver] = useState(false);
   const [droppedFiles, setDroppedFiles] = useState([]);
   const [loadingStates, setLoadingStates] = useState([]);
   const abortControllerRef = useRef(new AbortController());
 
   const handleDragOver = (e) => {
     e.preventDefault();
     setDragOver(true);
   };
 
   const handleDragLeave = () => {
     setDragOver(false);
   };
 
   const handleDrop = async (e) => {
     e.preventDefault();
     setDragOver(false);
 
     let files = [];
     if (e.currentTarget?.files) {
       files = Array.from(e.currentTarget.files);
     } else if (e.dataTransfer?.files) {
       files = Array.from(e.dataTransfer.files);
     }
     // console.log("Current Target: ", e.currentTarget?.files);
     // console.log("Data Transfer: ", e.dataTransfer?.files);
     // const files = [];
     // const files = Array.from(e.dataTransfer?.files) || e.currentTarget.files;
     setDroppedFiles([]);
     
     if (files && files.length > 1) {
       alert('Upload only one image file.');
     } else if (files && files.length === 1) {
       setLoadingStates(new Array(files.length).fill(true));
       
       abortControllerRef.current.abort();
       abortControllerRef.current = new AbortController();
       
       
       const url = `https://api.cloudinary.com/v1_1/${
         process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
       }/upload`;
       for (let file of files) {
         try {
           const formData = new FormData();
           const fields = {
             file,
             upload_preset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
             tags: ['user-profile', `userid-${userID}`],
             multiple: false,
             resource_type: 'image',            
           };
 
           Object.entries(fields).forEach(([key, value]) => {
             formData.append(key, value);
           });
 
           const options = {
             method: 'POST',
             body: formData,
             signal: abortControllerRef.current.signal,
           };
           const response = await fetch(url, options);
           if (!response.ok) {
             throw new Error('Failed to execute file upload via the Fetch API');
           }
           const json = await response.json();
           const secureUrl = json.secure_url;
           // const previewUrl = secureUrl;
           const previewUrl = secureUrl.replace(
             '/upload/',
             '/upload/c_fill,g_face,h_200,w_200/f_auto/'
           );
 
           setDroppedFiles((prevFiles) => [...prevFiles, { file, previewUrl }]);
           formik.setFieldValue('avatar', previewUrl);
           formik.setFieldValue('currentAvatar', previewUrl);
           setLoadingStates((prevStates) =>
             prevStates.map((state, index) =>
               file === files[index] ? false : state
             )
           );
         } catch (error) {
           if (error.name !== 'AbortError') {
             console.error(error);
           }
           setLoadingStates((prevStates) =>
             prevStates.map((state, index) =>
               file === files[index] ? false : state
             )
           );
         }
       }
     }
   };
 
   useEffect(() => {
     return () => {
       abortControllerRef.current.abort();
     };
   }, []);
   // END DRAG and DROP for CLOUDINARY

  

  return (
    <div className="user-form user__column profile-page">
      <div className="users__heading">
        <h2>Edit User</h2>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <h4 className="label Material-KitButton">User Details</h4>

        <div className="avatar-section">
          {formik.values.currentAvatar && (
            <img
              src={
                formik.values.currentAvatar.startsWith('http')
                  ? formik.values.currentAvatar
                  : `${process.env.REACT_APP_SERVER_BASE_URL}${formik.values.currentAvatar}`
              }
              alt="Current avatar"
              className="current-avatar"
              style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '16px' }}
            />
          )}
          <input
            type="file"
            accept="image/png,image/jpeg,image/jpg"
            id="avatar"
            name="avatar"
            className=" invisible Material-KitSB2"
            placeholder="Avatar"
            onChange={(event) => {
              // formik.setFieldValue('avatar', event.currentTarget.files[0]);
              handleDrop(event);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.avatar && formik.errors.avatar && (
            <p className="error">{formik.errors.avatar}</p>
          )}

          <div
            className={`${styles.dragArea} ${dragOver ? `${styles.dragOver}` : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            
            {loadingStates.some((loading) => loading) ? (
              // <span className="drag-and-drop-image loading loading-spinner text-primary"></span>                      
              <img src="/icons/asterisk.svg" className="drag-and-drop-image loading loading-spinner text-primary" alt="Uploading Image" />
            ) :
              <img src="/icons/upgrade.svg" className="drag-and-drop-image" alt="Upload Image" />
            }
            <div className="drag-and-drop-text-container">
              <div className="drag-and-drop-text">Drop an image here</div>
              <div className="or-text">or</div>
              <label htmlFor="avatar" className="select-file-link">select file</label>
            </div>
          </div>
        </div>

        <input
          name="name"
          className="Material-KitSB2"
          placeholder="Full Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        {formik.touched.name && formik.errors.name && <p className="error">{formik.errors.name}</p>}

        <input
          name="username"
          className="Material-KitSB2"
          placeholder="Username"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
        />
        {formik.touched.username && formik.errors.username && (
          <p className="error">{formik.errors.username}</p>
        )}

        <input
          name="email"
          className="Material-KitSB2"
          placeholder="Email Address"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email && (
          <p className="error">{formik.errors.email}</p>
        )}

        {auth.me?.role === 'SUPERADMIN' && user?.role !== 'SUPERADMIN' && (
          <>
            <h5 className="user-form-label Material-KitOverline">User Role</h5>
            <select
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
              className="user-form-select Material-KitSB2"
            >
              <option value="USER">User</option>
              <option value="PODMASTER">Pod Master</option>
              <option value="SUPERADMIN">Super Admin</option>
            </select>
            {formik.touched.role && formik.errors.role && (
              <p className="error">{formik.errors.role}</p>
            )}

            <h5 className="user-form-label Material-KitOverline">User Account</h5>
            <input
              name="account"
              className="Material-KitSB2"
              placeholder="Account ID"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.account}
            />
            {formik.touched.account && formik.errors.account && (
              <p className="error">{formik.errors.account}</p>
            )}
          </>
        )}

        {/* {user && user.provider === 'local' && ( */}
        {user && (
          <div className="password-change-section">
            <h4 className="label Material-KitButton">Change Password</h4>
            {!(auth.me?.role === 'SUPERADMIN' || user.account?.admins.some(admin => admin.toString() === auth.me?.id.toString())) && (
              <>
                <input
                  type="password"
                  name="oldPassword"
                  className="Material-KitSB2"
                  placeholder="Current Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oldPassword}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <p className="error">{formik.errors.oldPassword}</p>
                )}
              </>
            )}
            <input
              type="password"
              name="password"
              className="Material-KitSB2"
              placeholder="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password && (
              <p className="error">{formik.errors.password}</p>
            )}
            {formik.values.password && (
              <>
                <input
                  type="password"
                  name="confirmPassword"
                  className="Material-KitSB2"
                  placeholder="Confirm New Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <p className="error">{formik.errors.confirmPassword}</p>
                )}
              </>
            )}
          </div>
        )}
        <div className="user__buttons">
          <Button
            type="submit"
            className="save-user submit-btn Material-KitButton bg"
            disabled={formik.isSubmitting}
          >
            Save
          </Button>
          <Button
            type="button"
            onClick={handleCancel}
            className="cancel-btn submit-btn Material-KitButton bg"
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
        </div>

        {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
        {error && <p className="error">{error}</p>}
        {users.error && <p className="error">{users.error}</p>}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
});

export default compose(requireAuth, connect(mapStateToProps, { editUser }))(UserFormEdit);
