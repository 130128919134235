// import React, { useState, useEffect } from 'react';
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';



const DepartmentFilter = ({ departmentFilter, toggleDepartmentFilter, project, filterDepartmentsArray }) => {
  
    

    // VERTICAL SCROLL
    const deptScrollRef = useRef(null);
    const [ isMouseDown, setIsMouseDown ] = useState(false);
    const [ startX, setStartX ] = useState(0);
    const [ scrollLeft, setScrollLeft ] = useState(0);
    
    const handleMouseDown = (e) => {
        setIsMouseDown(true);
        // setStartX(e.x);
        setStartX(e.pageX - - deptScrollRef.current.offsetLeft);
        setScrollLeft(deptScrollRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsMouseDown(false);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleMouseMove = (e) => {
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - deptScrollRef.current.offsetLeft;
        const walk = (x - startX) * 1;
        deptScrollRef.current.scrollLeft = scrollLeft - walk;
    };
    // END VERTICAL SCROLL
  

  
  return (
    <div 
        ref={deptScrollRef} 
        id="department-scrollable-filter" 
        className="project-title-bar-right-filter-container mobile-scrollable-filters"
        // onWheel={(e) => console.log('e', e)}
        // onClick={() => deptScrollRef.current.scrollLeft += 20}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
    >
        <div className="project-title-bar-right-filter-title">Departments:</div>
        {filterDepartmentsArray(project.departments).map((department) => (
        <div
            key={department.id}
            className={`project-title-bar-right-filter-button-department ${
            departmentFilter[department.id] ? 'filter-active' : ''
            }`}
            style={{ backgroundColor: project.taskColoredBy === 0 ? department.color : 'var(--brand-color)', color: project.taskColoredBy === 0 || departmentFilter[department.id]? '#131523' : 'white' }}
            onClick={() => toggleDepartmentFilter(department.id)}
        >
            {department.name}
            {departmentFilter[department.id] ? (
            ''
            ) : (
            <>
                &nbsp; <FontAwesomeIcon icon={faXmark} />
            </>
            )}
        </div>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,    
});


export default connect(mapStateToProps)(DepartmentFilter);