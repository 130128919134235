import React, { useMemo, useState, useEffect } from 'react';
import LetterLayout from './LettersLayout/LetterLayout';
import CustomLayout from './CustomLayout/CustomLayout';
import DailyLayout from './DailyLayout/DailyLayout';
import WeeklyLayout from './WeeklyLayout/WeeklyLayout';
import { letterOptions, dailyOptions, weeklyOptions } from '../../constants/index';

const HuddleBoardContent = ({ title, huddleBoard, labels, tags }) => {
  
  const [selectedTag, setSelectedTag] = useState(tags[0]?.id);
  const [selectedHuddleBoard, setSelectedHuddleBoards] = useState(huddleBoard[0]);

  const isLetterComponent = useMemo(() => {
    return letterOptions.includes(title);
  }, [title]);

  const isDailyComponent = useMemo(() => {
    return dailyOptions.includes(title);
  }, [title]);

  const isWeeklyComponent = useMemo(() => {
    return weeklyOptions.includes(title);
  }, [title]);

  useEffect(() => {
    if (huddleBoard.length === 1) { 
      setSelectedHuddleBoards(huddleBoard[0]);
    } else {
      setSelectedHuddleBoards(huddleBoard.find((el) => el.tag === selectedTag));
    }
  }, [selectedTag, huddleBoard]);

  return (
    <div className="huddle-board-content">
      
      {huddleBoard.length > 1 && <div className="huddle-board-tag-filter">
        {tags.map((tag) => (
          <div 
            className={`huddle-board-tag-filter-item ${selectedTag === tag.id ? 'huddle-board-tag-filter-item-active' : ''}`} 
            key={tag.id} 
            onClick={() => setSelectedTag(tag.id)}
            style={{ backgroundColor: selectedTag === tag.id ? tag.color : 'var(--huddle-board-bg-color)' }}
          >
            {tag.name}
          </div>
        ))}

      </div>}

      {isLetterComponent ? (
        <LetterLayout title={title} huddleBoard={selectedHuddleBoard} labels={labels} />
      ) : isDailyComponent ? (
        <DailyLayout title={title} huddleBoard={selectedHuddleBoard} labels={labels}/>
      ) : isWeeklyComponent ? (
        <WeeklyLayout title={title} huddleBoard={selectedHuddleBoard} labels={labels} />
      ) : (
        <CustomLayout title={title} huddleBoard={selectedHuddleBoard} />
      )}

    </div>
  );
};

export default HuddleBoardContent;
