import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from '../../Buttons/Button';
import Loader from '../../Loader/Loader';

import requireAuth from '../../../hoc/requireAuth';
import {
  getUserForDepartment,
  deleteUserForDepartment,
  getDepartmentUsers,
} from '../../../store/actions/departmentActions';
import { getAccountUsers } from '../../../store/actions/userActions';
import './styles.css';

import { departmentUsersFormSchema } from './validation';

const ProjectDepartmentsUsersEdit = ({
  departmentID,
  accountID,
  getDepartmentUsers,
  getAccountUsers,
  department: { department, isLoading, error },
  user: { users, isLoading: usersIsLoading, error: usersError },
  getUserForDepartment,
  deleteUserForDepartment,
  onReturnBack,
}) => {
  const [addUserError, setAddUserError] = useState(null);
  const [isSave, setIsSave] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: departmentUsersFormSchema,
    onSubmit: (values, { resetForm }) => {
      // editDepartment(values.id, { name: values.name, color: values.color, viewOnly: values.viewOnly, external: values.external, project: values.project.id, users: values.users });
      // Check if the user already exists in the department
      const userExists = department.users.some((user) => user.email === formik.values.email);
      if (userExists) {
        setAddUserError('This user is already in the department.');
        return;
      } else {
        getUserForDepartment({ email: formik.values.email, departmentID: department.id });
        setAddUserError(null);
        formik.setFieldTouched('email', false, false);
        formik.setFieldValue('email', '');
        setIsSave(true);
      }

      // resetForm();
    },
  });

  const handleRemoveUser = (id, departmentID) => {
    // Check if the user already exists in the department
    // const userExists = department.users.some(user => user.email === email);
    // if (!userExists) {
    //   setAddUserError('This user is already in the department.');
    //   return;
    // } else {
    setAddUserError(null);
    // formik.setFieldTouched('email', false, false);
    deleteUserForDepartment({ id, departmentID });
    // formik.setFieldValue('email', '');
    setIsSave(true);
    // }
  };

  const handleAddUser = (id, departmentID) => {
    setAddUserError(null);
    getUserForDepartment({ id, departmentID });
    setIsSave(true);
  };

  useEffect(() => {
    getDepartmentUsers(departmentID, true);
    getAccountUsers(accountID, true);    
  }, []);

  useEffect(() => {
    getDepartmentUsers(departmentID, true);
    getAccountUsers(accountID || department.account.id, true);

    setIsSave(false);
  }, [setIsSave, isSave, getDepartmentUsers, departmentID, getAccountUsers, accountID]);

  return (
    <div className="department-users-edit-page dashboard-form project-list">
      {error && <div className="error-center">{error}</div>}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project-form__heading flex align-center justify-space-between margin-bottom-sm">
            <h2 className="Material-KitH4">
              {department.name} - Members
            </h2>
            <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
              <div className="btn__image">
                <img src="/icons/arrow_back.svg" alt="previous" />
              </div>
            </Button>
          </div>
          <div className="podmasters-list-container list flex flex-column">
            <div className="podmaster-list">
              {department.users.length > 0
                ? department.users.map((user, index) => {
                    return (
                      <div key={index} className="podmaster">
                        <div className="podmaster-list-item">
                          <div className="user-title">
                            <div className="user-info__image">
                              <img src={user.avatar.startsWith('http') ? user.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${user.avatar}`} alt="user" />
                            </div>                                                          
                            <div className="user-name Material-KitCaption">{user.name}</div>
                          </div>
                          <div className="user-info-container">
                            {/* <div className="user-info user-username Material-KitOverline">
                              @{user.username}
                            </div> */}
                            <div className="user-info user-email Material-KitOverline">
                              {user.email}
                            </div>
                          </div>
                        </div>
                        <div className="podmaster-actions">
                          <Button
                            size="x-mini"
                            onClick={() => handleRemoveUser(user.id, department.id)}
                            type="button"
                            className="primary-red"
                          >
                            <div className="btn__image">
                              <img src="/icons/delete.svg" alt="delete" />
                            </div>
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : <p className="Material-KitB1">No members selected</p>}
            </div>
            {/* <div className="department-users-form">
              <h2>Add a member</h2>
              <form onSubmit={formik.handleSubmit}>
                <>
                  <input
                    name="email"
                    placeholder="User email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    disabled={department.isLoading}
                    className="user-input Material-KitCaption"
                  />
                  {(formik.touched.email && formik.errors.email) || addUserError ? (
                    <p className="error Material-KitCaption">
                      {formik.errors.email || addUserError}
                    </p>
                  ) : null}
                  {department.error ? (
                    <p className="error Material-KitCaption">{department.error}</p>
                  ) : null}
                  <button
                    type="submit"
                    className="save-new-pod submit-btn unique-btn Material-KitButton bg"
                    disabled={department.isLoading}
                  >
                    Add Member
                  </button>
                </>
              </form>
            </div> */}
            <div className="podmaster-list">
              <h2>Add from existing users</h2>
              {users.filter(user => !department.users.some(departmentUser => departmentUser.id === user.id)).length > 0
                ? users.filter(user => !department.users.some(departmentUser => departmentUser.id === user.id)).map((user, index) => {
                    return (
                      <div key={index} className="podmaster">
                        <div className="podmaster-list-item">
                          <div className="user-title">
                            <div className="user-info__image">
                              <img src={user.avatar.startsWith('http') ? user.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${user.avatar}`} alt="user" />
                            </div>                                                          
                            <div className="user-name Material-KitCaption">{user.name}</div>
                          </div>
                          <div className="user-info-container">
                            {/* <div className="user-info user-username Material-KitOverline">
                              @{user.username}
                            </div> */}
                            <div className="user-info user-email Material-KitOverline">
                              {user.email}
                            </div>
                          </div>
                        </div>
                        <div className="podmaster-actions">
                          <Button
                            size="x-mini"
                            onClick={() => handleAddUser(user.id, department.id)}
                            type="button"
                            className="primary-blue"
                          >
                            <div className="btn__image">
                              <img src="/icons/add.svg" alt="add" />
                            </div>
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : <p className="Material-KitB1">No users remaining</p>}
            </div>
          </div>
          {/* 
          <div className="form-actions">
            <Button onClick={onReturnBack} className="btn return-btn Material-KitButton">
              Back
            </Button>
          </div> */}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  department: state.department,
  user: state.user,
});

// export default compose(requireAuth, withRouter, connect(mapStateToProps))(ProjectDepartmentsEdit);
export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getDepartmentUsers, getUserForDepartment, deleteUserForDepartment, getAccountUsers }),
)(ProjectDepartmentsUsersEdit);
