import React from 'react';
import { connect } from 'react-redux';
import Button from '../Buttons/Button';

import { deleteTag, editTag, clearTagError } from '../../store/actions/tagActions';

import './Tag.css';

const Tag = ({ tag, auth, deleteTag, project, editTag, clearTagError, setTag, onOpenForm }) => {
  const checkPermissions = () => {
    if (
      auth.isAuthenticated &&
      (auth.me.id === tag.project.user ||
        auth.me.role === 'SUPERADMIN' ||
        project.podMasters.some((pm) => pm.id === auth.me.id) ||
        project.account.admins.some((admin) => admin.toString() === auth.me.id.toString())
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={tag.isLoading ? 'tag loader' : 'tag'}>
      <div className="tag-list-item">
        <div className="tag-row">
          <div className="tag-color-box" style={{ backgroundColor: tag.color }}></div>
          <div className="tag-name Material-KitCaption">{tag.name}</div>
        </div>
      </div>
      {checkPermissions() && (
        <div className="tag-actions flex align-center">
          <Button
            size="x-mini"
            className="primary-blue"
            onClick={() => {
              setTag(tag.id);
              onOpenForm('tag/edit');
            }}
          >
            <div className="tag-actions__image">
              <img src="/icons/edit.svg" alt="edit" />
            </div>
          </Button>
          <Button
            size="x-mini"
            className="primary-red"
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this tag?')) {
                deleteTag(tag.id);
              }
            }}
          >
            <div className="tag-actions__image">
              <img src="/icons/delete.svg" alt="delete" />
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deleteTag,
  editTag,
  clearTagError,
})(Tag);
