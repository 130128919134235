import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';

import { addTag } from '../../store/actions/tagActions';
import { tagFormSchema } from './validation';
import Button from '../Buttons/Button';
import { colorPickerColors } from '../../constants/index';
import './TagForm.css';

const TagForm = ({
  addTag,
  projectId,
  tag: { tags, isLoading: tagIsLoading },
  onReturnBack,
  setTag,
}) => {
  const [color, setColor] = useState('#000000');
  const formik = useFormik({
    initialValues: {
      name: '',
      project: projectId,
      color: color,
    },
    validationSchema: tagFormSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log("Tag form values:", values);
      addTag({ name: values.name, project: values.project, color: values.color });
      setColor('#000000');
      resetForm();
    },
  });

  const isSubmiting = tags.some((d) => d.id === 0);

  return (
    <div className="tag-form-page">
      <h2 className="Material-KitH4">Create New Tag</h2>

      {/* <div className="list"> */}
        <form onSubmit={formik.handleSubmit}>
          <input type="hidden" name="id" />
          <h3 className="label Material-KitCaption">Tag Name</h3>
          <input
            name="name"
            placeholder="Tag name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            disabled={isSubmiting}
            className="Material-KitSB2"
          />
          {formik.touched.name && formik.errors.name ? (
            <p className="error Material-KitCaption">{formik.errors.name}</p>
          ) : null}

          <h3 className="label Material-KitCaption">Color</h3>
          <div className="color-picker margin-bottom-sm">
            {colorPickerColors.map((i) => (
              <button
                type="button"
                key={i}
                className="color-button"
                style={{
                  backgroundColor: i,
                  border: color === i ? '2px solid var(    --primary-primary-80)' : 'none',
                }}
                onClick={() => {
                  setColor(i);
                  formik.setFieldValue('color', i);
                }}
              />
            ))}
          </div>
          {formik.touched.color && formik.errors.color ? (
            <p className="error Material-KitCaption">{formik.errors.color}</p>
          ) : null}

          <div className="form-actions">
            <Button type="submit" className="save-new-pod submit-btn unique-btn Material-KitButton bg" disabled={isSubmiting}>
              Add Tag
            </Button>
          </div>
        </form>
      {/* </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tag: state.tag,
});

export default connect(mapStateToProps, { addTag })(TagForm);
