import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../Loader/Loader';
import requireAuth from '../../../hoc/requireAuth';
import {
  getAccount,
  addAdminForAccount,
  deleteAdminForAccount  
} from '../../../store/actions/accountActions';
import { getAccountUsers } from '../../../store/actions/userActions';
import { adminsFormSchema } from './validation';
import Button from '../../Buttons/Button';
  
const AccountAdminsEdit = ({
  getAccount,
  account: { account, isLoading, error },
  user: { users, isLoading: usersIsLoading, error: usersError },
  accountID,
  addAdminForAccount,
  deleteAdminForAccount,
  getAccountUsers,
  onReturnBack,
}) => {
  const [addUserError, setAddUserError] = useState(null);
  const [isSave, setIsSave] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: adminsFormSchema,
    onSubmit: (values, { resetForm }) => {
      const userExists = account.admins.some((user) => user.email === formik.values.email);
      if (userExists) {
        setAddUserError('This admin is already in the account.');
        return;
      } else {
        addAdminForAccount({ email: formik.values.email, accountID: account.id });
        setAddUserError(null);
        formik.setFieldTouched('email', false, false);
        formik.setFieldValue('email', '');
        setIsSave(true);
      }

      // resetForm();
    },
  });

  // const handleRemoveUser = (email, accountID) => {
  //   setAddUserError(null);

  //   deleteAdminForAccount({ email, accountID });

  //   setIsSave(true);
  // };

  useEffect(() => {
    getAccount(accountID, true);
    getAccountUsers(accountID);
  }, []);

  useEffect(() => {
    getAccount(accountID, true);
    getAccountUsers(accountID);
  }, [accountID]);

  useEffect(() => {
    // getProject(matchProjectId, true, history);
    setIsSave(false);
  }, [isSave]);

  const handleRemoveAdmin = (id) => {
    setAddUserError(null);
    deleteAdminForAccount(account.id, id);
    setIsSave(true);
  };

  const handleAddAdmin = (id) => {
    setAddUserError(null);
    addAdminForAccount(account.id, id);
    setIsSave(true);
  };

  return (
    // <div className="department-users-edit-page dashboard-form project-list project-pod-masters-edit">
    <div className="podmasters-edit-page dashboard-form project-list project-pod-masters-edit">
      {error && <div className="error-center">{error}</div>}
      {(isLoading || usersIsLoading) ? (
        <Loader />
      ) : (
        <>
          <div className="project-form__heading flex align-center justify-space-between margin-bottom-sm">
            <h2 className="Material-KitH4">
              Admins
            </h2>
            <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
              <div className="btn__image">
                <img src="/icons/arrow_back.svg" alt="previous" />
              </div>
            </Button>
          </div>
          {/* <div className="department-users-list-container list flex flex-column"> */}
          <div className="podmasters-list-container list flex flex-column">
            {/* <div className="department-users-list podmasters-list"> */}
            <div className="podmaster-list">
              {account.admins?.length > 0
                ? account.admins?.map((user, index) => {
                    return (                      
                      <div key={index} className="podmaster">
                      {/* <div key={index} className="department-user podmaster"> */}
                        {/* <div className="department-users-list-item podmaster-list-item">*/}
                        <div className="podmaster-list-item">
                          <div className="user-title">
                            <div className="user-info__image">
                              <img src={user.avatar.startsWith('http') ? user.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${user.avatar}`} alt="user" />
                            </div>                                                          
                            <div className="user-name Material-KitCaption">{user.name}</div>
                          </div>
                          <div className="user-info-container">
                            {/* <div className="user-info user-username Material-KitOverline">
                              @{user.username}
                            </div> */}
                            <div className="user-info user-email Material-KitOverline">
                              {user.email}
                            </div>
                          </div>
                        </div>
                        {/* <div className="department-users-actions">*/}
                        <div className="podmaster-actions">
                          <Button
                            size="x-mini"
                            onClick={() => handleRemoveAdmin(user.id)}
                            type="button"
                            className="primary-red"
                          >
                            <div className="btn__image">
                              <img src="/icons/delete.svg" alt="delete" />
                            </div>
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : <p className="Material-KitB1">No admins selected</p>}
            </div>
            <div className="podmaster-list">
              <h2>Add from existing users</h2>
              {users.filter(user => !account.admins.some(admin => admin.id === user.id)).length > 0
                ? users.filter(user => !account.admins.some(admin => admin.id === user.id)).map((user, index) => {
                    return (                      
                      <div key={index} className="podmaster">
                      {/* <div key={index} className="department-user podmaster"> */}
                        {/* <div className="department-users-list-item podmaster-list-item">*/}
                        <div className="podmaster-list-item">
                          <div className="user-title">
                            <div className="user-info__image">
                              <img src={user.avatar.startsWith('http') ? user.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${user.avatar}`} alt="user" />
                            </div>                                                          
                            <div className="user-name Material-KitCaption">{user.name}</div>
                          </div>
                          <div className="user-info-container">
                            {/* <div className="user-info user-username Material-KitOverline">
                              @{user.username}
                            </div> */}
                            <div className="user-info user-email Material-KitOverline">
                              {user.email}
                            </div>
                          </div>
                        </div>
                        {/* <div className="department-users-actions">*/}
                        <div className="podmaster-actions">
                          <Button
                            size="x-mini"
                            onClick={() => handleAddAdmin(user.id)}
                            type="button"
                            className="primary-blue"
                          >
                            <div className="btn__image">
                              <img src="/icons/add.svg" alt="add" />
                            </div>
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : <p className="Material-KitB1">No users remaining.</p>}
            </div>
            {/* <div className="department-users-form">
              <h2>Add an admin</h2>
              <form onSubmit={formik.handleSubmit}>
                <>
                  <input
                    name="email"
                    placeholder="User email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    disabled={account.isLoading}
                    className="user-input Material-KitSB2 margin-bottom-sm"
                  />
                  {(formik.touched.email && formik.errors.email) || addUserError ? (
                    <p className="error Material-KitCaption">
                      {formik.errors.email || addUserError}
                    </p>
                  ) : null}
                  {account.error ? (
                    <p className="error Material-KitCaption">{account.error}</p>
                  ) : null}
                  <button
                    type="submit"
                    className="save-new-pod submit-btn unique-btn Material-KitButton bg"
                    disabled={account.isLoading}
                  >
                    Add Admin
                  </button>
                </>
              </form>
            </div> */}
          </div>
          
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  account: state.account,
  user: state.user,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getAccount, addAdminForAccount, deleteAdminForAccount, getAccountUsers }),
)(AccountAdminsEdit);
