import React, { useMemo, useState, useEffect } from 'react';
import MetricDaily from '../Metrica/MetricDaily';
import DailyAgenda from './DailyAgenda';
import Attendance from './Attendance';
import DailyAgendaDeepDive from './DailyAgendaDeepDive';
import { dailyAgendaStatuses, attendanceStatuses } from '../../../constants/index';
import { connect, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '../DailyWeeklyModals/Modal';
import { normalizeYearsDataWithMaster } from '../../../constants/functions';

import { selectDate, selectYear, selectMonth, selectDaysInMonth } from '../../../constants/functions';
import { updateHuddleBoard } from '../../../store/actions/huddleBoardAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck, faXmark, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import './styles.css';
// Function to format date to DD-MM-YY
const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
};


const DailyLayout = ({ updateHuddleBoard, title, huddleBoard, labels }) => {  
  
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [isGlobalEdit, setIsGlobalEdit] = useState(true);

  const dataRows = useMemo(() => {
    return normalizeYearsDataWithMaster(huddleBoard?.data, selectedDate)[0].years[selectYear(selectedDate)][selectMonth(selectedDate)][moment(selectedDate).date()-1];
  }, [huddleBoard, selectedDate]);

  const master = useMemo(() => {
    return normalizeYearsDataWithMaster(huddleBoard?.data, selectedDate)[1].master;
  }, [huddleBoard, selectedDate]);
  
  const [modalOpen, setModalOpen] = useState(false);
  
  const [rowToEdit, setRowToEdit] = useState(null);
  
  const [rows, setRows] = useState(dataRows.length > 0 ? dataRows : master.items);
  
  const departments = useSelector((state) => state.project.project.departments);

  useEffect(() => {
    if (dataRows.length > 0) {
      setIsGlobalEdit(false);
      setRows(dataRows);
    } else {
      setIsGlobalEdit(true);
      setRows(master.items);
    }
    
  }, [selectedDate, huddleBoard]);

  

  const fields = useMemo(() => {
    switch (title) {
      case 'Daily Agenda':
        return [
          {
            name: 'topic',
            label: 'Topic',
            type: 'textarea',
            defaultValue: rows[rowToEdit]?.topic || '',
            validation: Yup.string().required('Topic is required'),
          },
          {
            name: 'duration',
            label: 'Duration',
            type: 'number',
            defaultValue:
              rows[rowToEdit]?.duration || '',
            validation: Yup.number().required('Duration is required and must be a number'),
          },
          {
            name: 'status',
            label: 'Status',
            type: 'button',
            isMulti: true,
            options: dailyAgendaStatuses,
            defaultValue: rows[rowToEdit]?.status || ['not completed'],
            validation: Yup.array().min(1, 'Please select at least one status'),
            hidden: true,
          }
        ];
      case 'Attendance':
        return [
          {
            name: 'names',
            label: 'Name(s)',
            type: 'textarea',
            defaultValue: rows[rowToEdit]?.names || '',
            validation: Yup.string(),
          },
          {
            name: 'status',
            label: 'Status',
            type: 'button',
            isMulti: true,
            options: attendanceStatuses,
            defaultValue: rows[rowToEdit]?.status || ['not completed'],
            validation: Yup.array().min(1, 'Please select at least one status'),
            hidden: true,
          }
        ];       
      default:
        return [];
    }
  }, [title, rowToEdit, rows]);
  
  const handleUpdate = (update) => {
    updateHuddleBoard(huddleBoard?.id, update, huddleBoard, 'Daily', selectedDate);
  };

  const handleDeleteRow = (targetIndex) => {
    let updatedRows = [];
    let updatedMasterItems = master.items;
    if (dataRows.length > 0) {
      updatedRows = rows.filter((_, idx) => idx !== targetIndex);
    } else {
      updatedMasterItems = master.items.filter((_, idx) => idx !== targetIndex);
    }
    setRows(updatedRows);
    handleUpdate({years: updatedRows, master: {items: updatedMasterItems, subItems: master.subItems}});
  };

  const handleEditRow = (idx) => {
    setRowToEdit(idx);
    setModalOpen(true);
  };
  
  const handleSubmit = (newRow) => {

    // console.log("newRow", newRow);
    // console.log("rows", rows);
    // console.log("rowToEdit", rowToEdit);
    // console.log("master", master);
    // console.log("dataRows", dataRows);

    let updatedDataRows = dataRows;
    let updatedMaster = master;
    
    if (rowToEdit === null) {
      if (dataRows.length > 0) {
        updatedDataRows = [...rows, newRow];
        setRows(updatedDataRows);
      } else {
        
        if (title === 'Daily Agenda') {
          updatedMaster = {
            items: [...master.items, {topic: newRow.topic, duration: newRow.duration }],
            subItems: master.subItems,
          };
        }
        
        if (title === 'Attendance') {
          updatedMaster = {
            items: [...master.items, {departmentID: newRow.departmentID, departmentName: newRow.departmentName, names: newRow.names, status: [newRow.status[0] ? newRow.status[0] : 'absent'] }],
            subItems: master.subItems,
          };
        }
        
        
        setRows(updatedMaster.items);
      }
    } else {
      if (dataRows.length > 0) {
        if (title === 'Daily Agenda') {
          updatedDataRows = rows.map((currRow, idx) => (idx === rowToEdit ? newRow : currRow));
        }

        if (title === 'Attendance') {                
          updatedDataRows = rows.map((currRow, idx) => (idx === rowToEdit ? {departmentID: currRow.departmentID, departmentName: currRow.departmentName, names: newRow.names, status: [newRow.status[0] ? newRow.status[0] : 'absent'] } : currRow));          
        }
        
        setRows(updatedDataRows);
        setIsGlobalEdit(false);
      } else {
        
        if (title === 'Daily Agenda') {
          updatedMaster = {          
            items: master.items.map((currRow, idx) => (idx === rowToEdit ? {topic: newRow.topic, duration: newRow.duration } : currRow)),
            subItems: master.subItems,
          };
        }
        
        if (title === 'Attendance') {
          updatedMaster = {          
            items: departments.map((currRow, idx) => (idx === rowToEdit ? {departmentID: currRow.id || currRow.departmentID, departmentName: currRow.name || currRow.departmentName, names: newRow.names } : {
              departmentID: currRow.id, 
              departmentName: currRow.name, 
              names: dataRows.filter(d => (d.departmentID === currRow.id) || (d.departmentID === currRow.departmentID)).length > 0 ? rows.filter(d => (d.departmentID === currRow.departmentID) || (d.departmentID === currRow.id))[0].names : 
              rows.filter(d => (d.departmentID === currRow.departmentID) || (d.departmentID === currRow.id)).length > 0 ? master.items.filter(d => (d.departmentID === currRow.id) || (d.departmentID === currRow.departmentID))[0].names : '' 
            })),
            subItems: master.subItems,
          };
        }
        
        
        setRows(updatedMaster.items);
        setIsGlobalEdit(true);
      }
    }
    
    // console.log("updatedDataRows", updatedDataRows);
    // console.log("updatedMaster", updatedMaster);

    const updatedDaily = {
      years: updatedDataRows,
      master: updatedMaster,
    }  

    setRowToEdit(null);
    setModalOpen(false);
    handleUpdate(updatedDaily);
  };
  
  const handleMarkCompleteRow = (idx) => {    
    let updatedRows = [];
    
    if (title === 'Daily Agenda') {
      if (dataRows.length > 0) {
        updatedRows = rows      
          .map((row, index) => {
            if (index === idx && !row.status?.includes('completed')) {            
              return { ...row, status: ['completed'] };
            } else if (index === idx && row.status.includes('completed')) {
              return { ...row, status: ['not completed'] };
            } else {
              return row;
            }
          }); 
      } else {
        updatedRows = rows      
          .map((row, index) => {
            if (index === idx) {            
              return { ...row, status: ['completed'] };
            } else {
              return { ...row, status: ['not completed'] };
            } 
          });
      }
    }
    
    if (title === 'Attendance') {
      if (dataRows.length > 0) {
        updatedRows = rows      
          .map((row, index) => {
            if (index === idx && !row.status?.includes('present')) {            
              return { ...row, status: ['present'] };
            } else if (index === idx && row.status.includes('present')) {
              return { ...row, status: ['absent'] };
            } else {
              return row;
            }
          }); 
      } else if (rows.length > 0) {
        updatedRows = rows      
          .map((row, index) => {
            if (index === idx) {            
              return { ...row, status: ['present'] };
            } else {
              return { ...row, status: ['absent'] };
            } 
          });
      } else {
        let tempRows = departments.map((department) => ({departmentID: department.id, departmentName: department.name, names: '', status: ['absent']}));
        updatedRows = tempRows      
          .map((row, index) => {
            if (index === idx) {            
              return { ...row, status: ['present'] };
            } else {
              return { ...row, status: ['absent'] };
            } 
          });
      }
    }
    
    

    setRows(updatedRows);
    setIsGlobalEdit(false);
    handleUpdate({years: updatedRows, master: master});
  };

  const componentMapping = (title, propsToPass, selectedDate) => {
    switch (title) {
      case 'Daily Agenda':
        return (
          <div className='huddle-board-content__daily-agenda-container'>
            <DailyAgenda               
              rows={rows}
              isGlobalEdit={isGlobalEdit}
              deleteRow={handleDeleteRow}
              editRow={handleEditRow}
              markCompleteRow={handleMarkCompleteRow}
            />
            <div className="daily-agenda-btn-container btn-container flex-left">
              <button className="btn-add" onClick={() => setModalOpen(true)}>
                + Add Topic to Agenda
              </button>
            </div>
            {modalOpen && (
              <Modal
                fields={fields}
                closeModal={() => {
                  setModalOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
                defaultValue={rowToEdit !== null ? rows[rowToEdit] : {}}
              />
            )}
            <DailyAgendaDeepDive
              huddleBoard={huddleBoard}
              updateHuddleBoard={updateHuddleBoard}
              normalizedData={normalizeYearsDataWithMaster(huddleBoard?.data, selectedDate)}
            />
          </div>
        );
      case 'Attendance':
        return (
          <div className='huddle-board-content__attendance-container'>
            <Attendance               
              rows={rows}
              master={master}                            
              departments={departments}
              isGlobalEdit={isGlobalEdit}
              editRow={handleEditRow}
              markCompleteRow={handleMarkCompleteRow}
            />            
            {modalOpen && (
              <Modal
                fields={fields}
                closeModal={() => {
                  setModalOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
                defaultValue={rowToEdit !== null ? rows[rowToEdit] : {}}
              />
            )}
          </div>
        )
      default:
        return <p>No specified Huddle Board found</p>;
    }
  };

  return (
    <>
      <h2 className="huddle-board-content__title title text-center">{title}</h2>
      

      <span className="huddle-board-content__date-row date-row">
        {/* 📅 {firstDayFormatted} - {lastDayFormatted} */}
        <div className="month-select-button" onClick={() => setSelectedDate(new Date(moment(selectedDate).add(-1, 'day').toISOString()))}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="month-select-current">📅 {moment(selectedDate).format('MMMM DD, YYYY')}</div>
        <div className="month-select-button" onClick={() => setSelectedDate(new Date(moment(selectedDate).add(1, 'day').toISOString()))}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </span>
      
      <div className={`huddle-board-content-row ${title === 'Daily Agenda' ? 'daily-agenda-container' : title === 'Attendance' ? 'attendance-container' : ''}`}>        
        {componentMapping(title, huddleBoard, selectedDate)}            
        <MetricDaily
          updateHuddleBoard={updateHuddleBoard}
          huddleBoard={huddleBoard}
          title={title}
          labels={labels}
          selectedDate={selectedDate}
        />
      </div>
    </>
  );
};

export default connect(null, { updateHuddleBoard })(DailyLayout);
