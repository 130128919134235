import React, { useMemo } from 'react';
import { updateHuddleBoard } from '../../../../store/actions/huddleBoardAction';
// import { currentDate, currentMonth, currentYear } from '../../../../constants';
import './StatusCard.css';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const StatusCard = ({ title, huddleBoard, labels, selectedDate }) => {
  const dispatch = useDispatch();

  const formattedDate = useMemo(() => {
    // const today = new Date(`${currentYear}-${currentMonth}-${new Date().getDate()}`);
    // return (currentDate.getDate() - 1).toLocaleDateString('en-US', {
    //   weekday: 'long',
    //   month: 'short',
    //   day: 'numeric',
    // });
    // return moment().add(-1, 'days').format('dddd, MMMM Do');
    return moment(selectedDate).format('dddd, MMMM Do');
  }, [selectedDate]);

  const handleStatusChange = (status) => {
    if (huddleBoard) {
      dispatch(updateHuddleBoard(huddleBoard.id, status, huddleBoard, 'SQCPD', selectedDate));
    }
  };
  return (
    <div className="status-card">
      <div className="status-card-header">
        {/* <span className="status-card-header-left">🔲 Yesterday</span> */}
        <span className="status-card-header-left">🔲 Selected Date</span>
        <span className="status-card-header-right">{formattedDate}</span>
      </div>
      <div className="status-card-body">
        <p>Choose status:</p>
        <div className="status-card-buttons">
          <button className="status-button green-button" onClick={() => handleStatusChange(true)}>
            {labels[0]}
          </button>
          <button className="status-button red-button" onClick={() => handleStatusChange(false)}>
            {labels[1]}
          </button>
          <button className="status-button neutral-button" onClick={() => handleStatusChange('no-data')}>
            Unset
          </button>
        </div>
      </div>
    </div>
  );
};
export default StatusCard;
