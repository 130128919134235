import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { updateHuddleBoard } from '../../../../store/actions/huddleBoardAction';
const PatientCentricity = ({ title, huddleBoard, updateHuddleBoard, onUpdate }) => {
  const [formVisibility, setFormVisibility] = useState([false, false, false]);

  // const defaultValue = huddleBoard?.data[0] || '';
  const [defaultValue, setDefaultValue] = useState(huddleBoard?.data[0] || '');
  
  useEffect(() => {
    setDefaultValue(huddleBoard?.data[0] || '');
    formik.setFieldValue('client', huddleBoard?.data[0]?.client || '');
    formik.setFieldValue('project', huddleBoard?.data[0]?.project || '');
    formik.setFieldValue('patient', huddleBoard?.data[0]?.patient || '');
  }, []);

  useEffect(() => {
    setDefaultValue(huddleBoard?.data[0] || '');
    formik.setFieldValue('client', huddleBoard?.data[0]?.client || '');
    formik.setFieldValue('project', huddleBoard?.data[0]?.project || '');
    formik.setFieldValue('patient', huddleBoard?.data[0]?.patient || '');
  }, [huddleBoard]);

  const formik = useFormik({
    initialValues: {
      client: defaultValue.client || '',
      project: defaultValue.project || '',
      patient: defaultValue.patient || '',
    },
    validationSchema: Yup.object({
      client: Yup.string().optional().max(300),
      project: Yup.string().optional().max(300),
      patient: Yup.string().optional().max(300),
    }),
    onSubmit: (values, { resetForm }) => {
      onUpdate([values]);
      setFormVisibility([false, false, false]);
    },
  });

  

  const handleFormVisibilityToggle = (index) => {
    const newVisibility = [...formVisibility];
    if (newVisibility[index] === false) {
      if (index === 0) {
        newVisibility[1] = false;
        formik.setFieldValue('project', defaultValue.project);
        newVisibility[2] = false;
        formik.setFieldValue('patient', defaultValue.patient);
      } else if (index === 1) {
        newVisibility[0] = false;
        formik.setFieldValue('client', defaultValue.client);
        newVisibility[2] = false;
        formik.setFieldValue('patient', defaultValue.patient);
      } else if (index === 2) {
        newVisibility[0] = false;
        formik.setFieldValue('client', defaultValue.client);
        newVisibility[1] = false;
        formik.setFieldValue('project', defaultValue.project);
      }
    } else {
      if (index === 0) {
        formik.setFieldValue('client', defaultValue.client);
      } else if (index === 1) {
        formik.setFieldValue('project', defaultValue.project);
      } else if (index === 2) {
        formik.setFieldValue('patient', defaultValue.patient);
      }
    }
    newVisibility[index] = !newVisibility[index];
    setFormVisibility(newVisibility);
  };

  return (
    <div className="huddle-board-content__patient-centricity patient-centricity">
      <h2 className="huddle-board-content__title title">{title}</h2>
      <div className="form__content form">
        {/* <form onSubmit={formik.handleSubmit}> */}
        <div className="form-col">
          <label htmlFor="client">Client</label>
          {!formVisibility[0] ? (
            <div className="patient-centricity-row">
              {defaultValue?.client?.split("\n").map((line, index) => <p key={index}>{line}</p>)}
              <FontAwesomeIcon
                icon={faPen}
                className="input-edit-icon"
                onClick={() => {
                  handleFormVisibilityToggle(0);
                }}
              />
            </div>
          ) : (
            <div className="patient-centricity-edit">
              <form onSubmit={formik.handleSubmit}>
                <textarea
                  name="client"
                  id="client"
                  onChange={formik.handleChange}
                  value={formik.values.client}
                  onBlur={formik.handleBlur}
                ></textarea>
                <div className="flex-center">
                  <button type="submit" className="edit-submit-button">
                    <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                  </button>
                  <button
                    className="edit-cancel-button"
                    onClick={() => {
                      handleFormVisibilityToggle(0);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                  </button>
                </div>
              </form>
            </div>
          )}
          {formik.touched.client && formik.errors.client && (
            <div className="error">{formik.errors.client}</div>
          )}
        </div>
        <div className="form-col">
          <label htmlFor="project">Project</label>

          {!formVisibility[1] ? (
            <div className="patient-centricity-row">
              {defaultValue?.project?.split("\n").map((line, index) => <p key={index}>{line}</p>)}
              <FontAwesomeIcon
                icon={faPen}
                className="input-edit-icon"
                onClick={() => {
                  handleFormVisibilityToggle(1);
                }}
              />
            </div>
          ) : (
            <div className="patient-centricity-edit">
              <form onSubmit={formik.handleSubmit}>
                <textarea
                  name="project"
                  id="project"
                  onChange={formik.handleChange}
                  value={formik.values.project}
                  onBlur={formik.handleBlur}
                ></textarea>
                <div className="flex-center">
                  <button type="submit" className="edit-submit-button">
                    <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                  </button>
                  <button
                    className="edit-cancel-button"
                    onClick={() => {
                      handleFormVisibilityToggle(1);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                  </button>
                </div>
              </form>
            </div>
          )}
          {formik.touched.project && formik.errors.project && (
            <div className="error">{formik.errors.project}</div>
          )}
        </div>
        <div className="form-col">
          <label htmlFor="patient">Patient</label>

          {!formVisibility[2] ? (
            <div className="patient-centricity-row">
              {defaultValue?.patient?.split("\n").map((line, index) => <p key={index}>{line}</p>)}
              <FontAwesomeIcon
                icon={faPen}
                className="input-edit-icon"
                onClick={() => {
                  handleFormVisibilityToggle(2);
                }}
              />
            </div>
          ) : (
            <div className="patient-centricity-edit">
              <form onSubmit={formik.handleSubmit}>
                <textarea
                  name="patient"
                  id="patient"
                  onChange={formik.handleChange}
                  value={formik.values.patient}
                  onBlur={formik.handleBlur}
                ></textarea>
                <div className="flex-center">
                  <button type="submit" className="edit-submit-button">
                    <FontAwesomeIcon icon={faCheck} className="edit-controls" />
                  </button>
                  <button
                    className="edit-cancel-button"
                    onClick={() => {
                      handleFormVisibilityToggle(2);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} className="edit-controls" />
                  </button>
                </div>
              </form>
            </div>
          )}
          {formik.touched.patient && formik.errors.patient && (
            <div className="error">{formik.errors.patient}</div>
          )}
        </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default connect(null, { updateHuddleBoard })(PatientCentricity);
