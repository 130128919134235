import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import { GET_USERS_LOADING, GET_USERS_SUCCESS, GET_USERS_FAIL } from '../types';

export const getUsers = (accountId) => async (dispatch, getState) => {
  dispatch({
    type: GET_USERS_LOADING,
  });

  let url = `${process.env.REACT_APP_SERVER_BASE_URL}/api/users`;
  if (accountId) {
    url = `${url}/account/${accountId}`;
  }
  
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(url, options);

    dispatch({
      type: GET_USERS_SUCCESS,
      payload: { users: response.data.users },
    });
  } catch (err) {
    dispatch({
      type: GET_USERS_FAIL,
      payload: err.message,
    });
  }
};
