import React from 'react';
import './Button.css';
const Button = ({ size, children, onClick, className, type, disabled = false }) => {
  return (
    <button
      className={`${className} unique-btn ${size}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
