import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import { colorPickerColors } from '../../../constants/index';
import Button from '../../Buttons/Button';
import Loader from '../../Loader/Loader';
import requireAuth from '../../../hoc/requireAuth';

import '../../DepartmentList/styles.css';

import { getProject, getProjectUsers } from '../../../store/actions/projectActions';
import {
  getDepartmentUsers,
  deleteDepartment,
  editDepartment,
  deleteUserForDepartment,
  clearDepartmentError,
} from '../../../store/actions/departmentActions';
import { getUser } from '../../../store/actions/userActions';
import { departmentFormSchema } from './validation';

import './styles.css';

const DepartmentEdit = ({
  getDepartmentUsers,
  getProjectUsers,
  thisProject: { project, error },
  department: { department = {}, isLoading: departmentIsLoading, error: departmentError },
  projectID,
  accountID,
  onUnsetForm,
  editDepartment,
  clearDepartmentError,
  deleteUserForDepartment,
  onReturnBack,
  departmentID,
  getUser,
  currentOpenForm,
  onOpenForm,
}) => {
  const [isSave, setIsSave] = useState(false);
  const [color, setColor] = useState(department?.color || '#B4A7D6');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: department?.name || '',
      id: department?.id || '',
      color: department?.color || '#B4A7D6',
      viewOnly: department?.viewOnly || false,
      external: department?.external || false,      
      users: department?.users || [],
    },
    validationSchema: departmentFormSchema,
    onSubmit: async (values) => {
      try {
        const userIDs = values.users.map((user) => user.id);
        editDepartment(departmentID, {
          name: values.name,
          color: values.color,
          viewOnly: values.viewOnly,
          external: values.external,          
          users: userIDs,
        });
        clearDepartmentError(departmentID);
        setIsSave(true);
        onReturnBack();
      } catch (err) {
        console.error('Error updating department:', err);
      }
    },
  });

  useEffect(() => {
    getDepartmentUsers(departmentID);

    setIsSave(false);
  }, [isSave, departmentID, getDepartmentUsers]);

  useEffect(() => {
    if (department?.color) {
      setColor(department.color);
    }
  }, [department]);

  useEffect(() => {
    if (projectID) {
      getProjectUsers(projectID, true);
    }
    if (department?.users) {
      department.users.forEach((user) => {        
        getUser(user.id);
      });
    }
  }, [projectID, getProjectUsers, department, getUser]);
  
  const handleRemoveUser = (user) => {
    deleteUserForDepartment({
      id: user.id,
      departmentID: departmentID,
    });
  };
  
  return (
    <div className="dashboard-form project-list">
      <div className="project-form__heading flex align-center justify-space-between">
        <h2 className="Material-KitH4">Edit Department</h2>
        <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
          <div className="btn__image">
            <img src="/icons/arrow_back.svg" alt="previous" />
          </div>
        </Button>
      </div>

      {error && <div className="error-center Material-KitCaption">{error}</div>}
      {departmentIsLoading ? (
        <Loader />
      ) : (
        <div className="list">                   
          <form onSubmit={formik.handleSubmit}>
            <div className="form-section">
              <h4 className="label Material-KitButton">Department Name</h4>
              <input
                name="name"
                className="Material-KitSB2"
                placeholder="Example Department"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                disabled={departmentIsLoading}
              />
              {formik.touched.name && formik.errors.name ? (
                <p className="error Material-KitCaption">{formik.errors.name}</p>
              ) : null}
            </div>
            

            

            <div className="form-section">
              <h3 className="label Material-KitButton">Color</h3>
              <div className="color-picker margin-bottom-sm">
                {colorPickerColors.map((i) => (
                  <button
                    type="button"
                    key={i}
                    className="color-button "
                    style={{
                      backgroundColor: i,
                      border: formik.values.color === i ? '2px solid var(--primary-primary-80)' : 'none',
                    }}
                    onClick={() => {
                      formik.setFieldValue('color', i);
                      setColor(i);
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="flex-left flex-row flex-gap-36">
                <div className="full-width">
                  <h3 className="label Material-KitButton">View Only</h3>            
                  {/* View Only */}
                  <select
                    name="viewOnly"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.viewOnly}
                    className="Material-KitSB2 form-select"
                    // TODO: Remove this once the feature is implemented
                    disabled={false}
                  >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                  {formik.touched.viewOnly && formik.errors.viewOnly ? (
                    <p className="error Material-KitCaption">{formik.errors.viewOnly}</p>
                  ) : null}
                </div>
                <div className="full-width">
                  <h3 className="label Material-KitButton">External</h3>            
                  {/* External */}
                  <select
                    name="external"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.external}
                    className="Material-KitSB2 form-select"
                    // TODO: Remove this once the feature is implemented
                    disabled={false}
                  >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                  {formik.touched.external && formik.errors.external ? (
                    <p className="error Material-KitCaption">{formik.errors.external}</p>
                  ) : null}
                </div>
            </div>    
            

            

<div className="form-section form-section-gap">
              {/* Departments */}
              <div className="department-users-list">
                <div className="project-edit__row">
                  <h3 className="label Material-KitButton">Members</h3>

                  <Button
                    size="mini"
                    className="action-btn primary-blue-bg bg"
                    type="button"
                    onClick={() => {
                      currentOpenForm ? onOpenForm('member/new') : onUnsetForm();
                    }}
                  >
                    <div className="department-btn__image">
                      <img src="/icons/add.svg" alt="add" />
                    </div>
                  </Button>
                </div>
                {error && <div className="error-center Material-KitCaption">{error}</div>}                
                
                <div className="user-list-container">
                  {departmentIsLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {!department?.users?.length ? (
                        <p className="Material-KitCaption">No members selected</p>
                      ) : (
                        <>
                          {department.users.map((member, index) => {
                            return (
                              <div className="user-list-item flex justify-space-between" key={index}>
                                <div className="user-info-container full-width">
                                  <div className="user-title">
                                    <div className="user-info__image">
                                      <img
                                        src={member.avatar.startsWith('http') ? member.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${member.avatar}`
                                        }
                                        alt={member.name}
                                      />
                                    </div>
                                    <div className="user-info user-username Material-KitCaption">
                                      {member.name}
                                    </div>
                                  </div>
                                  <div>                                      
                                    <div className="user-info user-email Material-KitCaption">
                                      {member.email}
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="member-tags">
                                  {member.role === 'ADMIN' && (
                                    <div className="admin-tag Material-KitCaption">Admin</div>
                                  )}
                                  {member.viewOnly && (
                                    <div className="view-only Material-KitCaption">View Only</div>
                                  )}
                                </div> */}

                                <Button
                                  size="mini"
                                  className="action-btn"
                                  onClick={() => {
                                    handleRemoveUser(member);
                                  }}
                                >
                                  <div className="department-btn__image">
                                    <img src="/icons/delete.svg" alt="delete" />
                                  </div>
                                </Button>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </div>
                
                
              </div>
            </div>

            {/* <div className="department-users-list">
              <h3 className="label Material-KitSB2">Members</h3>
              <Link
                to={`/project/department/edit/${departmentID}/users`}
                className="user-list-link"
              >
                <div className="user-list-container">
                  {formik.values.users.length === 0 ? (
                    <p className="Material-KitCaption">No members selected</p>
                  ) : (
                    <>
                      {formik.values.users.map((user, index) => {
                        return (
                          <div key={index} className="user-list-item">
                            <div>
                              <div className="user-name Material-KitButton">{user.name}</div>
                            </div>
                            <div className="user-info-container">
                              <div>
                                <div className="user-info user-username Material-KitCaption">
                                  @{user.username}
                                </div>
                              </div>
                              <div>
                                <div className="user-info user-email Material-KitCaption">
                                  {user.email}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </Link>
            </div> */}
            
            <div className="project__buttons">
              <Button
                type="submit"
                className="save-new-pod submit-btn Material-KitButton bg"
                disabled={departmentIsLoading}
              >
                Save
              </Button>
              <Button
                type="button"
                className="cancel-btn submit-btn Material-KitButton bg"
                disabled={departmentIsLoading}
                onClick={() => {
                  formik.resetForm();
                  onReturnBack();
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,
  department: state.department,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, {
    deleteDepartment,
    editDepartment,
    clearDepartmentError,
    getDepartmentUsers,
    getProjectUsers,
    getUser,
    deleteUserForDepartment,
  }),
)(DepartmentEdit);
